import React, { Component } from 'react'
import ProductService from '../services/ProductService';

class CreateProductComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            name: '',
            productPrice: '',
            rentPrice: '',
            maintainancePrice: '',
            modelYear: '',
            quantity: '',
            lateCharges: '',
            categoryOfProduct: '',
            discount: ''


        }
        this.changeNameHandler = this.changeNameHandler.bind(this);
        this.changeProductPriceHandler = this.changeProductPriceHandler.bind(this);
        this.changeRentPriceHandler = this.changeRentPriceHandler.bind(this);
        this.changeMaintainancePriceHandler =this.changeMaintainancePriceHandler.bind(this);
        this.changeModelYearHandler =this.changeModelYearHandler.bind(this);
        this.changeQuantityHandler =this.changeQuantityHandler.bind(this);
        this.changeLateChargesHandler =this.changeLateChargesHandler.bind(this);
        this.changeCategoryOfProductHandler =this.changeCategoryOfProductHandler.bind(this);
        this.changeDiscountHandler =this.changeDiscountHandler.bind(this);
        this.saveOrUpdateProduct = this.saveOrUpdateProduct.bind(this);
    }

    // step 3
    componentDidMount(){

        // step 4
        if(this.state.id === '0'){
            return
        }else{
            ProductService.getProductById(this.state.id).then( (res) =>{
                let product = res.data;
                this.setState({name : product.name,
                    productPrice : product.productPrice,
                    rentPrice : product.rentPrice,
                    maintainancePrice :product.maintainancePrice,
                    modelYear :product.modelYear,
                    quantity : product.quantity,
                    lateCharges : product.lateCharges,
                    categoryOfProduct : product.categoryOfProduct,
                    discount : product.discount

                });
            });
        }        
    }
    saveOrUpdateProduct = (e) => {
        e.preventDefault();
        let product = {name: this.state.name, productPrice: this.state.productPrice, rentPrice: this.state.rentPrice, maintainancePrice :this.state.maintainancePrice, 
        modelYear: this.state.modelYear, quantity : this.state.quantity, lateCharges :this.state.lateCharges, categoryOfProduct :this.state.categoryOfProduct, discount :this.state.discount};
        console.log('product => ' + JSON.stringify(product));

        // step 5
        if(this.state.id === '0'){
            ProductService.createProduct(product).then(res =>{
                this.props.history.push('/products');
            });
        }else{
            ProductService.updateProduct(product, this.state.id).then( res => {
                this.props.history.push('/products');
            });
        }
    }
    
    changeNameHandler= (event) => {
        this.setState({name: event.target.value});
    }

    changeProductPriceHandler= (event) => {
        this.setState({productPrice: event.target.value});
    }

    changeRentPriceHandler= (event) => {
        this.setState({rentPrice: event.target.value});
    }

    changeMaintainancePriceHandler= (event) =>{
        this.setState({maintainancePrice: event.target.value});
    }

    changeDiscountHandler =(event)=> {
        this.setState({discount: event.target.value});
    }

    changeCategoryOfProductHandler =(event) =>{
        this.setState({categoryOfProduct: event.target.value});
    }

    changeModelYearHandler =(event) =>{
        this.setState({modelYear: event.target.value});
    }

    changeQuantityHandler =(event) =>{
        this.setState({quantity: event.target.value});
    }

    changeLateChargesHandler =(event) =>{
        this.setState({lateCharges: event.target.value});
    }



    cancel(){
        this.props.history.push('/products');
    }

    getTitle(){
        if(this.state.id === '0'){
            return <h3 className="text-center">Add Product</h3>
        }else{
            return <h3 className="text-center">Update Product</h3>
        }
    }
    render() {
        return (
            <div>
                <br></br>
                   <div className = "container">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                {
                                    this.getTitle()
                                }
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Product Name: </label>
                                            <input placeholder="Name" name="name" className="form-control" 
                                                value={this.state.name} onChange={this.changeNameHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Product Price: </label>
                                            <input placeholder="Product Price" name="productPrice" className="form-control" 
                                                value={this.state.productPrice} onChange={this.changeProductPriceHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Rent Price: </label>
                                            <input placeholder="Rent Price" name="rentPrice" className="form-control" 
                                                value={this.state.rentPrice} onChange={this.changeRentPriceHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Maintainance Price: </label>
                                            <input placeholder="Maintainance Price" name="maintainancePrice" className="form-control" 
                                                value={this.state.maintainancePrice} onChange={this.changeMaintainancePriceHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Model Year: </label>  
                                            <input placeholder="Model Year" name="modelYear" className="form-control" 
                                                value={this.state.modelYear} onChange={this.changeModelYearHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Quantity: </label>
                                            <input placeholder="Quantity " name="quantity" className="form-control" 
                                                value={this.state.quantity} onChange={this.changeQuantityHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Late Charges: </label>
                                            <input placeholder="Late Charges" name="lateCharges" className="form-control" 
                                                value={this.state.lateCharges} onChange={this.changeLateChargesHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Category Of Product: </label>
                                            <input placeholder="Category Of Product" name="categoryOfProduct" className="form-control" 
                                                value={this.state.categoryOfProduct} onChange={this.changeCategoryOfProductHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Discount: </label>
                                            <input placeholder="Discount" name="discouunt" className="form-control" 
                                                value={this.state.discount} onChange={this.changeDiscountHandler}/>
                                        </div>

                                        <button className="btn btn-success" onClick={this.saveOrUpdateProduct}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default CreateProductComponent
