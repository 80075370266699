import React, { Component } from 'react'
import FooterComponent from './FooterComponent';


class AboutUsComponent extends Component {




render() {

return (
<div>
 
<div class="container d-flex justify-content-center">
    <div class="row">
        <div class="col-md-12"> <button type="button" class="btn btn-primary m-3" data-toggle="modal" data-target="#exampleModal"> Launch discount modal </button> </div>
    </div>
</div>

 {/* <!-- Launch Modal --> */}

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">




       



            <div class="modal-header">
                <h5 class="modal-title text-center" id="exampleModalLabel">Big Festival Sale</h5> <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            </div>
            <div class="row justify-content-center mt-2 ">
                        <div class="col-12 col-md-10 col-lg-8">
                            <form class="card card-sm">
                                <div class="card-body row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <i class="fas fa-search h4 text-body"></i>
                                    </div>
                                    {/* <!--end of col--> */}
                                    <div class="col">
                                        <input class="form-control form-control-lg form-control-borderless" type="search" placeholder="Search topics or keywords"/>
                                    </div>
                                    {/* <!--end of col--> */}
                                    <div class="col-auto">
                                        <button class="btn btn-lg btn-success" type="submit">Search</button>
                                    </div>
                                    {/* <!--end of col--> */}
                                </div>
                            </form>
                        </div>
                        {/* <!--end of col--> */}
                    </div>


                    

            <div class="modal-body">
                <h3>Get 60% discount on all products</h3>
                <p>@ BBBootstrap.com</p> <button type="button" class="btn btn-custom">Get Now</button>
            </div>
        </div>
    </div>
</div>


  <FooterComponent />
</div>
)
}
}

export default AboutUsComponent