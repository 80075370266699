import axios from 'axios';

const CUSTOMER_API_BASE_URL = "https://api.bsecars.com/api/v1/customers";

class CustomerService {
    
    getCustomers(){
        return axios.get(CUSTOMER_API_BASE_URL);
    }

    createCustomer(Customer){
        return axios.post(CUSTOMER_API_BASE_URL, Customer);
    }

    getCustomerById(CustomerId){
        return axios.get(CUSTOMER_API_BASE_URL + '/' + CustomerId);
    }

    updateCustomer(Customer, CustomerId){
        return axios.put(CUSTOMER_API_BASE_URL + '/' + CustomerId, Customer);
    }

    deleteCustomer(CustomerId){
        return axios.delete(CUSTOMER_API_BASE_URL + '/' + CustomerId);
    }
}

export default new CustomerService()