import axios from 'axios';

const STATE_API_BASE_URL = "https://api.bsecars.com/api/v1/states";

class StateService {

    getStates(){
        return axios.get(STATE_API_BASE_URL);
    }

    createState(state){
        return axios.post(STATE_API_BASE_URL, state);
    }

    getStateById(stateId){
        return axios.get(STATE_API_BASE_URL + '/' + stateId);
    }

    updateState(state, stateId){
        return axios.put(STATE_API_BASE_URL + '/' + stateId, state);
    }

    deleteState(stateId){
        return axios.delete(STATE_API_BASE_URL + '/' + stateId);
    }
}

export default new StateService()