import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import FeatureService from "../services/FeatureService";

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';




  


class CreateFeatureComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
        // step 2
        id: this.props.match.params.id,
        featureName: '', featureNameVal: ''
      
    }
    this.changeFeatureNameHandler = this.changeFeatureNameHandler.bind(this);

    this.saveOrUpdateFeature = this.saveOrUpdateFeature.bind(this);
    
}

// step 3
componentDidMount(){

    // step 4
    if(this.state.id === '0'){
        return
    }else{
        FeatureService.getFeatureById(this.state.id).then( (res) =>{
            let feature = res.data;
            this.setState({
                featureName: feature.featureName,
            });
        });
    }        
}
saveOrUpdateFeature = (e) => {
    e.preventDefault();
    if (this.state.featureName === '') {
        this.setState({featureNameVal: 'Invalid!, Please enter feature name'})
        return false;
    } 
    let feature = {featureId:this.state.id, featureName: this.state.featureName};
    console.log('feature => ' + JSON.stringify(feature));

    // step 5
    if(this.state.id === '0'){
        FeatureService.createFeature(feature).then(res =>{
            this.props.history.push('/features');
        });
    }else{
        FeatureService.updateFeature(feature, this.state.id).then( res => {
            this.props.history.push('/features');
        });
    }
}

changeFeatureNameHandler= (event) => {
    this.setState({featureName: event.target.value});
}



cancel(){
    this.props.history.push('/Features');
}

getTitle(){
    if(this.state.id === '0'){
        return <h6 className="m-0 font-weight-bold text-primary">Add Feature</h6>
    }else{
        return <h6 className="m-0 font-weight-bold text-primary">Update Feature</h6>
    }
}


      render() {
        if (this.state.redirect) {
          return <Redirect to={this.state.redirect} />
        }
    

        return (

 // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Feature</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                <a class="btn btn-sm btn-primary float-right   mr-3" href="/features" role="button">List features</a>

                                 {  this.getTitle() }
                                </div>
                                <div class="card-body">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                              
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Feature Name: </label>
                                            <input placeholder="Feature Name" name="featureName" className="form-control" 
                                                value={this.state.featureName} onChange={this.changeFeatureNameHandler}/>
                                        <p className="text-danger"> {this.state.featureNameVal }</p>

                                        </div>
                                      

                                        <button className="btn btn-success" onClick={this.saveOrUpdateFeature}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 










        );
      }
}

export default CreateFeatureComponent
