import React, { Component } from "react";
import VariantDataService from "../services/VariantService";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

export default class VariantComponent extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchVariant = this.onChangeSearchVariant.bind(this);
    this.retrieveVariants = this.retrieveVariants.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveVariant = this.setActiveVariant.bind(this);
    this.removeAllVariants = this.removeAllVariants.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      variants: [],
      currentVariant: null,
      currentIndex: -1,
      searchVariant: "",

      page: 1,
      count: 0,
      pageSize: 3,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
    this.retrieveVariants();
  }

  onChangeSearchVariant(e) {
    const searchVariant = e.target.value;

    this.setState({
      searchVariant: searchVariant,
    });
  }

  getRequestParams(searchVariant, page, pageSize) {
    let params = {};

    if (searchVariant) {
      params["variantName"] = searchVariant;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveVariants() {
    const { searchVariant, page, pageSize } = this.state;
    const params = this.getRequestParams(searchVariant, page, pageSize);

    VariantDataService.getAll(params)
      .then((response) => {
        const { variants, totalPages } = response.data;

        this.setState({
          variants: variants,
          count: totalPages,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveVariants();
    this.setState({
      currentVariant: null,
      currentIndex: -1,
    });
  }

  setActiveVariant(variant, index) {
    this.setState({
      currentVariant: variant,
      currentIndex: index,
    });
  }

  removeAllVariants() {
    VariantDataService.deleteAll()
      .then((response) => {
        console.log(response.data);
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveVariants();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1
      },
      () => {
        this.retrieveVariants();
      }
    );
  }

  render() {
    const {
      searchVariant,
      variants,
      currentVariant,
      currentIndex,
      page,
      count,
      pageSize,
    } = this.state;

    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by variantName"
              value={searchVariant}
              onChange={this.onChangeSearchVariant}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.retrieveVariants}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4>Variants List</h4>

          <div className="mt-3">
            {"Items per Page: "}
            <select onChange={this.handlePageSizeChange} value={pageSize}>
              {this.pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>

            <Pagination
              className="my-3"
              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={this.handlePageChange}
            />
          </div>

          <ul className="list-group">
            {variants &&
              variants.map((variant, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveVariant(variant, index)}
                  key={index}
                >
                  {variant.variantName}
                </li>
              ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllVariants}
          >
            Remove All
          </button>
        </div>
        <div className="col-md-6">
          {currentVariant ? (
            <div>
              <h4>Variant</h4>
              <div>
                <label>
                  <strong>Variant:</strong>
                </label>{" "}
                {currentVariant.variantName}
              </div>
            
            

              <Link
                to={"/variants/" + currentVariant.id}
                className="badge badge-warning"
              >
                Edit
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Please click on a Variant...</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
