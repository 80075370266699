import React, { Component } from 'react'
import RentalInformationService from '../services/RentalInformationService'

class ListRentalInformationComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                RentalInformations: []
        }
        this.addRentalInformation = this.addRentalInformation.bind(this);
        this.editRentalInformation = this.editRentalInformation.bind(this);
        this.deleteRentalInformation = this.deleteRentalInformation.bind(this);
    }

    deleteRentalInformation(id){
        RentalInformationService.deleteRentalInformation(id).then( res => {
            this.setState({RentalInformations: this.state.RentalInformations.filter(RentalInformation => RentalInformation.id !== id)});
        });
    }
    viewRentalInformation(id){
        this.props.history.push(`/view-rentalInformation/${id}`);
    }
    editRentalInformation(id){
        this.props.history.push(`/add-rentalInformation/${id}`);
    }

    componentDidMount(){
        RentalInformationService.getRentalInformations().then((res) => {
            this.setState({ RentalInformations: res.data});
        });
    }

    addRentalInformation(){
        this.props.history.push('/add-rentalInformation/0');
    }

    render() {
        return (
            <div>
                 <h2 className="text-center">Rental Information List</h2>
                 <div className = "row">
                    <button className="btn btn-primary" 
                    style={{marginLeft: "900px"}}
                    onClick={this.addRentalInformation}> Add RentalInformation</button>
                 </div>
                 <br></br>
                 <div className = "row">
                        <table className = "table table-striped table-bordered">

                            <thead>
                                <tr>
                                    <th> Issue Date</th>
                                    <th> End Date</th>
                                    <th> Initial Payment</th>
                                    <th> Installment Price</th>
                                    <th> Installment Date</th>
                                    <th> Late Charges</th>
                                    <th> Product Damage Charges</th>
                                    <th> Payment Due Date</th>
                                    <th> Payment Collected By </th>
                                    <th> Payment Method </th>
                                    <th> Transpotation Charges</th>
                                    <th> GST Taxes</th>
                                    <th> Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.RentalInformations.map(
                                        RentalInformation => 
                                        <tr key = {RentalInformation.issueDate}>
                                             <td> {RentalInformation.endDate} </td>  
                                             <td> {RentalInformation.initialPayment}</td> 
                                             <td> {RentalInformation.installmentPrice}</td>
                                             <td> {RentalInformation.installmentDate}</td>
                                             <td> {RentalInformation.lateCharges}</td>
                                             <td> {RentalInformation.productDamageCharges}</td>
                                             <td> {RentalInformation.paymentDueDate}</td>
                                             <td> {RentalInformation.paymentCollectedBy}</td>
                                             <td> {RentalInformation.paymentMethod}</td>
                                             <td> {RentalInformation.transpotationCharges}</td>
                                             <td> {RentalInformation.gstTaxes}</td>
                                              
                                             <td>
                                                 <button onClick={ () => this.editRntalInformation(RentalInformation.id)} className="btn btn-info">Update </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deleteRentalInformation(RentalInformation.id)} className="btn btn-danger">Delete </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.viewRentalInformation(RentalInformation.id)} className="btn btn-info">View </button>
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            </div>
        )
    }
}

export default ListRentalInformationComponent
