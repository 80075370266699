import React, { Component } from 'react'
import ProductService from '../services/ProductService'


// const images = [
//   { url: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg" },
//   { url: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg" },
//   { url: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111.jpg" },
// ];

class ViewProductComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            product: {}
        }
    }

    componentDidMount(){
        ProductService.getProductById(this.state.id).then( res => {
            this.setState({product: res.data});
        })
    }

    render() {
        return (
         <div>
           
         </div>
        )
    }
}

export default ViewProductComponent
