import React, { Component } from 'react'
import FeatureValueService from '../services/FeatureValueService';
import VariantService from '../services/VariantService';
import FeatureService from '../services/FeatureService';

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class CreateFeatureValueComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            featureId: 0,
            featureValueName: '',
            variant: {},
            model: {},
            brand: {},
            featureValues: [],
            featureValues: [],
            features: [],


        }


    }


    // step 3
    componentDidMount() {

        FeatureService.getFeatures().then((res) => {
            this.setState({ features: res.data });
        });

        VariantService.getVariantById(this.state.id).then(res => {

            this.setState({ variant: res.data });
            this.setState({ model: res.data.model });
            this.setState({ brand: res.data.model.brand });
            this.setState({ featureValues: res.data.featureValues });

        })


    }
    saveOrUpdateFeaturesValue = (e) => {
        e.preventDefault();


        let featureValue = {
            featureValueName: this.state.featureValueName,
            variant: { variantId: this.state.variant.variantId },
            feature: { featureId: this.state.featureId }
        };
        console.log('featureValue => ' + JSON.stringify(featureValue));
        FeatureValueService.createFeatureValue(featureValue).then(res => {
            this.props.history.push(`/add-variant-feature/${this.state.id}`);
        });

    }
    deleteFeatureValue(id){

        FeatureValueService.deleteFeatureValue(id).then( res => {
            this.setState({featureValues: this.state.featureValues.filter(featureValue => featureValue.featureValueId !== id)});
        });
    }
    changeFeatureIdHandler = (event) => {
        this.setState({ featureId: event.target.value });
    }
    changeFeaturetNameHandler = (event) => {
        this.setState({ featureValueName: event.target.value });
    }

    changeFeatureUnitHandler = (event) => {
        this.setState({ featureUnit: event.target.value });
    }

    cancel() {
        this.props.history.push('/variants');
    }

     getTitle() {
        if (this.state.id === '0') {
            return <h6 className="m-0 font-weight-bold text-primary">Add Feature</h6>
        } else {
            return <h6 className="m-0 font-weight-bold text-primary">Manage Feature Values</h6>
        }
    }
    render() {
        return (
            // <!-- Page Wrapper -->
            <div id="wrapper">

                <SideBarComponent />

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" class="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBarComponent />


                        {/* <!-- Begin Page Content --> */}
                        <div class="container-fluid">

                            {/* <!-- Page Heading --> */}
                            {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Variant</h1>
                
                </div> */}

                            {/* <!-- Content Row --> */}
                            {/* <!-- Content Row --> */}
                            <div class="row">

                                <div class="col-xl-12 col-lg-12">

                                    {/* <!-- Area Chart --> */}
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            {this.getTitle()}  {this.state.brand.brandName} {this.state.model.modelName} {this.state.variant.variantName}
                                        </div>
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="card col-md-12 offset-md-0 offset-md-0">

                                                    <div className="card-body">

                                                        <div className="row" >
                                                            <form>
                                                                <div className="form-group col-4">
                                                                    <label> Feature: </label>
                                                                    <select name="featureId" aria-label="Default select example" onChange={this.changeFeatureIdHandler}>
                                                                        <option value="">Select Feature</option>)

                                                                        {this.state.features.map(feature =>
                                                                            <option value={feature.featureId} selected={this.state.featureId == feature.featureId}>{feature.parentId} == {feature.featureName}</option>)
                                                                        }

                                                                    </select>
                                                                </div>
                                                                <div className="form-group col-4">
                                                                    <label>Feature Value: </label>
                                                                    <input placeholder="Value" name="featureValueName" className="form-control"
                                                                        value={this.state.featureValueName} onChange={this.changeFeaturetNameHandler} />
                                                                </div>

                                                                <div className="form-group col-12">

                                                                    <button className="btn btn-success" onClick={this.saveOrUpdateFeaturesValue}>Save</button>
                                                                    <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>

                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="row" >
                                                            <table className="table table-striped table-bordered">

                                                                <thead>
                                                                    <tr>
                                                                        <th> Feature Id</th>
                                                                        <th> Feature Name</th>
                                                                        <th> Feature Value</th>
                                                                        <th> Created Date</th>
                                                                        <th> Updated Date</th>
                                                                        <th> Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.featureValues.map(
                                                                            featureValue =>
                                                                                <tr key={featureValue.featureValueId}>
                                                                                    <td> {featureValue.featureValueId} </td>
                                                                                    <td>{featureValue.feature.parentId}  - {featureValue.feature.featureName} </td>
                                                                                    <td> {featureValue.featureValueName} </td>

                                                                                   <td> {featureValue.dateCreated} </td>
                                                                                    <td> {featureValue.dateUpdated} </td>
                                                                                    <td>
                                                                                        <button style={{ marginLeft: "10px" }} onClick={() => this.deleteFeatureValue(featureValue.featureValueId)} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /> </button>
                                                                                    </td>
                                                                                </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                        {/* 

                                                          
                                                            <div className="form-group">
                                                                <label> Fuel Type: </label>

                                                                <select class="form-select col-12" name="fuelType" aria-label="Default select example" onChange={this.changeFuelTypeHandler}>
                                                                    <option value="Petrol" selected={this.state.fuelType == 'Petrol'}>Petrol</option>
                                                                    <option value="CNG" selected={this.state.fuelType == 'CNG'}>CNG</option>
                                                                    <option value="Diesel" selected={this.state.fuelType == 'Diesel'}>Diesel</option>

                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Transmission: </label>

                                                                <select class="form-select col-12" name="transmission" aria-label="Default select example" onChange={this.changeTransmissionHandler}>
                                                                    <option value="Automatic" selected={this.state.transmission == 'Automatic'}>Automatic</option>
                                                                    <option value="Manual" selected={this.state.transmission == 'Manual'}>Manual</option>

                                                                </select>

                                                            </div>

                                                            <div className="form-group">
                                                                <label> Ex Showroom: </label>
                                                                <input placeholder="Ex Showroom" name="exShowroom" className="form-control"
                                                                    value={this.state.exShowroom} onChange={this.changeExShowroomHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Registration Amount: </label>
                                                                <input placeholder="Registration Amount" name="regAmount" className="form-control"
                                                                    value={this.state.regAmount} onChange={this.changeRegAmountHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Insurance Payment: </label>
                                                                <input placeholder="Insurance Payment" name="insPayment" className="form-control"
                                                                    value={this.state.insPayment} onChange={this.changeInsPaymentHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Auto Card: </label>
                                                                <input placeholder="Auto Card" name="autoCard" className="form-control"
                                                                    value={this.state.autoCard} onChange={this.changeAutoCardHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Fast Tag: </label>
                                                                <input placeholder="Fast Tag" name="fastTag" className="form-control"
                                                                    value={this.state.fastTag} onChange={this.changeFastTagHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Discount: </label>
                                                                <input placeholder="Discount" name="discount" className="form-control"
                                                                    value={this.state.discount} onChange={this.changeDiscountHandler} />
                                                            </div>

                                                            <Multiselect
                                                                options={this.state.allColors} // Options to display in the dropdown
                                                                selectedValues={this.state.colors} // Preselected value to persist in dropdown
                                                                onSelect={this.onSelect} // Function will trigger on select event
                                                                onRemove={this.onRemove} // Function will trigger on remove event
                                                                displayValue="colorName" // Property name to display in the dropdown options
                                                            /> */}


                                                    </div>
                                                </div>
                                            </div>
                                            <hr />



                                        </div>
                                    </div>



                                </div>


                            </div>

                            {/* <!-- Content Row --> */}


                            {/* <!-- Content Row --> */}


                        </div>
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}
                    <BackEndFooterComponent />

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div>
            // <!-- End of Page Wrapper --> 


        )
    }
}

export default CreateFeatureValueComponent
