import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import DealerService from "../services/DealerService";
import BrandService from '../services/BrandService';
import CityService from '../services/CityService';
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';

class CreateDealerComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            dealerName: '',
            dealerAddress: '',
            dealerLattitude: '',
            dealerLongitude: '',
            dealerMoblieNumber: '',
            brandName: '',
            brandId: 0,
            brandIds: 0,
            cityIds: 0,
            brands: [],
            city: [],
        }
        this.changeDealerNameHandler = this.changeDealerNameHandler.bind(this);
        this.saveOrUpdateDealer = this.saveOrUpdateDealer.bind(this);

    }

    // step 3
    componentDidMount() {


        BrandService.getBrands().then((res) => {
            this.setState({ brands: res.data });
        });

        CityService.getCitys().then((res) => {
            this.setState({ city: res.data });
        });

        // step 4
        if (this.state.id === '0') {
            return
        } else {
            DealerService.getDealerById(this.state.id).then((res) => {
                let dealer = res.data;

                this.setState({
                    dealerName: dealer.dealerName,

                    brandName: dealer.brandName,
                    brandId: dealer?.brand?.brandId,
                    cityId: dealer?.city?.cityId,
                    dealerAddress: dealer.dealerAddress,
                    dealerMoblieNumber: dealer.dealerMoblieNumber,
                    dealerLattitude: dealer.dealerLattitude,
                    dealerLongitude: dealer.dealerLongitude,


                });
            });

        }
    }
    saveOrUpdateDealer = (e) => {
        e.preventDefault();
        let dealer = {
            dealerName: this.state.dealerName, dealerAddress: this.state.dealerAddress,
            dealerLattitude: this.state.dealerLattitude, dealerLongitude: this.state.dealerLongitude,
             dealerMoblieNumber: this.state.dealerMoblieNumber,
            brand: { brandId: this.state.brandId },  city: { cityId: this.state.cityId }
        };
        console.log('dealer => ' + JSON.stringify(dealer));

        // step 5
        if (this.state.id === '0') {
            DealerService.createDealer(dealer).then(res => {
                this.props.history.push('/dealers');
            });
        } else {
            DealerService.updateDealer(dealer, this.state.id).then(res => {
                this.props.history.push('/dealers');
            });
        }
    }

    changeDealerNameHandler = (event) => {
        this.setState({ dealerName: event.target.value });
    }
    changeBrandIdHandler = (event) => {
        this.setState({ brandId: event.target.value });
    }

    changeCityIdHandler = (event) => {
        this.setState({ cityId: event.target.value });
    }

    


    changeDealerAddressHandler = (event) => {
        this.setState({ dealerAddress: event.target.value });
    }

    // changeDealerEmailHandler = (event) => {
    //     this.setState({ dealerEmailId: event.target.value });
    // }
    changedealerNumberHandler = (event) => {
        this.setState({ dealerMoblieNumber: event.target.value });
    }

    changeDealerLattitudeHandler = (event) => {
        this.setState({ dealerLattitude: event.target.value });
    }

    changeDealerLongitudeHandler = (event) => {
        this.setState({ dealerLongitude: event.target.value });
    }




    cancel() {
        this.props.history.push('/Dealers');
    }

    getTitle() {
        if (this.state.id === '0') {
            return <h6 className="m-0 font-weight-bold text-primary">Add Dealer</h6>
        } else {
            return <h6 className="m-0 font-weight-bold text-primary">Update Dealer</h6>
        }
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        //     const { currentUser } = this.state;

        return (

            // <!-- Page Wrapper -->
            <div id="wrapper">
                <SideBarComponent />

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" class="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBarComponent />


                        {/* <!-- Begin Page Content --> */}
                        <div class="container-fluid">

                            {/* <!-- Page Heading --> */}
                            {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Dealer</h1>
                
                </div> */}

                            {/* <!-- Content Row --> */}
                            {/* <!-- Content Row --> */}
                            <div class="row">

                                <div class="col-xl-12 col-lg-12">

                                    {/* <!-- Area Chart --> */}
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <a class="btn btn-sm btn-primary float-right   mr-3" href="/Dealers" role="button">List Dealer</a>

                                            {this.getTitle()}
                                        </div>
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="card col-md-6 offset-md-3 offset-md-3">

                                                    <div className="card-body">
                                                        <form>
                                                            <div className="form-group">
                                                                <label> Dealer Name: </label>
                                                                <input placeholder="Dealer Name" name="dealerName" className="form-control"
                                                                    value={this.state.dealerName} onChange={this.changeDealerNameHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                            <label> Dealer Brand: </label>

                                                                <select class="form-select col-12" name="brandId" aria-label="Default select example" onChange={this.changeBrandIdHandler}>
                                                                    <option value="">Select Brand</option>

                                                                    {this.state.brands.map(brand =>
                                                                        <option value={brand.brandId} selected={this.state.brandId == brand.brandId}>{brand.brandName}</option>)
                                                                    }
                                                                </select>
                                                            </div>


                                                            <div className="form-group">
                                                            <label> Dealer City: </label>

                                                                <select class="form-select col-12" name="cityId" aria-label="Default select example" onChange={this.changeCityIdHandler}>
                                                                    <option value="">Select City</option>

                                                                    {this.state.city.map(city =>
                                                                        <option value={city.cityId} selected={this.state.cityId == city.cityId}>{city.cityName}</option>)
                                                                    }
                                                                </select>
                                                            </div>

                                                            {/* <div className="form-group">
                                                                <label> Dealer Email: </label>
                                                                <input placeholder="Dealer Email" name="dealerEmail" className="form-control"
                                                                    value={this.state.dealerEmailId} onChange={this.changeDealerEmailHandler} />
                                                            </div> */}
                                                            <div className="form-group">
                                                                <label> Dealer Number: </label>
                                                                <input placeholder="Dealer Number" name="dealerNumber" className="form-control"
                                                                    value={this.state.dealerMoblieNumber} onChange={this.changedealerNumberHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Dealer Address: </label>
                                                                <input placeholder="Dealer Address" name="dealerAddress" className="form-control"
                                                                    value={this.state.dealerAddress} onChange={this.changeDealerAddressHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Dealer Location Lattitude: </label>
                                                                <input placeholder="Dealer Location Lattitude: " name="dealerLattitude" className="form-control"
                                                                    value={this.state.dealerLattitude} onChange={this.changeDealerLattitudeHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Dealer Location Longitude: </label>
                                                                <input placeholder="Dealer Location Longitude" name="dealerLongitude" className="form-control"
                                                                    value={this.state.dealerLongitude} onChange={this.changeDealerLongitudeHandler} />
                                                            </div>
                                                            <button className="btn btn-success" onClick={this.saveOrUpdateDealer}>Save</button>
                                                            <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />



                                        </div>
                                    </div>



                                </div>


                            </div>

                            {/* <!-- Content Row --> */}


                            {/* <!-- Content Row --> */}


                        </div>
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}
                    <BackEndFooterComponent />

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div>
            // <!-- End of Page Wrapper --> 










        );
    }
}

export default CreateDealerComponent
