import React, { Component } from 'react'
import EmployeeService from '../services/EmployeeService'
class ListEmployeeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                employees: []
        }
        this.addEmployee = this.addEmployee.bind(this);
        this.editEmployee = this.editEmployee.bind(this);
        this.deleteEmployee = this.deleteEmployee.bind(this);
    }

    deleteEmployee(id){
        alert();
        EmployeeService.deleteEmployee(id).then( res => {
            this.setState({employees: this.state.employees.filter(employee => employee.id !== id)});
        });
    }
    viewEmployee(id){
        this.props.history.push(`/view-employee/${id}`);
    }
    editEmployee(id){
        this.props.history.push(`/add-employee/${id}`);
    }

    componentDidMount(){
        EmployeeService.getEmployees().then((res) => {
            this.setState({ employees: res.data});
        });
    }

    addEmployee(){
        this.props.history.push('/add-employee/0');
    }


    viewCustomer(){
        this.props.history.push('/customers');
    }



    render() {
        return (
            <div >
                 <h2 className="text-center">Employees List</h2>
                 <div className = "row">

                    <button className="btn btn-primary" onClick={this.addEmployee}> Add Employee</button>
                    <button className="btn btn-primary" onClick={this.addEmployee}> View Customer</button>
                 </div>
                 <br></br>
                 <div className = "row" >
                        <table className = "table table-striped table-bordered">

                            <thead>
                                <tr>
                                    <th> Employee First Name</th>
                                    <th> Employee Last Name</th>
                                    <th> Employee Email Id</th>
                                    <th> Employee Id</th>
                                    <th> Employee Address</th>
                                    <th> Employee Alternate Mobile Number</th>
                                    <th> Employee Oraganization</th>
                                    <th> Employee Designation</th>
                                    {/* <th> Employee State</th>
                                    <th> Employee Country</th>
                                    <th> Employee Aadhar</th>
                                    <th> Employee Pan</th>
                                    <th> Employee ZipCode</th> */}
                                    <th> Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.employees.map(
                                        employee => 
                                        <tr key = {employee.id}>
                                             <td> { employee.firstName} </td>   
                                             <td> {employee.lastName}</td>
                                             <td> {employee.emailId}</td>
                                             <td> {employee.employeeId}</td>
                                             <td> {employee.address}</td>
                                             <td> {employee.alternateMobile}</td>
                                             <td> {employee.oraganization}</td>
                                             <td> {employee.designation}</td>
                                            {/*  <td> {employee.state}</td>
                                             <td> {employee.country}</td>
                                             <td> {employee.aadhar}</td>
                                             <td> {employee.pan}</td>
                                             <td> {employee.zipcode}</td> */}

                                             <td>
                                                 <button onClick={ () => this.editEmployee(employee.id)} className="btn btn-info">Update </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deleteEmployee(employee.id)} className="btn btn-danger">Delete </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.viewEmployee(employee.id)} className="btn btn-info">More </button>
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            </div>
        )
    }
}

export default ListEmployeeComponent
