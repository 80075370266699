import React, { Component } from 'react'
import DealerService from '../services/DealerService';
import CustomerService from '../services/CustomerService';
import SideBarComponent from './SideBarComponent';
import CityService from '../services/CityService';

import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';
import Multiselect from 'multiselect-react-dropdown';


class ViewInquiryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            inquiry: this.props.match.params.inquiry,
            brandId: this.props.match.params.brandId,
            customer: {},
            dealerLocation: {},
            dealerInquiry: [],
            dealerLocation1: [{name: 'Pune', id: 4},{name: 'Mumbai', id: 2}, {name: 'Nagpur', id: 7}, {name: 'Nashik', id: 6}, {name: 'Hyderabad', id: 3}, {name: 'Aurangabad', id: 5}],


        }
        this.multiselectRef = React.createRef();

        this.viewCustomers = this.viewCustomers.bind(this);
        this.saveOrUpdateDealerInquiry = this.saveOrUpdateDealerInquiry.bind(this);

    }

    componentDidMount(){

        console.log('inquiry => ' + this.state.inquiry);

        CustomerService.getCustomerById(this.state.id).then( res => {

            console.log("customer data",res.data);

            this.setState({customer: res.data});
        })

        DealerService.getDealerByBrandId(this.state.brandId).then( res => {

            console.log("customer data", res.data);

            this.setState({customer: res.data});
        })
    }


  

    saveOrUpdateDealerInquiry = (e) => {
        e.preventDefault();
      let dealerInquiry = {  customer: { id: this.state.id }, dealerLocation : this.multiselectRef.current.getSelectedItems() };

       alert(JSON.stringify(dealerInquiry ));

            DealerService.createDealerInquiry(dealerInquiry).then(res =>{
               this.viewCustomers();
            });
    }

    viewCustomers(){
        this.props.history.push('/customers');
    }


    render() {
        return (
           // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Customer</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">View Customer
                                    <button className="btn btn-primary" style={{marginLeft: "950px"}} onClick={this.viewCustomers}> List Customer</button>
                                    </h6>
                                </div>
                                <div class="card-body">
                                {/* <h2 className="text-center">Customers List</h2> */}
                 <div className = "row">
                   
                 </div>
                 <br></br>
                <div className = "card col-md-6 offset-md-3">
                    <h3 className = "text-center"> View Inquiry Details</h3>
                    <div className = "card-body">
                        <div className = "row">
                        <h6 class="">
                            <label> Customer Name :     </label> <div>&nbsp; {this.state.customer.name}</div>
                            </h6>
                        </div>


                        <div className = "row">
                        <h6 class="">
                           <label> Customer Address: </label>&nbsp;{this.state.customer.address}</h6>
                       </div>
                       
                       <div className = "row">
                       <h6 class="">
                           <label> Customer Email: </label>&nbsp;{this.state.customer.email}</h6>
                       </div>
                       <div className = "row">
                       <h6 class="">
                           <label> Customer Number: </label> {this.state.customer.mobile}</h6>
                       </div>

                       <div className = "row">
                       <h6 class="">
                           <label> Customer Inquiry: </label>&nbsp; {this.state.customer.inquiry.brand.brandName}</h6>
                       </div>


                            <Multiselect 
                                options={this.state.dealerLocation1} // Options to display in the dropdown
                                selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                onSelect={this.onSelect} // Function will trigger on select event
                                onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                ref={this.multiselectRef}
                           />

                       <hr className="multiSelectContainer" ></hr>
                       <button className="btn btn-success multiSelectContainer" onClick={this.saveOrUpdateDealerInquiry}>Send To Dealer</button>


                    </div>
                    

                </div>

                <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 
        )
    }
}

export default ViewInquiryComponent
