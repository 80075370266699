import React, { Component } from 'react'
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import HeaderComponent from './HeaderComponent';


import AuthService from "../services/auth.service";
import FooterComponent from './FooterComponent';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};




class
  LoginComponent extends Component {
  constructor(props) {
    super(props)

    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }



  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.history.push("/dashboard");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  // deleteEmployee(id){
  //     EmployeeService.deleteEmployee(id).then( res => {
  //         this.setState({employees: this.state.employees.filter(employee => employee.id !== id)});
  //     });
  // }
  // viewEmployee(id){
  //     this.props.history.push(`/view-employee/${id}`);
  // }
  // editEmployee(id){
  //     this.props.history.push(`/add-employee/${id}`);
  // }

  // componentDidMount(){
  //     EmployeeService.getEmployees().then((res) => {
  //         this.setState({ employees: res.data});
  //     });
  // }

  // addEmployee(){
  //     this.props.history.push('/add-employee/0');
  // }


  // viewCustomer(){
  //     this.props.history.push('/customers');
  // }



  render() {
    return (
      <div >
                <HeaderComponent/>

        <h2 className="text-center">Forgot  Password</h2>

        <div class="container">

          {/* <!-- Outer Row --> */}
          <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

              <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                  {/* <!-- Nested Row within Card Body --> */}
                  <div class="row">
                    <div class="col-lg-6 d-none d-lg-block bg-login-image">

                    </div>
                    <div class="col-lg-6">
                      <div class="p-5">
                        <div class="text-center">
                          <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                        </div>

                        <Form
                          onSubmit={this.handleLogin}
                          ref={c => {
                            this.form = c;
                          }}
                        >
                          {/* <form class="user"> */}


                          <div class="form-group">
                            <input type="text" class="form-control form-control-user"
                              id="exampleInputEmail" aria-describedby="emailHelp"

                              value={this.state.username}
                              onChange={this.onChangeUsername}
                              validations={[required]}
                              placeholder="Enter Email Address..." name="username" />
                          </div>


                        
                         
                          {/* <a href="index.html" class="btn btn-primary btn-user btn-block">
                                            Login
                                        </a> */}


                          {/* </form> */}



                          <div className="form-group">
                            <button
                              className="btn btn-primary btn-block"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              <span>Forgot Password</span>
                            </button>
                          </div>

                          {this.state.message && (
                            <div className="form-group">
                              <div className="alert alert-danger" role="alert">
                                {this.state.message}
                              </div>
                            </div>
                          )}
                          <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                              this.checkBtn = c;
                            }}
                          />

                        </Form>
                        <hr />
                        <div class="text-center">
                          <a class="small" href="/login">Login</a>
                        </div>
                        <div class="text-center">
                          <a class="small" href="/register">Create an Account!</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <FooterComponent/>

      </div>
    )
  }
}

export default LoginComponent
