import React, { Component } from "react";
import FooterComponent from "./FooterComponent";
import VariantService from "../services/VariantService";
import FrontEndFooterComponent from "./FrontEndFooterComponent";
import FrontTopBarComponent from "./FrontTopBarComponent";
import ModelService from "../services/ModelService";
import BrandService from "../services/BrandService";
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../services/auth.service";
import CityService from "../services/CityService";
import ScriptTag from "react-script-tag";
import UploadFilesService from "../services/UploadFilesService";
import CurrencyFormat from "react-currency-format";

class CompareCarResultComponent extends Component {
  constructor(props) {
    super(props);

    this.xLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.onChangeSearchModel = this.onChangeSearchModel.bind(this);
    this.retrieveModels = this.retrieveModels.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveModel = this.setActiveModel.bind(this);
    this.removeAllModels = this.removeAllModels.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      variantId1: this.props.match.params.id1,
      variantId2: this.props.match.params.id2,
      variantId3: this.props.match.params.id3,
      variant1: {},
      variant2: {},
      variant3: {},
      model1: {},
      model2: {},
      model3: {},
      brand1: {},
      brand2: {},
      brand3: {},
      specifications1: [],
      specifications2: [],
      specifications3: [],
      features1: [],
      features2: [],
      features3: [],
      username: "",
      password: "",
      loading: false,
      message: "",
      models: [],
      brands: [],
      currentModel: null,
      currentIndex: -1,
      searchModel: "",
      page: 1,
      count: 0,
      pageSize: 6,
      brandId: "",
      modelId: "",
      fuelType: "",
      transmission: "",
      imageInfos: [],
      city: [],
    };

    this.pageSizes = [6, 10, 20];
  }

  displayModel(id) {
    this.props.history.push(`/display-variant-New/${id}`);
  }
  specifications(id) {
    this.props.history.push(`/display-variant/${id}/specifications/`);
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }
  displayModel(id) {
    this.props.history.push(`/display-variant/${id}`);
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.history.push("/dashboard");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
    this.setState({
      message: "",
      loading: true,
    });
    if (this.state.variantId1 !== "") {
      VariantService.getVariantById(this.state.variantId1).then((res) => {
        console.log(res.data);
        this.setState({ model1: res.data.model });
        this.setState({ brand1: res.data.model?.brand });
        this.setState({ variant1: res.data });
        this.setState({ specifications1: res.data?.specificationValues });
        this.setState({ features1: res.data?.featureValues });
        this.setState({
          onRoadPrice1:
            this.state.variant1.exShowroom +
            this.state.variant1.regAmount +
            this.state.variant1.insPayment +
            this.state.variant1.autoCard +
            this.state.variant1.fastTag -
            this.state.variant1.discount,
        });
        this.setState({
          colors: this.state.variant1.colors
            ? this.state.variant1.colors.map((color) => color.colorCode)
            : [],
        });
      });

      this.setState({
        loading: false,
      });
    }

    if (this.state.variantId2 !== "") {
      VariantService.getVariantById(this.state.variantId2).then((res) => {
        console.log(res.data);
        this.setState({ model2: res.data.model });
        this.setState({ brand2: res.data.model?.brand });
        this.setState({ variant2: res.data });
        this.setState({ specifications2: res.data?.specificationValues });
        this.setState({ features2: res.data?.featureValues });
        this.setState({
          onRoadPrice2:
            this.state.variant2.exShowroom +
            this.state.variant2.regAmount +
            this.state.variant2.insPayment +
            this.state.variant2.autoCard +
            this.state.variant2.fastTag -
            this.state.variant2.discount,
        });
        this.setState({
          colors: this.state.variant2.colors
            ? this.state.variant2.colors.map((color) => color.colorCode)
            : [],
        });
      });
    }

    if (this.state.variantId3 !== "") {
      VariantService.getVariantById(this.state.variantId3).then((res) => {
        console.log(res.data);
        this.setState({ model3: res.data.model });
        this.setState({ brand3: res.data.model?.brand });
        this.setState({ variant3: res.data });
        this.setState({ specifications3: res.data?.specificationValues });
        this.setState({ features3: res.data?.featureValues });
        this.setState({
          onRoadPrice3:
            this.state.variant3.exShowroom +
            this.state.variant3.regAmount +
            this.state.variant3.insPayment +
            this.state.variant3.autoCard +
            this.state.variant3.fastTag -
            this.state.variant3.discount,
        });
        this.setState({
          colors: this.state.variant3.colors
            ? this.state.variant3.colors.map((color) => color.colorCode)
            : [],
        });
      });
    }

    ModelService.getModels().then((res) => {
      this.setState({ models: res.data });
    });

    BrandService.getBrands().then((res) => {
      this.setState({ brands: res.data });
    });

    CityService.getCitys().then((res) => {
      this.setState({ city: res.data });
    });

    this.retrieveModels();
  }

  onChangeSearchModel(e) {
    const searchModel = e.target.value;

    this.setState({
      searchModel: searchModel,
    });
  }

  onChangeBrandIdHandler = (event) => {
    //alert('Hello' + event.target.value);
    console.log("brandId: " + event.target.value);

    this.setState({ brandId: event.target.value });
    this.setState({ modelId: "" });

    console.log("state.brandId: " + this.state.brandId);

    this.retrieveModels(event.target.name, event.target.value);
  };
  onChangeModelIdHandler = (event) => {
    //alert('Hello' + event.target.value);
    console.log("modelId: " + event.target.value);

    this.setState({ modelId: event.target.value });
    console.log("state.modelId: " + this.state.modelId);
    this.retrieveModels(event.target.name, event.target.value);
  };

  onChangeFuelTypeHandler = (event) => {
    // alert('Hello' + event.target.value);
    console.log("fuelType: " + event.target.value);

    this.setState({ fuelType: event.target.value });
    console.log("state.fuelType: " + this.state.fuelType);
    this.retrieveModels(event.target.name, event.target.value);
  };
  onChangeTransmissionHandler = (event) => {
    // alert('Hello' + event.target.value);
    console.log("transmission: " + event.target.value);

    this.setState({ transmission: event.target.value });
    console.log("state.transmission: " + this.state.transmission);
    this.retrieveModels(event.target.name, event.target.value);
  };

  getRequestParams(brandId, modelId, fuelType, transmission, page, pageSize) {
    let params = {};

    if (brandId) {
      params["brandId"] = brandId;
    }
    if (modelId) {
      params["modelId"] = modelId;
    }
    if (fuelType) {
      params["fuelType"] = fuelType;
    }
    if (transmission) {
      params["transmission"] = transmission;
    }
    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveModels() {
    const { brandId, modelId, fuelType, transmission, page, pageSize } =
      this.state;

    const params = this.getRequestParams(
      brandId,
      modelId,
      fuelType,
      transmission,
      page,
      pageSize
    );

    ModelService.getAll(params)
      .then((response) => {
        const { models, totalPages } = response.data;

        this.setState({
          models: models,
          count: totalPages,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveModels(name, value) {
    const { brandId, modelId, fuelType, transmission, page, pageSize } =
      this.state;
    let params;

    // alert(name);
    // alert(value);

    switch (name) {
      case "brandId":
        params = this.getRequestParams(
          value,
          modelId,
          fuelType,
          transmission,
          page,
          pageSize
        );
        break;
      case "modelId":
        params = this.getRequestParams(
          brandId,
          value,
          fuelType,
          transmission,
          page,
          pageSize
        );
        break;
      case "fuelType":
        params = this.getRequestParams(
          brandId,
          modelId,
          value,
          transmission,
          page,
          pageSize
        );
        break;
      case "transmission":
        params = this.getRequestParams(
          brandId,
          modelId,
          fuelType,
          value,
          page,
          pageSize
        );
        break;
      default:
        params = this.getRequestParams(
          brandId,
          modelId,
          fuelType,
          transmission,
          page,
          pageSize
        );
    }

    ModelService.getAll(params)
      .then((response) => {
        const { models, totalPages } = response.data;

        this.setState({
          models: models,
          count: totalPages,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  refreshList() {
    this.retrieveModels();
    this.setState({
      currentModel: null,
      currentIndex: -1,
    });
  }

  setActiveModel(model, index) {
    this.setState({
      currentModel: model,
      currentIndex: index,
    });
  }

  removeAllModels() {
    ModelService.deleteAll()
      .then((response) => {
        console.log(response.data);
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveModels();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveModels();
      }
    );
  }
  render() {
    const { models, page, count, pageSize, imageInfos } = this.state;
    return (
      <div>
        
        {/* <div id="loading-area" className="loading-page-1">
          <div className="spinner">
            <div className="ball" />
            <p>LOADING</p>
          </div>
        </div> */}
        <div className="page-wraper">
          {/* Header */}
          <FrontTopBarComponent />
          {/* Header End */}
          <div className="page-content bg-white">
            {/* Banner  */}
            <div
              className="dlab-bnr-inr style-1 overlay-black-middle"
              style={{
                backgroundImage: 'url("https://mobhil.dexignlab.com/xhtml/images/banner/bnr2.jpg")',
              
              }}
            >
              <div className="container">
                <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">Compare Result</h1>
                  <div className="dlab-separator" />
                </div>
              </div>
            </div>
            {/* Banner End */}
            <section className="content-inner-2">
              <div className="container">
                <div className="row m-b30 lightgallery">
                  <div className="col-12 m-b30">
                    <h3 className>
                      Compare {this.state.brand1?.brandName}{" "}
                      {this.state.model1?.modelName} -{" "}
                      {this.state.variant1?.variantName} Vs{" "}
                      {this.state.brand2?.brandName}{" "}
                      {this.state.model2?.modelName} -{" "}
                      {this.state.variant2?.variantName} Vs{" "}
                      {this.state.brand3?.brandName}{" "}
                      {this.state.model3?.modelName} -{" "}
                      {this.state.variant3?.variantName}
                    </h3>
                    <p>
                     Comparing the specifications and featues for variants provided by each brand. 
                    </p>
                  </div>
                  {/* <div className="col-xl-3 m-b40">
                    <div className="compare-box">
                      <form className="row">
                        <h6 className="m-b15 col-xl-12">Compare Car</h6>
                        <div className="col-xl-12 col-lg-3 m-b20">
                          <select
                            class="form-control
                            col-12"
                            name="brandId"
                            value={this.state.brandId}
                            aria-label="Default select example"
                            onChange={this.onChangeBrandIdHandler}
                          >
                            <option value="">Select Brand</option>
                            {this.state.brands.map((brand1) => (
                              <option
                                key={brand1.brandId}
                                value={brand1.brandId}
                              >
                                {brand1.brandName}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-xl-12 col-lg-3 m-b20">
                          <select className="form-control sm">
                            <option>-Select Model-</option>
                            <option>Creta</option>
                            <option>Elantra</option>
                            <option>EON</option>
                            <option>Grand i10</option>
                          </select>
                        </div>
                        <div className="col-xl-12 col-lg-3 m-b20">
                          <select className="form-control sm">
                            <option>-Select Variant-</option>
                            <option>Creta</option>
                            <option>Elantra</option>
                            <option>EON</option>
                            <option>Grand i10</option>
                          </select>
                        </div>
                        <div className="col-xl-12 col-lg-3">
                          <button className="btn btn-primary d-block w-100">
                            Compare Now
                          </button>
                        </div>
                      </form>
                    </div>
                  </div> */}
                  <div className="col-xl-4 col-lg-4 m-b30">
                    {/* <div className="input-group m-b30">
                      <select className="form-control sm">
                        <option>Select</option>
                        <option>Sportback</option>
                        <option>Cabriolet</option>
                      </select>
                    </div> */}

                    <div className="car-list-box box-sm">
                      <div className="media-box">
                        <img
                          src={
                            "https://api.bsecars.com/api/v1/models_get_base_image/" +
                            this.state.model1?.modelId
                          }
                        />
                        <div className="overlay-bx">
                          <span
                            data-exthumbimage="images/product/grid/pic1.jpg"
                            data-src="images/product/grid/pic1.jpg"
                            className="view-btn lightimg"
                          >
                            <svg
                              width={75}
                              height={74}
                              viewBox="0 0 75 74"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                fill="white"
                                fillOpacity="0.66"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>

                      <div className="list-info">
                        <h5 className="title mb-0" style={{height:"50px"}}>
                          <a
                            onClick={() =>
                              this.displayModel(this.state.model1.modelId)
                            }
                            data-splitting
                            className="text-black"
                          >
                            {this.state.variant1?.variantName}
                          </a>
                        </h5>
                        <div className="car-type">
                          {" "}
                          {this.state.brand1?.brandName} -{" "}
                          {this.state.model1?.modelName}
                        </div>
                        <span className="badge m-b30">
                          {" "}
                          <CurrencyFormat
                            value={this.state.onRoadPrice1}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"\u20B9"}
                            renderText={(value) => <div>{value}</div>}
                          />
                        </span>
                        <div className="feature-list" style={{height:"50px"}}>
                          <div>
                            <label>Transmission</label>
                            <p className="value">
                              {this.state.variant1?.transmission}
                            </p>
                          </div>
                          <div>
                            <label>Fuel</label>
                            <p className="value">
                              {this.state.variant1?.fuelType}
                            </p>
                          </div>
                          {/* <div>
                            <label>Passenger</label>
                            <p className="value">
                              {" "}
                              {this.state.specifications1.map(
                                (specificationValue) => (
                                  <label>
                                    {specificationValue.specification
                                      .specificationName == "Seating Capacity"
                                      ? specificationValue.specificationValueName +
                                        " " +
                                        specificationValue.specificationUnit
                                      : ""}{" "}
                                  </label>
                                )
                              )}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 m-b30">
                    {/* <div className="input-group m-b30">
                      <select className="form-control sm">
                        <option>Select</option>
                        <option>Sportback</option>
                        <option>Cabriolet</option>
                      </select>
                    </div> */}
                    <div className="car-list-box box-sm">
                      <div className="media-box">
                        <img
                          src={
                            "https://api.bsecars.com/api/v1/models_get_base_image/" +
                            this.state.model2?.modelId
                          }
                        />
                        <div className="overlay-bx">
                          <span
                            data-exthumbimage="images/product/grid/pic2.jpg"
                            data-src="images/product/grid/pic2.jpg"
                            className="view-btn lightimg"
                          >
                            <svg
                              width={75}
                              height={74}
                              viewBox="0 0 75 74"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                fill="white"
                                fillOpacity="0.66"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className="list-info">
                        <h5 className="title mb-0" style={{height:"50px"}}>
                          <a
                            href="car-details.html"
                            data-splitting
                            className="text-black"
                          >
                            {this.state.variant2.variantName}
                          </a>
                        </h5>
                        <div className="car-type">
                          {" "}
                          {this.state.brand2?.brandName} -{" "}
                          {this.state.model2?.modelName}
                        </div>
                        <span className="badge m-b30">
                          {" "}
                          <CurrencyFormat
                            value={this.state.onRoadPrice2}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"\u20B9"}
                            renderText={(value) => <div>{value}</div>}
                          />
                        </span>
                        <div className="feature-list" style={{height:"50px"}} >
                          <div>
                            <label>Transmission</label>
                            <p className="value">
                              {" "}
                              {this.state.variant2?.transmission}
                            </p>
                          </div>
                          <div>
                            <label>Fuel</label>
                            <p className="value">
                              {this.state.variant2?.fuelType}
                            </p>
                          </div>
                          {/* <div>
                            <label>Passenger</label>
                            <p className="value">
                              {" "}
                              {this.state.specifications2.map(
                                (specificationValue) => (
                                  <label>
                                    {specificationValue.specification
                                      .specificationName == "Seating Capacity"
                                      ? specificationValue.specificationValueName +
                                        " " +
                                        specificationValue.specificationUnit
                                      : ""}{" "}
                                  </label>
                                )
                              )}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 m-b30">
                    {/* <div className="input-group m-b30">
                      <select className="form-control sm">
                        <option>Select</option>
                        <option>Sportback</option>
                        <option>Cabriolet</option>
                      </select>
                    </div> */}
                    <div className="car-list-box box-sm">
                      <div className="media-box">
                        <img
                          src={
                            "https://api.bsecars.com/api/v1/models_get_base_image/" +
                            this.state.model3?.modelId
                          }
                        />
                        <div className="overlay-bx">
                          <span
                            data-exthumbimage="images/product/grid/pic3.jpg"
                            data-src="images/product/grid/pic3.jpg"
                            className="view-btn lightimg"
                          >
                            <svg
                              width={75}
                              height={74}
                              viewBox="0 0 75 74"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                fill="white"
                                fillOpacity="0.66"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className="list-info">
                        <h5 className="title mb-0" style={{height:"50px"}}>
                          <a
                            href="car-details.html"
                            data-splitting
                            className="text-black"
                          >
                            {this.state.variant3?.variantName}
                          </a>
                        </h5>
                        <div className="car-type">
                          {" "}
                          {this.state.brand3?.brandName} -{" "}
                          {this.state.model3?.modelName}
                        </div>
                        <span className="badge m-b30">
                          {" "}
                          <CurrencyFormat
                            value={this.state.onRoadPrice3}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"\u20B9"}
                            renderText={(value) => <div>{value}</div>}
                          />
                        </span>
                        <div className="feature-list" style={{height:"50px"}}>
                          <div>
                            <label>Transmission</label>
                            <p className="value">
                              {" "}
                              {this.state.variant3?.transmission}
                            </p>
                          </div>
                          <div>
                            <label>Fuel</label>
                            <p className="value">
                              {this.state.variant3?.fuelType}
                            </p>
                          </div>
                          {/* <div>
                            <label>Passenger</label>
                            <p className="value">
                              {" "}
                              {this.state.specifications3.map(
                                (specificationValue) => (
                                  <label>
                                    {specificationValue.specification
                                      .specificationName == "Seating Capacity"
                                      ? specificationValue.specificationValueName +
                                        " " +
                                        specificationValue.specificationUnit
                                      : ""}{" "}
                                  </label>
                                )
                              )}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="m-b30">
                    <ul className="table-dl table-col4 clearfix ">
                      <li className="table-head">
                        <div className="leftview compare-list-1">Overview</div>
                        <div className="rightview compare-list-2">
                          {this.state.variant1?.variantName}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.variant2?.variantName}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.variant3?.variantName}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">Fuel Type</div>
                        <div className="rightview compare-list-2">
                          {this.state.variant1?.fuelType}{" "}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.variant2?.fuelType}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.variant3?.fuelType}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Mileage (ARAI)
                        </div>
                        <div className="rightview compare-list-2">
                          {this.state.specifications1?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.specifications2?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.specifications3?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Mileage City{" "}
                        </div>
                        <div className="rightview compare-list-2"> —</div>
                        <div className="rightview compare-list-3"> —</div>
                        <div className="rightview compare-list-4"> —</div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Available Colors
                        </div>
                        <div className="rightview compare-list-2"> Yes </div>
                        <div className="rightview compare-list-3"> Yes</div>
                        <div className="rightview compare-list-4"> Yes</div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Fuel Tank Capacity
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          58Litres
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          75Litres
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          55Litres
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Seating Capacity
                        </div>
                        <div className="rightview compare-list-2">
                          {this.state.specifications1?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Seating Capacity"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : ""}{" "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.specifications2?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Seating Capacity"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : ""}{" "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.specifications3?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Seating Capacity"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : ""}{" "}
                              </label>
                            )
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Transmission Type
                        </div>
                        <div className="rightview compare-list-2">
                          {this.state.variant1?.transmission}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.variant2?.transmission}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.variant3?.transmission}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Engine Displacement (cc)
                        </div>
                        <div className="rightview compare-list-2">
                          {this.state.specifications1?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Engine"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : ""}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.specifications2?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Engine"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : ""}{" "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          {this.state.specifications3?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Engine"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : ""}{" "}
                              </label>
                            )
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Offers &amp; Discount
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          Not Available
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          Not Available
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          3 Offers
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Finance Available (EMI)
                        </div>
                        <div className="rightview compare-list-2">
                          <CurrencyFormat
                            value={this.state.onRoadPrice1}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"\u20B9"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </div>
                        <div className="rightview compare-list-3">
                          <CurrencyFormat
                            value={this.state.onRoadPrice2}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"\u20B9"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </div>
                        <div className="rightview compare-list-4">
                          <CurrencyFormat
                            value={this.state.onRoadPrice3}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"\u20B9"}
                            renderText={(value) => <span>{value}</span>}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Service Cost
                        </div>
                        <div className="rightview compare-list-2"> —</div>
                        <div className="rightview compare-list-3"> —</div>
                        <div className="rightview compare-list-4"> —</div>
                      </li>
                    </ul>
                  </div>
                  <div className="m-b30">
                    <ul className="table-dl table-col4 clearfix">
                      <li className="table-head">
                        <div className="leftview compare-list-1">
                          Standard Features
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          {this.state.variant1?.variantName}{" "}
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          {this.state.variant2?.variantName}{" "}
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          {this.state.variant3?.variantName}{" "}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Air Conditioner
                        </div>
                        <div className="rightview compare-list-2">
                          {this.state.features1?.map((featureValue) => (
                            <label>
                              {featureValue.feature.featureName ==
                              "Air Conditioner"
                                ? featureValue.featureValueName
                                : ""}
                            </label>
                          ))}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.features2?.map((featureValue) => (
                            <label>
                              {featureValue.feature.featureName ==
                              "Air Conditioner"
                                ? featureValue.featureValueName
                                : ""}
                            </label>
                          ))}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.features3?.map((featureValue) => (
                            <label>
                              {featureValue.feature.featureName ==
                              "Air Conditioner"
                                ? featureValue.featureValueName
                                : ""}
                            </label>
                          ))}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          CD Player{" "}
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          AntiLock Braking System
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                        <div className="rightview compare-list-3"> —</div>
                        <div className="rightview compare-list-4">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Power Steering
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />{" "}
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Power Windows
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Leather Seats
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Central Locking
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Speed Sensing Auto Door Lock{" "}
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Impact Sensing Auto Door Unlock
                        </div>
                        <div className="rightview compare-list-2">
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                        <div className="rightview compare-list-3">
                          <i className="fas fa-times font-18 text-red" />
                        </div>
                        <div className="rightview compare-list-4">
                          <i className="fa fa-check font-18 text-green" />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="m-b30">
                    <ul className="table-dl table-col4 clearfix ">
                      <li className="table-head">
                        <div className="leftview compare-list-1">Engine</div>
                        <div className="rightview compare-list-2">
                          {" "}
                          {this.state.variant1?.variantName}{" "}
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          {this.state.variant2?.variantName}{" "}
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          {this.state.variant3?.variantName}{" "}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">Fuel Type</div>
                        <div className="rightview compare-list-2">
                          {this.state.variant1?.fuelType}{" "}
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          {this.state.variant2?.fuelType}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.variant3?.fuelType}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Mileage (ARAI){" "}
                        </div>
                        <div className="rightview compare-list-2">
                          {this.state.specifications1?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.specifications2?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.specifications3?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Mileage City{" "}
                        </div>
                        <div className="rightview compare-list-2"> —</div>
                        <div className="rightview compare-list-3"> —</div>
                        <div className="rightview compare-list-4"> —</div>
                      </li>
                    </ul>
                  </div>
                  <div className="m-b30">
                    <ul className="table-dl table-col4 clearfix ">
                      <li className="table-head">
                        <div className="leftview compare-list-1">
                          Transmission
                        </div>
                        <div className="rightview compare-list-2">
                          {" "}
                          {this.state.variant1?.variantName}{" "}
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          {this.state.variant2?.variantName}{" "}
                        </div>
                        <div className="rightview compare-list-4">
                          {" "}
                          {this.state.variant3?.variantName}{" "}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">Fuel Type</div>
                        <div className="rightview compare-list-2">
                          {this.state.variant1?.fuelType}{" "}
                        </div>
                        <div className="rightview compare-list-3">
                          {" "}
                          {this.state.variant2?.fuelType}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.variant3?.fuelType}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Mileage (ARAI){" "}
                        </div>
                        <div className="rightview compare-list-2">
                          {this.state.specifications1?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-3">
                          {this.state.specifications2?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                        <div className="rightview compare-list-4">
                          {this.state.specifications3?.map(
                            (specificationValue) => (
                              <label>
                                {specificationValue.specification
                                  .specificationName == "Mileage"
                                  ? specificationValue.specificationValueName +
                                    " " +
                                    specificationValue.specificationUnit
                                  : " "}
                              </label>
                            )
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="leftview compare-list-1">
                          Mileage City{" "}
                        </div>
                        <div className="rightview compare-list-2"> —</div>
                        <div className="rightview compare-list-3"> —</div>
                        <div className="rightview compare-list-4"> —</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="content-inner-2">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="section-head row">
                      <div className="col-sm-8">
                        <h3 className="title">Similar Car Comparisons</h3>
                      </div>
                      <div className="col-sm-4 text-sm-end">
                        <div className="portfolio-pagination d-inline-block">
                          <div className="btn-prev swiper-button-prev2">
                            <i className="las la-arrow-left" />
                          </div>
                          <div className="btn-next swiper-button-next2">
                            <i className="las la-arrow-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-container similar-slider">
                    <div className="swiper-wrapper">
                            {models &&
                              models.map((model, index1) => (
                                <div className="swiper-slide">
                                  <div className="car-list-box border shadow-none">
                                    <div className="media-box" style={{height:"220px"}}>
                                      <img
                                      
                                        src={
                                          " https://api.bsecars.com/api/v1/models_get_base_image/" +
                                          model.modelId
                                        }
                                        alt=""
                                        onClick={() => this.carListing()}
                                      />
                                      <div className="overlay-bx">
                                        <span
                                          data-exthumbimage={
                                            " https://api.bsecars.com/api/v1/models_get_base_image/" +
                                            model.modelId
                                          }
                                          data-src={
                                            " https://api.bsecars.com/api/v1/models_get_base_image/" +
                                            model.modelId
                                          }
                                          className="view-btn lightimg"
                                        >
                                          <svg
                                            width={75}
                                            height={74}
                                            viewBox="0 0 75 74"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                              fill="white"
                                              fillOpacity="0.66"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="list-info">
                                      <h5 className="title mb-0">
                                        <a
                                          onClick={() =>
                                            this.displayModel(model.modelId)
                                            
                                          }style={{height:"20px"}}
                                          data-splitting
                                          className="text-black"
                                        >
                                          {model.brand.brandName} - 
                                          {model.modelName}
                                        </a>
                                      </h5>
                                      <div className="car-type">{model.modelName}</div>
                                      <span className="badge m-b30">
                                        <CurrencyFormat
                                          className="text-black"
                                          value={model?.first}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"Starts from \u20B9"}
                                          renderText={(value) => (
                                            <div>{value}</div>
                                          )}
                                        />
                                         
                                      </span>
                                      <div className="feature-list" style={{height:"50px"}}>
                                        <div>
                                          <label>Transmotion</label>
                                          <p className="value">
                                            {model.transmissions.map(
                                              (transmission) => (
                                                <span key={transmission}>
                                                  {transmission},
                                                </span>
                                              )
                                            )}
                                          </p>
                                        </div>
                                        <div>
                                          <label>Fuel</label>
                                          <p className="value">
                                            {" "}
                                            {model.fuelTypes.map((fuelType) => (
                                              <span key={fuelType}>
                                                {fuelType},
                                              </span>
                                            ))}
                                          </p>
                                        </div>
                                        {/* <div>
                                          <label>Passenger</label>
                                          <p className="value">5 Person</p>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                           
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="content-inner">
              <div className="container">
                <div className="row call-to-action-bx">
                  <div className="col-xl-5 col-lg-6 me-auto">
                    <div className="section-head">
                      <h2 className="title text-white">
                        Have any question about us?
                      </h2>
                    </div>
                    <a
                      href="tel::+9180073 58007"
                      className="btn btn-white me-3 mb-2"
                    >
                      <i className="fas fa-phone-volume me-sm-3 me-0 shake" />
                      <span className="d-sm-inline-block d-none">+91 80073 58007</span>
                    </a>
                    <a
                      href="contact-us.html"
                      className="btn btn-outline-white effect-1  mb-2"
                    >
                      <span>Contact Us</span>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="media-box">
                    <img   src="https://mobhil.dexignlab.com/xhtml/images/about/pic5.jpg"
                        className="main-img"
                        alt=""
                      />
                      <img
                        src="images/pattern/pattern7.png"
                        className="pt-img move-1"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* Footer */}
          <FrontEndFooterComponent />
          {/* Footer End */}
          <button className="scroltop icon-up" type="button">
            <i className="fas fa-arrow-up" />
          </button>
        </div>
              </div>
    );
  }
}

export default CompareCarResultComponent;
