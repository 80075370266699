import React, { Component } from 'react'
import ModelService from '../services/ModelService'
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';

class ViewModelComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            model: {}
        }

        this.viewModels = this.viewModels.bind(this);
    }

    componentDidMount(){
        ModelService.getModelById(this.state.id).then( res => {
            this.setState({model: res.data});
        })
    }

    viewModels(){
        this.props.history.push('/models');
    }


    render() {
        return (
           // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Brand</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">View Model
                                    <button className="btn btn-primary" style={{marginLeft: "850px"}} onClick={this.viewModels}> List Model</button>
                                    </h6>
                                </div>
                                <div class="card-body">
                                {/* <h2 className="text-center">Models List</h2> */}
                 <div className = "row">
                   
                 </div>
                 <br></br>
                <div className = "card col-md-6 offset-md-3">
                    <h3 className = "text-center"> View Model Details</h3>
                    <div className = "card-body">
                        <div className = "row">
                        <h6 class="">
                            <label> Model Name: </label>
                            &nbsp;{ this.state.model?.modelName }</h6>
                        </div>
                        <div className = "row">
                        <h6 class="">
                            <label> Brand Name: </label>&nbsp;  { this.state.brand?.brandName }</h6>
                        </div>
                        <div className = "row">
                        <h6 class="">
                            <label> Variants: </label>&nbsp;
                           { this.state.model?.variants?.length }</h6>
                        </div>
                        <div className = "row">
                        <h6 class="">
                            <label>Date Created : </label>&nbsp;
                            { this.state.model?.dateCreated }
                            </h6>
                        </div>
                        <div className = "row">
                        <h6 class="">
                            <label>Date Updated: </label>&nbsp;
                           { this.state.model?.dateUpdated }</h6>
                        </div>
                        {/* <div className = "row">
                            <label>Image: </label>
                            <div> { this.state.model?.Image }</div>
                        </div> */}
                       
                    </div>

                </div>
                <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 
        )
    }
}

export default ViewModelComponent
