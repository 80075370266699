import React, { Component } from 'react'
import StateService from '../services/StateService'

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';
import DataTable from "react-data-table-component";
// import { confirm } from "react-confirm-box";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";




class ListStateComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                states: [],
               
      currentUser: null,
      tableData: {},
      selctedStatesRows: [],
      columns: [
        {
          name: "State Id",
          selector: (row) => row.stateId,
          cellExport: (row) => row.stateId,
          sortable: true,
        },
        {
            name: "State Name",
            selector: (row) => row.stateName,
            cellExport: (row) => row.stateName,
            sortable: true,
          },
       {
          cell: (row) => (
            <a href={`/view-state/${row["stateId"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add-state/${row["stateId"]}`}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href="#!" onClick={() => this.clickHandler(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
        }
        this.addState = this.addState.bind(this);
        this.editState = this.editState.bind(this);
        this.deleteState = this.deleteState.bind(this);
    }

    deleteState(id){

        StateService.deleteState(id).then( res => {
            this.setState({states: this.state.states.filter(state => state.stateId !== id),
            });
            this.componentDidMount();
        });

    }
    clickHandler = (row) => {
        this.deleteState(row.stateId);}



    viewState(id){
        this.props.history.push(`/view-state/${id}`);
    }
    editState(id){
        this.props.history.push(`/add-state/${id}`);
    }

    componentDidMount(){
        StateService.getStates().then((res) => {
            this.setState({ states: res.data});
            this.setState({
                tableData: { columns: this.state.columns, data: res.data },
              });
        });
    }

    addState(){
        this.props.history.push('/add-state/0');
    }




    render() {
        return (
               // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Brand</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">List State
                                    </h6>  <button className="btn btn-sm btn-primary"
                                 
                                     onClick={this.addState}> Add State</button>
                                    

                                </div>
                                <div class="card-body">
                 {/* <h2 className="text-center">states List</h2> */}
                 <div className = "row">
                 <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.states}
                            defaultSortField="State Id"
                            defaultSortDesc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                 </div>
                 <br></br>
                 

            
                 </div>
                            </div>

                          

                        </div>

                      
                    </div>

                {/* <!-- Content Row --> */}


                {/* <!-- Content Row --> */}
             

            </div>
            {/* <!-- /.container-fluid --> */}

        </div>
        {/* <!-- End of Main Content --> */}
<BackEndFooterComponent/>

    </div>
    {/* <!-- End of Content Wrapper --> */}

</div>
// <!-- End of Page Wrapper --> 
        )
    }
}

export default ListStateComponent
