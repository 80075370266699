import React, { Component } from "react";
import VariantService from "../services/VariantService";
import Tippy from "@tippyjs/react";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import DataTable from "react-data-table-component";
// import { confirm } from "react-confirm-box";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import {
  faEye,
  faEdit,
  faTrash,
  faGear,
  faBinoculars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ListVariantComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      tableData: {},
      selctedVariantsRows: [],
      variants: [],
      columns: [
        {
          name: "Variant Id",
          selector: (row) => row.variantId,
          cellExport: (row) => row.variantId,
          sortable: true,
        },
        {
          name: "Variant Name",
          selector: (row) => 
            <Tippy content={row.variantName}>
              <div>{row.variantName}</div>
            </Tippy>,
          
          cellExport: (row) => row.variantName,
          sortable: true,
        },
        {
          name: "Fuel Type",
          selector: (row) => row.fuelType,
          cellExport: (row) => row.fuelType,
          sortable: true,
        },
        {
          name: "Transmission",
          selector: (row) => row.transmission,
          cellExport: (row) => row.transmission,
          sortable: true,
        },
        {
          name: "Model Name",
          selector: (row) => row.model?.modelName,
          cellExport: (row) => row.model?.modelName,
          sortable: true,
        },
        {
          name: "Brand Name",
          selector: (row) => row.model.brand.brandName,
          cellExport: (row) => row.model.brand.brandName,
          sortable: true,
        },
        {
          name: "Featured Cars",
          selector: (row) => row.featuredCars,
          cellExport: (row) => row.featuredCars,
          sortable: true,
        },

        {
          cell: (row) => (
            <a href={`/view-variant/${row["variantId"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add-variant/${row["variantId"]}`}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href="#!" onClick={() => this.clickHandler(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };
    this.addVariant = this.addVariant.bind(this);
    this.editVariant = this.editVariant.bind(this);
    this.deleteVariant = this.deleteVariant.bind(this);
  }

  deleteVariant(id) {
    VariantService.deleteVariant(id).then((res) => {
      this.setState({
        variants: this.state.variants.filter(
          (variant) => variant.variantId !== id
        ),
      });
      this.componentDidMount();
    });
  }
  clickHandler = (row) => {
    this.deleteVariant(row.variantId);
  };

  viewVariant(id) {
    this.props.history.push(`/view-variant/${id}`);
  }
  editVariant(id) {
    this.props.history.push(`/add-variant/${id}`);
  }

  addVariantSpecification(id) {
    this.props.history.push(`/add-variant-specification/${id}`);
  }
  addVariantFeature(id) {
    this.props.history.push(`/add-variant-feature/${id}`);
  }
  componentDidMount() {
    VariantService.getVariants().then((res) => {
      console.log(res.data);
      this.setState({ variants: res.data });
      this.setState({
        tableData: { columns: this.state.columns, data: res.data },
      });
    });
  }

  addVariant() {
    this.props.history.push("/add-variant/0");
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Model</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List Variant{" "}
                      </h6>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.addVariant}
                      >
                        {" "}
                        Add Variant
                      </button>
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Variants List</h2> */}
                      <div className="row">
                        <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.variants}
                            defaultSortField="Id"
                            defaultSortDesc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      </div>
                      <br></br>
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListVariantComponent;
