import React, { Component } from "react";
import UploadFilesService from "../services/UploadFilesService";
import ModelService from "../services/ModelService";
import TopBarComponent from './TopBarComponent';
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';


export default class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.selectFiles = this.selectFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadImages = this.uploadImages.bind(this);

    this.state = {
      id: this.props.dataFromParent,
      selectedFiles: undefined,
      previewImages: [],
      progressInfos: [],
      message: [],
      imageInfos: [],
    };
  }

  componentDidMount() {
    if (this.state.id === '0') {
      return
    } else {
      ModelService.getModelById(this.state.id).then((res) => {
        let model = res.data;
        this.setState({
          modelName: model.modelName,
        });
      });
    }


    UploadFilesService.getFiles(this.state.id).then((response) => {
      alert("in upload "+response);
      this.setState({
        imageInfos: response.data,
      });
    });
  }

  selectFiles(event) {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]))
    }

    this.setState({
      progressInfos: [],
      message: [],
      selectedFiles: event.target.files,
      previewImages: images
    });
  }

  upload(idx, file) {
    let _progressInfos = [...this.state.progressInfos];


    UploadFilesService.upload(this.state.id, idx, file, (event) => {
      // alert(this.state.id);
      _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
      this.setState({
        progressInfos: _progressInfos,
      });
    })
      .then(() => {
        this.setState((prev) => {
          let nextMessage = [...prev.message, "Uploaded the image successfully: " + file.name];
          return {
            message: nextMessage
          };
        });

        return UploadFilesService.getFiles();
      })
      .then((files) => {
        this.setState({
          imageInfos: files.data,
        });
      });
      // .catch(() => {
      //   _progressInfos[idx].percentage = 0;
      //   this.setState((prev) => {
      //     let nextMessage = [...prev.message, "Could not upload the image: " + file.name];
      //     return {
      //       progressInfos: _progressInfos,
      //       message: nextMessage
      //     };
      //   });
      // });
  }

  uploadImages() {
    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i]);
        }
      }
    );
  }

  render() {
    const { selectedFiles, previewImages, progressInfos, message, imageInfos } = this.state;

    return (


      <div class="container-fluid">
        <div className="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card shadow mb-4">

              <div class="card-body">

                <input type="hidden" name="modelId" value={this.state.id} />

                <div className="col-8">
                  <label className="btn btn-default p-0">
                    <input type="file" multiple accept="image/*" onChange={this.selectFiles} />
                  </label>
                </div>


                <div className="col-4">
                  <button
                    className="btn btn-success btn-sm"
                    disabled={!selectedFiles}
                    onClick={this.uploadImages}>
                    Upload
                  </button>
                </div>


                {progressInfos &&
                  progressInfos.map((progressInfo, index) => (
                    <div className="mb-2" key={index}>
                      <span>{progressInfo.fileName}</span>
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-info"
                          role="progressbar"
                          aria-valuenow={progressInfo.percentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: progressInfo.percentage + "%" }}
                        >
                          {progressInfo.percentage}%
                        </div>
                      </div>
                    </div>
                  ))}
                <div class="card text-center">




                  {previewImages && (
                    <div>
                      {previewImages.map((img, i) => {
                        return <img className="preview" src={img} alt={"image-" + i} key={i} />;
                      })}
                    </div>
                  )}



                  {message.length > 0 && (
                    <div className="alert alert-secondary mt-2" role="alert">
                      <ul>
                        {message.map((item, i) => {
                          return <li key={i}>{item}</li>;
                        })}
                      </ul>
                    </div>
                  )}



                  <div className="card mt-3">
                    <div className="card-header">List of Files</div>
                    <ul className="list-group list-group-flush">
                      {imageInfos &&
                        imageInfos.map((img, index) => (
                          <li className="list-group-item" key={index}>

                            <img src={`https://api.bsecars.com/api/v1/files/` + img.modelImageName} alt={img.modelImageName} height="80px" />

                          </li>
                        ))}
                    </ul>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>




    );
  }
}