import React, { Component } from 'react'
import SpecificationValueService from '../services/SpecificationValueService';
import VariantService from '../services/VariantService';
import SpecificationService from '../services/SpecificationService';

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class CreateSpecificationValueComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            specificationId: 0,
            specificationUnit: '',
            specificationValueName: '',
            variant: {},
            model: {},
            brand: {},
            specificationValues: [],
            specifications: [],


        }


    }


    // step 3
    componentDidMount() {

        SpecificationService.getSpecifications().then((res) => {
            this.setState({ specifications: res.data });
        });

        VariantService.getVariantById(this.state.id).then(res => {


            console.log('Home Data: ', res.data.specificationValues);
            this.setState({ variant: res.data });
            this.setState({ model: res.data.model });
            this.setState({ brand: res.data.model.brand });
            this.setState({ specificationValues: res.data.specificationValues });
            this.setState({ featureValues: res.data.featureValues });

        })


    }
    saveOrUpdateSpecificationsValue = (e) => {
        e.preventDefault();


        let specificationValue = {
            specificationValueName: this.state.specificationValueName,
            specificationUnit: this.state.specificationUnit,
            variant: { variantId: this.state.variant.variantId },
            specification: { specificationId: this.state.specificationId }
        };
        console.log('specificationValue => ' + JSON.stringify(specificationValue));
        SpecificationValueService.createSpecificationValue(specificationValue).then(res => {
            this.props.history.push(`/add-variant-specification/${this.state.id}`);
        });

    }
    deleteSpecificationValue(id) {

        SpecificationValueService.deleteSpecificationValue(id).then(res => {
            this.setState({ specificationValues: this.state.specificationValues.filter(specificationValue => specificationValue.specificationValueId !== id) });
        });
    }
    changeSpecificationIdHandler = (event) => {
        this.setState({ specificationId: event.target.value });
    }
    changeSpecificationtNameHandler = (event) => {
        this.setState({ specificationValueName: event.target.value });
    }

    changeSpecificationUnitHandler = (event) => {
        this.setState({ specificationUnit: event.target.value });
    }

    cancel() {
        this.props.history.push('/variants');
    }

    getTitle() {
        if (this.state.id === '0') {
            return <h6 className="m-0 font-weight-bold text-primary">AddSpecification</h6>
        } else {
            return <h6 className="m-0 font-weight-bold text-primary">Manage Specification Values</h6>
        }
    }
    render() {
        return (
            // <!-- Page Wrapper -->
            <div id="wrapper">

                <SideBarComponent />

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" class="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">

                        <TopBarComponent />


                        {/* <!-- Begin Page Content --> */}
                        <div class="container-fluid">

                            {/* <!-- Page Heading --> */}
                            {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Variant</h1>
                
                </div> */}

                            {/* <!-- Content Row --> */}
                            {/* <!-- Content Row --> */}
                            <div class="row">

                                <div class="col-xl-12 col-lg-12">

                                    {/* <!-- Area Chart --> */}
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                        {this.getTitle()}  {this.state.brand.brandName} {this.state.model.modelName} {this.state.variant.variantName}
                                        </div>
                                        <div class="card-body">
                                            <div className="row">
                                                <div className="card col-md-12 offset-md-0 offset-md-0">

                                                    <div className="card-body">

                                                        <div className="row" >

                                                            <form>

                                                                <div className="form-group col-4">
                                                                    <label> Specification: </label>
                                                                    <select name="specificationId" aria-label="Default select example" onChange={this.changeSpecificationIdHandler}>
                                                                        <option value="">Select Specification</option>)

                                                                        {this.state.specifications.map(specification =>
                                                                            <option value={specification.specificationId} selected={this.state.specificationId == specification.specificationId}>{specification.specificationName}</option>)
                                                                        }

                                                                    </select>
                                                                </div>
                                                                <div className="form-group col-4">
                                                                    <label>Specification Name: </label>
                                                                    <input placeholder="Value" name="specificationValueName" className="form-control"
                                                                        value={this.state.specificationValueName} onChange={this.changeSpecificationtNameHandler} />
                                                                </div>
                                                                <div className="form-group col-4">
                                                                    <label>Specification Unit: </label>
                                                                    <input placeholder="Unit" name="specificationUnit" className="form-control"
                                                                        value={this.state.specificationUnit} onChange={this.changeSpecificationUnitHandler} />
                                                                </div>
                                                                <div className="form-group col-12">

                                                                    <button className="btn btn-success" onClick={this.saveOrUpdateSpecificationsValue}>Save</button>
                                                                    <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>Cancel</button>

                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="row" >
                                                            <table className="table table-striped table-bordered">

                                                                <thead>
                                                                    <tr>
                                                                        <th> Specification Id</th>
                                                                        <th> Specification Name</th>
                                                                        <th> Specification Value</th>
                                                                        <th> Specification Unit</th>
                                                                        <th> Created Date</th>
                                                                        <th> Updated Date</th>
                                                                        <th> Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.specificationValues.map(
                                                                            specificationValue =>
                                                                                <tr key={specificationValue.specificationValueId}>
                                                                                    <td> {specificationValue.specificationValueId} </td>
                                                                                    <td> {specificationValue.specification.specificationName} </td>
                                                                                    <td> {specificationValue.specificationValueName} </td>
                                                                                    <td> {specificationValue.specificationUnit} </td>

                                                                                    <td> {specificationValue.dateCreated} </td>
                                                                                    <td> {specificationValue.dateUpdated} </td>
                                                                                    <td>
                                                                                        <button style={{ marginLeft: "10px" }} onClick={() => this.deleteSpecificationValue(specificationValue.specificationValueId)} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /> </button>
                                                                                    </td>
                                                                                </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                        {/* 

                                                          
                                                            <div className="form-group">
                                                                <label> Fuel Type: </label>

                                                                <select class="form-select col-12" name="fuelType" aria-label="Default select example" onChange={this.changeFuelTypeHandler}>
                                                                    <option value="Petrol" selected={this.state.fuelType == 'Petrol'}>Petrol</option>
                                                                    <option value="CNG" selected={this.state.fuelType == 'CNG'}>CNG</option>
                                                                    <option value="Diesel" selected={this.state.fuelType == 'Diesel'}>Diesel</option>

                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Transmission: </label>

                                                                <select class="form-select col-12" name="transmission" aria-label="Default select example" onChange={this.changeTransmissionHandler}>
                                                                    <option value="Automatic" selected={this.state.transmission == 'Automatic'}>Automatic</option>
                                                                    <option value="Manual" selected={this.state.transmission == 'Manual'}>Manual</option>

                                                                </select>

                                                            </div>

                                                            <div className="form-group">
                                                                <label> Ex Showroom: </label>
                                                                <input placeholder="Ex Showroom" name="exShowroom" className="form-control"
                                                                    value={this.state.exShowroom} onChange={this.changeExShowroomHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Registration Amount: </label>
                                                                <input placeholder="Registration Amount" name="regAmount" className="form-control"
                                                                    value={this.state.regAmount} onChange={this.changeRegAmountHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Insurance Payment: </label>
                                                                <input placeholder="Insurance Payment" name="insPayment" className="form-control"
                                                                    value={this.state.insPayment} onChange={this.changeInsPaymentHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Auto Card: </label>
                                                                <input placeholder="Auto Card" name="autoCard" className="form-control"
                                                                    value={this.state.autoCard} onChange={this.changeAutoCardHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Fast Tag: </label>
                                                                <input placeholder="Fast Tag" name="fastTag" className="form-control"
                                                                    value={this.state.fastTag} onChange={this.changeFastTagHandler} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label> Discount: </label>
                                                                <input placeholder="Discount" name="discount" className="form-control"
                                                                    value={this.state.discount} onChange={this.changeDiscountHandler} />
                                                            </div>

                                                            <Multiselect
                                                                options={this.state.allColors} // Options to display in the dropdown
                                                                selectedValues={this.state.colors} // Preselected value to persist in dropdown
                                                                onSelect={this.onSelect} // Function will trigger on select event
                                                                onRemove={this.onRemove} // Function will trigger on remove event
                                                                displayValue="colorName" // Property name to display in the dropdown options
                                                            /> */}


                                                    </div>
                                                </div>
                                            </div>
                                            <hr />



                                        </div>
                                    </div>



                                </div>


                            </div>

                            {/* <!-- Content Row --> */}


                            {/* <!-- Content Row --> */}


                        </div>
                        {/* <!-- /.container-fluid --> */}

                    </div>
                    {/* <!-- End of Main Content --> */}
                    <BackEndFooterComponent />

                </div>
                {/* <!-- End of Content Wrapper --> */}

            </div>
            // <!-- End of Page Wrapper --> 


        )
    }
}

export default CreateSpecificationValueComponent
