import React, { Component } from "react";
import FooterComponent from "./FooterComponent";
import VariantService from "../services/VariantService";
import CustomerService from "../services/CustomerService";
import UploadFilesService from "../services/UploadFilesService";

import ModelService from "../services/ModelService";
import BrandService from "../services/BrandService";
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../services/auth.service";
import CityService from "../services/CityService";
import FrontTopBarComponent from "./FrontTopBarComponent";
import ScriptTag from "react-script-tag";
import FrontEndFooterComponent from "./FrontEndFooterComponent";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import CurrencyFormat from "react-currency-format";
// const required = value => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

class PicturesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variantId: "",
      id: this.props.match.params.id,
      model: {},
      brand: {},
      variant: {},
      variants: [],
      specifications: [],
      features: [],
      hex: "",
      colors: [],
      map1: [],
      variantNameStr: "",
      customerName: "",
      customerNameVal: "",
      customerEmailId: "",
      customerMoblieNumber: "",
      customerMoblieNumberVal: "",
      customerEmail: "",
      customerEmailVal: "",
      onRoadPrice: 0.0,
      imageInfos: [],
    };

    this.saveOrUpdateCustomer = this.saveOrUpdateCustomer.bind(this);
    this.changeCustomerNameHandler = this.changeCustomerNameHandler.bind(this);
    this.changecustomerNumberHandler =
      this.changecustomerNumberHandler.bind(this);
    this.changeCustomerEmailHandler =
      this.changeCustomerEmailHandler.bind(this);
  }

  componentDidMount() {
    ModelService.getModelById(this.state.id).then((res) => {
      this.setState({ model: res.data });
      this.setState({ brand: res.data.brand });
      this.setState({ variants: res.data.variants });
      this.setState({ variant: res.data.variants[0] });
      this.setState({
        specifications: res.data.variants[0].specificationValues,
      });
      this.setState({ features: res.data.variants[0].featureValues });
      this.setState({
        onRoadPrice:
          this.state.variant.exShowroom +
          this.state.variant.regAmount +
          this.state.variant.insPayment +
          this.state.variant.autoCard +
          this.state.variant.fastTag -
          this.state.variant.discount,
      });
    });

    UploadFilesService.getFiles(this.state.id).then((response) => {
      // alert("in upload " + response);
      this.setState({
        imageInfos: response.data,
      });
    });
  }
  saveOrUpdateCustomer = (e) => {
    e.preventDefault();
    if (this.state.customerName === "") {
      this.setState({
        customerNameVal: "Invalid!, Please enter  customer name",
      });
      return false;
    }

    if (this.state.customerEmail === "") {
      this.setState({ customerEmailVal: "Invalid!, Please enter email id" });
      return false;
    }

    if (this.state.customerMoblieNumber === "") {
      this.setState({
        customerMoblieNumberVal: "Invalid!, Please enter mobile number",
      });
      return false;
    }
    let customer = {
      name: this.state.customerName,
      mobile: this.state.customerMoblieNumber,
      email: this.state.customerEmail,
      inquiry:
        this.state.brand.brandName +
        "-" +
        this.state.model.modelName +
        "-" +
        this.state.variant.variantName,
      brandId: this.state.brand.brandId,
    };
    console.log("customer => " + JSON.stringify(customer));

    CustomerService.createCustomer(customer).then((res) => {
      window.location.reload(false);
    });
  };

  changeCustomerNameHandler = (event) => {
    this.setState({ customerName: event.target.value });
  };

  changecustomerNumberHandler = (event) => {
    this.setState({ customerMoblieNumber: event.target.value });
  };

  changeCustomerEmailHandler = (event) => {
    this.setState({ customerEmail: event.target.value });
  };

  changeVariantIdHandler = (event) => {
    this.setState({ variantId: event.target.value });
    console.log("variantId", event.target.value);
    if (event.target.value !== "") {
      VariantService.getVariantById(event.target.value).then((res) => {
        console.log(res.data);
        this.setState({ model: res.data.model });
        this.setState({ brand: res.data.model.brand });
        this.setState({ variant: res.data });
        this.setState({ specifications: res.data.specificationValues });
        this.setState({ features: res.data.featureValues });
        this.setState({
          onRoadPrice:
            this.state.variant.exShowroom +
            this.state.variant.regAmount +
            this.state.variant.insPayment +
            this.state.variant.autoCard +
            this.state.variant.fastTag -
            this.state.variant.discount,
        });
        this.setState({
          colors: this.state.variant.colors
            ? this.state.variant.colors.map((color) => color.colorCode)
            : [],
        });

        console.log(this.state.colors);
      });
    }
  };

  render() {
    const { models, page, count, pageSize, imageInfos } = this.state;
    return (
      <div>
        {/* Meta */}
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content />
        <meta name="author" content />
        <meta name="robots" content />
        <meta name="description" content="Mobhil Car Dealer HTML Template" />
        <meta property="og:title" content="Mobhil Car Dealer HTML Template" />
        <meta
          property="og:description"
          content="Mobhil Car Dealer HTML Template"
        />
        <meta
          property="og:image"
          content="https://mobhil.dexignlab.com/xhtml/social-image.png"
        />
        <meta name="format-detection" content="telephone=no" />
        {/* Mobile Specific */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Title */}
        <title>Mobhil Car Dealer HTML Template</title>
        {/* Favicon icon */}
        <link rel="icon" type="image/png" href="images/favicon.png" />
        {/* Stylesheet */}
        <link
          href="vendor/lightgallery/css/lightgallery.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-select/css/bootstrap-select.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/magnific-popup/magnific-popup.min.css"
          rel="stylesheet"
        />
        <link href="vendor/swiper/swiper-bundle.min.css" rel="stylesheet" />
        <link href="vendor/splitting/dist/splitting.css" rel="stylesheet" />
        <link href="vendor/aos/aos.css" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap"
          rel="stylesheet"
        />
        {/* Custom Stylesheet */}
        <link rel="stylesheet" href="vendor/rangeslider/rangeslider.css" />
        <link rel="stylesheet" href="css/style.css" />
        <link rel="stylesheet" className="skin" href="css/skin/skin-1.css" />
        <div id="loading-area" className="loading-page-1">
          <div className="spinner">
            <div className="ball" />
            <p>LOADING</p>
          </div>
        </div>
        <div className="page-wraper">
          {/* Header */}
          <FrontTopBarComponent />
          {/* Header End */}
          <div className="page-content bg-white">
            {/* Banner  */}
            <div
              className="dlab-bnr-inr style-1 overlay-black-middle"
              style={{ backgroundImage: "url(images/banner/bnr2.jpg)" }}
            >
              <div className="container">
                <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">Car Pictures</h1>
                  <div className="dlab-separator" />
                </div>
              </div>
            </div>
            {/* Banner End */}
            <div className="car-details-info">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="nav">
                      <ul>
                        <li className="active">
                          <a href="/display-variant-New/${id}">Overview</a>
                        </li>
                        <li>
                          <a href="/specifications/65">Specifications</a>
                        </li>
                        <li>
                          <a href="/price/65">Price</a>
                        </li>
                        <li>
                          <a href="/compare">Compare</a>
                        </li>
                        <li>
                          <a href="/pictures/65">Pictures</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="content-inner-2">
              <div className="section-head text-center">
                <h3 className="h3">Hyundai Magna Plus Pictures</h3>
              </div>
              <div className="site-filters style-1 clearfix center mb-5">
                <ul className="filters" data-bs-toggle="buttons">
                  <li className="btn active">
                    <input type="radio" />
                    <a href="javascript:void(0);">All Pictures</a>
                  </li>
                  <li data-filter=".interior" className="btn">
                    <input type="radio" />
                    <a href="javascript:void(0);">Interior</a>
                  </li>
                  <li data-filter=".exterior" className="btn">
                    <input type="radio" />
                    <a href="javascript:void(0);">Exterior</a>
                  </li>
                  <li data-filter=".road-test" className="btn">
                    <input type="radio" />
                    <a href="javascript:void(0);">Road Test</a>
                  </li>
                </ul>
              </div>
              <div className="container-fluid clearfix p-a10">
                {imageInfos &&
                  imageInfos.map((img, index) => (
                    <div
                      className="row lightgallery car-gallery sp4"
                      id="masonry"
                    >
                      <a
                        data-exthumbimage="images/gallery/pic1.jpg"
                        data-src="images/gallery/pic1.jpg"
                        className="card-container col-lg-3 col-md-4 col-sm-6 interior lightimg"
                      >
                        <img
                          src={
                            `https://api.bsecars.com/api/v1/files/` +
                            img.modelImageName
                          }
                          alt={img.modelImageName}
                        />
                      </a>
                      <a
                        data-exthumbimage="images/gallery/pic2.jpg"
                        data-src="images/gallery/pic2.jpg"
                        className="card-container col-lg-3 col-md-4 col-sm-6 interior lightimg"
                      >
                        <img
                          src={
                            `https://api.bsecars.com/api/v1/files/` +
                            img.modelImageName
                          }
                          alt={img.modelImageName}
                        />
                      </a>
                      <a
                        data-exthumbimage="images/gallery/pic3.jpg"
                        data-src="images/gallery/pic3.jpg"
                        className="card-container col-lg-3 col-md-4 col-sm-6 exterior lightimg"
                      >
                        <img
                          src={
                            `https://api.bsecars.com/api/v1/files/` +
                            img.modelImageName
                          }
                          alt={img.modelImageName}
                        />
                      </a>
                     
                    </div>
                  ))}
              </div>
            </section>
            <section className="content-inner">
              <div className="container">
                <div className="row call-to-action-bx">
                  <div className="col-xl-5 col-lg-6 me-auto">
                    <div className="section-head">
                      <h2 className="title text-white">
                        Have any question about us?
                      </h2>
                    </div>
                    <a
                      href="tel:224000221133"
                      className="btn btn-white me-3 mb-2"
                    >
                      <i className="fas fa-phone-volume me-sm-3 me-0 shake" />
                      <span className="d-sm-inline-block d-none">
                        224 000 22 11 33
                      </span>
                    </a>
                    <a
                      href="contact-us.html"
                      className="btn btn-outline-white effect-1  mb-2"
                    >
                      <span>Contact Us</span>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="media-box">
                      <img
                        src="https://mobhil.dexignlab.com/xhtml/images/about/pic5.jpg"
                        className="main-img"
                        alt=""
                      />
                      <img
                        src="images/pattern/pattern7.png"
                        className="pt-img move-1"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* Footer */}
          <footer className="site-footer style-1" id="footer">
            <div className="footer-top">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-md-6 col-sm-12 ">
                    <div className="widget widget_about">
                      <div className="footer-logo">
                        <img src="images/logo.png" alt="" />
                      </div>
                      <h5 className="m-b20">Best car dealer in europe</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <ul className="social-list style-1">
                        <li>
                          <a href="https://www.youtube.com/">
                            <i className="fab fa-youtube" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/">
                            <i className="fab fa-linkedin" />
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget widget_categories p-l50">
                      <div className="widget-title">
                        <h5 className="title">Quick Links</h5>
                      </div>
                      <ul>
                        <li className="cat-item">
                          <a href="about-us.html">About us</a>
                        </li>
                        <li className="cat-item">
                          <a href="/contact-us">Contact us</a>
                        </li>
                        <li className="cat-item">
                          <a href="car-listing.html">Products</a>
                        </li>
                        <li className="cat-item">
                          <a href="javascript:void(0);">Login</a>
                        </li>
                        <li className="cat-item">
                          <a href="javascript:void(0);">Sign Up</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="widget widget_categories">
                      <div className="widget-title">
                        <h5 className="title">Support</h5>
                      </div>
                      <ul>
                        <li className="cat-item">
                          <a href="javascript:void(0);">Affiliates</a>
                        </li>
                        <li className="cat-item">
                          <a href="javascript:void(0);">Sitemap</a>
                        </li>
                        <li className="cat-item">
                          <a href="javascript:void(0);">Cancelation Policy</a>
                        </li>
                        <li className="cat-item">
                          <a href="javascript:void(0);">Privacy Policy</a>
                        </li>
                        <li className="cat-item">
                          <a href="javascript:void(0);">Legal Disclaimer</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="widget">
                      <div className="widget-title">
                        <h5 className="title">Contact</h5>
                      </div>
                      <div className="icon-bx-wraper style-2 m-b20">
                        <div className="icon-bx-sm radius">
                          <span className="icon-cell">
                            <svg
                              width={23}
                              height={25}
                              viewBox="0 0 23 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.6675 23.3511H20.6854V1.97607C20.6854 1.35475 20.1578 0.851074 19.5068 0.851074H3.00684C2.35592 0.851074 1.82826 1.35475 1.82826 1.97607V23.3511H0.846122C0.520689 23.3511 0.256836 23.6029 0.256836 23.9136V24.8511H22.2568V23.9136C22.2568 23.6029 21.993 23.3511 21.6675 23.3511ZM6.54255 4.41357C6.54255 4.10293 6.8064 3.85107 7.13184 3.85107H9.09612C9.42155 3.85107 9.68541 4.10293 9.68541 4.41357V6.28857C9.68541 6.59922 9.42155 6.85107 9.09612 6.85107H7.13184C6.8064 6.85107 6.54255 6.59922 6.54255 6.28857V4.41357ZM6.54255 8.91357C6.54255 8.60293 6.8064 8.35107 7.13184 8.35107H9.09612C9.42155 8.35107 9.68541 8.60293 9.68541 8.91357V10.7886C9.68541 11.0992 9.42155 11.3511 9.09612 11.3511H7.13184C6.8064 11.3511 6.54255 11.0992 6.54255 10.7886V8.91357ZM9.09612 15.8511H7.13184C6.8064 15.8511 6.54255 15.5992 6.54255 15.2886V13.4136C6.54255 13.1029 6.8064 12.8511 7.13184 12.8511H9.09612C9.42155 12.8511 9.68541 13.1029 9.68541 13.4136V15.2886C9.68541 15.5992 9.42155 15.8511 9.09612 15.8511ZM12.8283 23.3511H9.68541V19.4136C9.68541 19.1029 9.94926 18.8511 10.2747 18.8511H12.239C12.5644 18.8511 12.8283 19.1029 12.8283 19.4136V23.3511ZM15.9711 15.2886C15.9711 15.5992 15.7073 15.8511 15.3818 15.8511H13.4176C13.0921 15.8511 12.8283 15.5992 12.8283 15.2886V13.4136C12.8283 13.1029 13.0921 12.8511 13.4176 12.8511H15.3818C15.7073 12.8511 15.9711 13.1029 15.9711 13.4136V15.2886ZM15.9711 10.7886C15.9711 11.0992 15.7073 11.3511 15.3818 11.3511H13.4176C13.0921 11.3511 12.8283 11.0992 12.8283 10.7886V8.91357C12.8283 8.60293 13.0921 8.35107 13.4176 8.35107H15.3818C15.7073 8.35107 15.9711 8.60293 15.9711 8.91357V10.7886ZM15.9711 6.28857C15.9711 6.59922 15.7073 6.85107 15.3818 6.85107H13.4176C13.0921 6.85107 12.8283 6.59922 12.8283 6.28857V4.41357C12.8283 4.10293 13.0921 3.85107 13.4176 3.85107H15.3818C15.7073 3.85107 15.9711 4.10293 15.9711 4.41357V6.28857Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="icon-content">
                          <p>77 Highfield Road London N36 7SB</p>
                        </div>
                      </div>
                      <div className="icon-bx-wraper style-2">
                        <div className="icon-bx-sm radius">
                          <span className="icon-cell">
                            <svg
                              width={22}
                              height={24}
                              viewBox="0 0 22 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.3722 16.9589L16.5597 14.7089C16.3541 14.6134 16.1257 14.5932 15.9087 14.6515C15.6917 14.7099 15.4979 14.8435 15.3566 15.0324L13.2254 17.873C9.88055 16.1526 7.18876 13.2161 5.61172 9.56722L8.21562 7.24222C8.38908 7.08832 8.51185 6.87696 8.56535 6.64014C8.61884 6.40331 8.60015 6.15392 8.51211 5.92972L6.44961 0.67973C6.35298 0.438047 6.18207 0.240721 5.96636 0.121777C5.75065 0.00283366 5.50366 -0.0302721 5.26797 0.0281687L0.799219 1.15317C0.571987 1.21041 0.36925 1.34999 0.224097 1.54911C0.0789444 1.74824 -5.2345e-05 1.99516 2.60228e-08 2.24957C2.60228e-08 14.273 8.9332 23.9995 19.9375 23.9995C20.1708 23.9997 20.3972 23.9136 20.5799 23.7552C20.7625 23.5969 20.8905 23.3756 20.943 23.1277L21.9742 18.2527C22.0274 17.9943 21.9965 17.7238 21.8866 17.4877C21.7767 17.2515 21.5948 17.0646 21.3722 16.9589Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="icon-content">
                          <p>412 444 1124</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer Bottom */}
            <div className="footer-bottom">
              <div className="container">
                <div className="row align-items-center fb-inner spno">
                  <div className="col-12 text-center">
                    <span className="copyright-text">
                      Copyright © 2022{" "}
                      <a
                        href="https://dexignlab.com/"
                        className="text-primary"
                        target="_blank"
                      >
                        DexignLabs
                      </a>{" "}
                      All rights reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          {/* Footer End */}
          <button className="scroltop icon-up" type="button">
            <i className="fas fa-arrow-up" />
          </button>
        </div>
        {/* JAVASCRIPT FILES ========================================= */}
        {/* JQUERY.MIN JS */}
        {/* BOOTSTRAP.MIN JS */}
        {/* BOOTSTRAP.MIN JS */}
        {/* RANGESLIDER */}
        {/* MAGNIFIC POPUP JS */}
        {/* ISOTOPE */}
        {/* LIGHTGALLERY */}
        {/* IMAGESLOADED */}
        {/* MASONRY */}
        {/* LIGHTGALLERY */}
        {/* WAYPOINTS JS */}
        {/* COUNTERUP JS */}
        {/* OWL-CAROUSEL */}
        {/* AOS */}
        {/* OWL-CAROUSEL */}
        {/* AJAX */}
        {/* CUSTOM JS */}
      </div>
    );
  }
}

export default PicturesComponent;
