import axios from 'axios';

const DASHBOARD_API_BASE_URL = "https://api.bsecars.com/api/v1/dashboard";

class DashboardService {

    getDashboardData(){
        return axios.get(DASHBOARD_API_BASE_URL);
    }

    getModelDashboardData(modelID){
        return axios.get(DASHBOARD_API_BASE_URL + '/' + modelID);
    }

    createBrand(brand){
        return axios.post(DASHBOARD_API_BASE_URL, brand);
    }

    getBrandById(brandId){
        return axios.get(DASHBOARD_API_BASE_URL + '/' + brandId);
    }

    createVarients(varient){
        return axios.post(DASHBOARD_API_BASE_URL, varient);
    }

    getVarientById(varientId){
        return axios.get(DASHBOARD_API_BASE_URL + '/' + varientId);
    }
    createDealer(dealer){
        return axios.post(DASHBOARD_API_BASE_URL, dealer);
    }

    getDealerById(dealerId){
        return axios.get(DASHBOARD_API_BASE_URL + '/' + dealerId);
    }

}

export default new DashboardService()