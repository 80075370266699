import axios from "axios";

const API_URL = "https://api.bsecars.com/api/auth/";

class AuthService {
  login(username, password) {

    // alert(username + password);
    return axios
      .post(API_URL + "signin", {
        username,
        password
      })
      .then(response => {

        // alert(JSON.stringify(response.data));

        if (response.data.accessToken) {



        
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        localStorage.setItem("user", JSON.stringify(response.data));

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }
changePassword(id, user){
  // alert(JSON.stringify(user));
  return axios.put(API_URL + "update/"+id, user);
}
  register(username, email, password, role) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      role
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
