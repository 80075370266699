import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import ExecutiveService from "../services/ExecutiveService";

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';

// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";
// import { isEmail } from "validator";

class CreateExecutiveComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
        // step 2
        id: this.props.match.params.id,
        executiveName: '', executiveNameVal: '',
        executiveAddress: '', executiveAddressVal: '',
        executiveEmailId: '',  executiveEmailIdVal: '',
        executiveMoblieNumber: '',  executiveMoblieNumberVal: '',      
    }
    this.changeExecutiveNameHandler = this.changeExecutiveNameHandler.bind(this);
    this.saveOrUpdateExecutive = this.saveOrUpdateExecutive.bind(this);
    
}

// step 3
componentDidMount(){

    // step 4
    if(this.state.id === '0'){
        return
    }else{
        ExecutiveService.getExecutiveById(this.state.id).then( (res) =>{
            let executive = res.data;
            this.setState({
                executiveName: executive.executiveName,
                executiveAddress: executive.executiveAddress,
                executiveEmailId: executive.executiveEmailId,
                executiveMoblieNumber: executive.executiveMoblieNumber,
            });
        });
    }        
}
saveOrUpdateExecutive = (e) => {
    e.preventDefault();
    if (this.state.executiveName === '') {
        this.setState({executiveNameVal: 'Invalid!, Please enter  executive name '})
        return false;
    } 
    if (this.state.executiveAddress === '') {
        this.setState({executiveAddressVal: 'Invalid!, Please enter  address'})
        return false;
    } 
    if (this.state.executiveMoblieNumber === '') {
        this.setState({executiveMoblieNumberVal: 'Invalid!, Please enter mobile number'})
        return false;
    } 
    if (this.state.executiveEmailId === '') {
        this.setState({executiveEmailIdVal: 'Invalid!, Please enter  email id '})
        return false;
    } 
    let executive = {executiveName: this.state.executiveName, executiveAddress: this.state.executiveAddress, executiveEmailId: this.state.executiveEmailId, executiveMoblieNumber: this.state.executiveMoblieNumber};
    console.log('executive => ' + JSON.stringify(executive));

    // step 5
    if(this.state.id === '0'){
        ExecutiveService.createExecutive(executive).then(res =>{
            this.props.history.push('/executives');
        });
    }else{
        ExecutiveService.updateExecutive(executive, this.state.id).then( res => {
            this.props.history.push('/executives');
        });
    }
}

changeExecutiveNameHandler= (event) => {
    this.setState({executiveName: event.target.value});
}


changeExecutiveAddressHandler= (event) => {
    this.setState({executiveAddress: event.target.value});
}

changeExecutiveEmailHandler= (event) => {
    this.setState({executiveEmailId: event.target.value});
}
changeexecutiveNumberHandler= (event) => {
    this.setState({executiveMoblieNumber: event.target.value});
}



cancel(){
    this.props.history.push('/Executives');
}

getTitle(){
    if(this.state.id === '0'){
        return <h6 className="m-0 font-weight-bold text-primary">Add Executive</h6>
    }else{
        return <h6 className="m-0 font-weight-bold text-primary">Update Executive</h6>
    }
}


      render() {
        if (this.state.redirect) {
          return <Redirect to={this.state.redirect} />
        }
    
   //     const { currentUser } = this.state;
    
        return (

 // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Executive</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                <a class="btn btn-sm btn-primary float-right   mr-3" href="/Executives" role="button">List Executive</a>

                                 {  this.getTitle() }
                                </div>
                                <div class="card-body">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                              
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Executive Name: </label>
                                            <input placeholder="Executive Name" name="executiveName" className="form-control" 
                                                value={this.state.executiveName} onChange={this.changeExecutiveNameHandler}/>
                                                <p className="text-danger"> {this.state.executiveNameVal }</p>
                                        </div>
                                        <div className = "form-group">
                                            <label> Executive Address: </label>
                                            <input placeholder="Executive Address" name="executiveAddress" className="form-control" 
                                                value={this.state.executiveAddress} onChange={this.changeExecutiveAddressHandler}/>
                                                <p className="text-danger"> {this.state.executiveAddressVal }</p>
                                        </div>
                                        <div className = "form-group">
                                            <label> Executive Email: </label>
                                            <input placeholder="Executive Email" name="executiveEmail" className="form-control" 
                                                value={this.state.executiveEmailId} onChange={this.changeExecutiveEmailHandler}/>
                                                <p className="text-danger"> {this.state.executiveEmailIdVal }</p>
                                        </div>
                                        <div className = "form-group">
                                            <label> Executive Number: </label>
                                            <input placeholder="Executive Number" name="executiveNumber" className="form-control" 
                                                value={this.state.executiveMoblieNumber} onChange={this.changeexecutiveNumberHandler}/>
                                                <p className="text-danger"> {this.state.executiveMoblieNumberVal }</p>
                                        </div>

                                        <button className="btn btn-success" onClick={this.saveOrUpdateExecutive}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 










        );
      }
}

export default CreateExecutiveComponent
