import React, { Component } from 'react'
import CityService from '../services/CityService';
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';



class CreateCityComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
           cityName: '', cityNameVal: ''
          
        }
        this.changeCityNameHandler = this.changeCityNameHandler.bind(this);

        this.saveOrUpdateCity = this.saveOrUpdateCity.bind(this);
        
    }

    // step 3
    componentDidMount(){

        // step 4
        if(this.state.id === '0'){
            return
        }else{
            CityService.getCityById(this.state.id).then( (res) =>{
                let city = res.data;
                this.setState({
                    cityName: city.cityName,
                });
            });
        }        
    }
    saveOrUpdateCity = (e) => {
        e.preventDefault();
        if (this.state.cityName === '') {
            this.setState({cityNameVal: 'Invalid!, Please enter  city Name'})
            return false;
        } 
        let city = {cityName: this.state.cityName};
        console.log('city => ' + JSON.stringify(city));

        // step 5
        if(this.state.id === '0'){
            CityService.createCity(city).then(res =>{
                this.props.history.push('/citys');
            });
        }else{
            CityService.updateCity(city, this.state.id).then( res => {
                this.props.history.push('/citys');
            });
        }
    }
    
    changeCityNameHandler= (event) => {
        this.setState({cityName: event.target.value});
    }

  

    cancel(){
        this.props.history.push('/citys');
    }

    getTitle(){
        if(this.state.id === '0'){
            return <h6 className="m-0 font-weight-bold text-primary">Add City</h6>
        }else{
            return <h6 className="m-0 font-weight-bold text-primary">Update City</h6>
        }
    }
    render() {
        return (
           // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">city</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                <a class="btn btn-sm btn-primary float-right   mr-3" href="/Citys" role="button">List City</a>

                                 {  this.getTitle() }
                                </div>
                                <div class="card-body">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                              
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> City Name: </label>
                                            <input placeholder="City Name" name="cityName" className="form-control" 
                                                value={this.state.cityName} onChange={this.changeCityNameHandler}/>
                                                <p className="text-danger"> {this.state.cityNameVal }</p>
                                        </div>
                                      

                                        <button className="btn btn-success" onClick={this.saveOrUpdateCity}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 

            
        )
    }
}

export default CreateCityComponent
