import axios from 'axios';

const MODEL_API_BASE_URL = "https://api.bsecars.com/api/v1/models";

class ModelService {
    getAll(params) {
        return axios.get("https://api.bsecars.com/api/v1/all_models", { params });
    }
    getModels() {
        return axios.get(MODEL_API_BASE_URL);
    }

    createModel(model) {
        return axios.post(MODEL_API_BASE_URL, model);
    }

    getModelById(modelId) {
        return axios.get(MODEL_API_BASE_URL + '/' + modelId);
    }

    updateModel(model, modelId) {
        return axios.put(MODEL_API_BASE_URL + '/' + modelId, model);
    }

    deleteModel(modelId) {
        return axios.delete(MODEL_API_BASE_URL + '/' + modelId);
    }
}

export default new ModelService()