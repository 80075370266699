import React, { Component } from 'react'
import HistoryService from '../services/HistoryService'

class ViewHistoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            history: {}
        }
    }

    componentDidMount(){
        HistoryService.getHistoryById(this.state.id).then( res => {
            this.setState({history: res.data});
        })
    }

    render() {
        return (
            <div>
                <br></br>
                <div className = "card col-md-6 offset-md-3">
                    <h3 className = "text-center"> View History</h3>
                    <div className = "card-body">
                        <div className = "row">
                            <label> Product Name: </label>
                            <div> { this.state.history.name }</div>
                        </div>
                        <div className = "row">
                            <label> Product Price: </label>
                            <div> { this.state.history.productPrice }</div>
                        </div>
                        <div className = "row">
                            <label> Rent Price: </label>
                            <div> { this.state.history.rentPrice }</div>
                        </div>
                        <div className = "row">
                            <label> Maintainance Price: </label>
                            <div> { this.state.history.maintainancePrice }</div>
                        </div>
                        <div className = "row">
                            <label> Model Year: </label>
                            <div> { this.state.history.modelYear }</div>
                        </div>
                        <div className = "row">
                            <label> Quantity: </label>
                            <div> { this.state.history.quantity }</div>
                        </div>
                        <div className = "row">
                            <label> Late Charges: </label>
                            <div> { this.state.history.lateCharges }</div>
                        </div>
                        <div className = "row">
                            <label> Category Of Product: </label>
                            <div> { this.state.history.categoryOfProduct }</div>
                        </div>
                        <div className = "row">
                            <label> Discount: </label>
                            <div> { this.state.history.discount }</div>
                        </div>
                        <div className = "row">
                            <label> Customer Name: </label>
                            <div> { this.state.history.custName }</div>
                        </div>
                        <div className = "row">
                            <label> Mobile no: </label>
                            <div> { this.state.history.mobile }</div>
                        </div>
                        <div className = "row">
                            <label> Email: </label>
                            <div> { this.state.history.email }</div>
                        </div>
                        <div className = "row">
                            <label> Address: </label>
                            <div> { this.state.history.address }</div>
                        </div>
                        <div className = "row">
                            <label> Alternate Mobile No: </label>
                            <div> { this.state.history.alternateMobile }</div>
                        </div>
                        <div className = "row">
                            <label> Aadhar: </label>
                            <div> { this.state.history.aadhar }</div>
                        </div>
                        <div className = "row">
                            <label> Pan: </label>
                            <div> { this.state.history.pan }</div>
                        </div>
                        <div className = "row">
                            <label> organization: </label>
                            <div> { this.state.history.organization }</div>
                        </div>
                        <div className = "row">
                            <label> City: </label>
                            <div> { this.state.history.city }</div>
                        </div>
                        <div className = "row">
                            <label> State: </label>
                            <div> { this.state.history.state }</div>
                        </div>
                        <div className = "row">
                            <label> Nationality: </label>
                            <div> { this.state.history.nationality }</div>
                        </div>
                        <div className = "row">
                            <label> Zip Code: </label>
                            <div> { this.state.history.zipcode }</div>
                        </div>
                        <div className = "row">
                            <label> Issue Date: </label>
                            <div> { this.state.history.issueDate }</div>
                        </div>
                        <div className = "row">
                            <label> Deadline: </label>
                            <div> { this.state.history.deadline }</div>
                        </div>
                        <div className = "row">
                            <label> Deposite: </label>
                            <div> { this.state.history.deposite }</div>
                        </div>
                        <div className = "row">
                            <label> Payment Deadline: </label>
                            <div> { this.state.history.paymentDeadline }</div>
                        </div>
                        <div className = "row">
                            <label> Monthly Installation Price: </label>
                            <div> { this.state.history.monthlyInstallPrice }</div>
                        </div>
                        <div className = "row">
                            <label> Monthly Installation Date: </label>
                            <div> { this.state.history.monthlyInstallDate }</div>
                        </div>
                        <div className = "row">
                            <label> Payment Collector Name: </label>
                            <div> { this.state.history.paymentCollectorName }</div>
                        </div>
                        <div className = "row">
                            <label> Payment Method: </label>
                            <div> { this.state.history.paymentMethod }</div>
                        </div>
                        <div className = "row">
                            <label> Late Charges: </label>
                            <div> { this.state.history.lateCharges }</div>
                        </div>
                        <div className = "row">
                            <label> Product Damage Charges: </label>
                            <div> { this.state.history.productDamageCharges }</div>
                        </div>
                        <div className = "row">
                            <label> Transportation Charge: </label>
                            <div> { this.state.history.transportationCharge }</div>
                        </div>
                        <div className = "row">
                            <label> Taxex: </label>
                            <div> { this.state.history.taxex }</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewHistoryComponent
