import React, { Component } from 'react'
import RentalInformationService from '../services/RentalInformationService';

class CreateRentalInformationComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            issueDate: '',
            endDate: '',
            paymentDueDate: '',
            installmentPrice:'',
            installmentDate: '',
            paymentColletedBy: '',
            paymentMethod: '',
            lateCharges: '',
            productDamageCharges: '',
            transpotationCharges: '',
            gstTaxes: '',
            initialPayment: ''

        }
        this.changeIssueDateHandler = this.changeIssueDateHandler.bind(this);
        this.changeEndDateHandler = this.changeEndDateHandler.bind(this);
        this.changePaymentDueDateIdHandler = this.changePaymentDueDateIdHandler.bind(this);
        this.changeInstallmentPriceHandler = this.changeInstallmentPriceHandler.bind(this);
        this.changeInstallmentDateHandler =this.changeInstallmentDateHandler.bind(this);
        this.changePaymentCollectedByHandler= this.changePaymentCollectedByHandler.bind(this);
        this.changePaymentMethodHandler= this.changePaymentMethodHandler.bind(this);
        this.changeLateChargesHandler= this.changeLateChargesHandler.bind(this);
        this.changeProductDamageChargesHandler= this.changeProductDamageChargesHandler.bind(this);
        this.changeTranspotationChargesHandler =this.changeTranspotationChargesHandler.bind(this);
        this.changeGstTaxesHandler =this.changeGstTaxesHandler.bind(this);
        this.changeInitialPaymentHandler=this.changeInitialPaymentHandler.bind(this);
         
        this.saveOrUpdateRentalInformation = this.saveOrUpdateRentalInformation.bind(this);
    }

    // step 3
    componentDidMount(){

        // step 4
        if(this.state.id === '0'){
            return
        }else{
            RentalInformationService.getRentalInformationById(this.state.id).then( (res) =>{
                let rentalInformation = res.data;
                this.setState({issueDate: rentalInformation.issueDate,
                    endDate: rentalInformation.endDate,
                    initialPayment : rentalInformation.initialPayment,
                    paymentDueDate:rentalInformation.paymentDueDate,
                    installmentPrice: rentalInformation.installmentPrice,
                    installmentDate: rentalInformation.installmentDate,
                    paymentColletedBy: rentalInformation.paymentColletedBy,
                    paymentMethod: rentalInformation.paymentMethod,
                    lateCharges: rentalInformation.lateCharges,
                    transpotationCharges: rentalInformation.transpotationCharges,
                    productDamageCharges: rentalInformation.productDamageCharges,
                    gstTaxes: rentalInformation.gstTaxes,

                });
            });
        }        
    }
    saveOrUpdateRentalInformation = (e) => {
        e.preventDefault();
        let rentalInformation = {issueDate: this.state.issueDate, endDate: this.state.endDate, initialPayment: this.state.initialPayment, 
                            paymentColletedBy: this.state.paymentColletedBy, paymentDueDate: this.state.paymentDueDate, paymentMethod: this.state.paymentMethod, transpotationCharges: this.state.transpotationCharges
                        ,lateCharges: this.state.lateCharges, productDamageCharges: this.state.productDamageCharges, gstTaxes: this.state.gstTaxes,installmentDate: this.state.installmentDate, installmentPrice: this.state.installmentPrice};
        console.log('rentalInformation => ' + JSON.stringify(rentalInformation));

        // step 5
        if(this.state.id === '0'){
            RentalInformationService.createRentalInformation(rentalInformation).then(res =>{
                this.props.history.push('/rentalInformation');
            });
        }else{
            RentalInformationService.updateRentalInformation(rentalInformation, this.state.id).then( res => {
                this.props.history.push('/rentalInformation');
            });
        }
    }
    
    changeIssueDateHandler= (event) => {
        this.setState({issueDate: event.target.value});
    }

    changeEndDateHandler= (event) => {
        this.setState({endDate: event.target.value});
    }

    changeInitialPaymentHandler= (event) => {
        this.setState({initialPayment: event.target.value});
    }

    changeInstallmentDateHandler= (event) => {
        this.setState({installmentDate: event.target.value});
    }

    changeInstallmentPriceHandler= (event)=>{
        this.setState({installmentPrice: event.target.value})
    }

    changeLateChargesHandler= (event)=> {
        this.setState({lateCharges: event.target.value})
    }
    
    changePaymentCollectedByHandler= (event)=>{
        this.setState({paymentColletedBy: event.target.value})
    }

    changePaymentDueDateIdHandler= (event)=> {
        this.setState({paymentDueDate: event.target.value})
    }

    changePaymentMethodHandler=(event)=> {
        this.setState({paymentMethod: event.target.value})
    }

    changeProductDamageChargesHandler= (event)=> {
        this.setState({productDamageCharges: event.target.value})
    }

    changeTranspotationChargesHandler= (event)=> {
        this.setState({transpotationCharges: event.target.value})
    }

    changeGstTaxesHandler= (event)=> {
        this.setState({gstTaxes: event.target.value})
    }

    

    cancel(){
        this.props.history.push('/rentalInformation');
    }

    getTitle(){
        if(this.state.id === '0'){
            return <h3 className="text-center">Add Rental Information</h3>
        }else{
            return <h3 className="text-center">Update Rental Information</h3>
        }
    }
    render() {
        return (
            <div>
                <br></br>
                   <div className = "container">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                {
                                    this.getTitle()
                                }
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Issue Date: </label>
                                            <input placeholder="Issue Date" name="issueDate" className="form-control" 
                                                value={this.state.issueDate} onChange={this.changeIssueDateHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> End Date: </label>
                                            <input placeholder="End Date" name="endDate" className="form-control" 
                                                value={this.state.endDate} onChange={this.changeEndDateHandler}/>
                                        </div>
                                       
                                        <div className = "form-group">
                                            <label> Initial Payment: </label>
                                            <input placeholder="Intial Payment" name="initialPayment" className="form-control" 
                                                value={this.state.initialPayment} onChange={this.changeInitialPaymentHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Payment Collected By: </label>
                                            <input placeholder="Payment Collected By" name="paymentColletedBy" className="form-control" 
                                                value={this.state.paymentColletedBy} onChange={this.changePaymentCollectedByHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Payment Due Date: </label>
                                            <input placeholder="Payment Due Date" name="paymentDueDate" className="form-control" 
                                                value={this.state.paymentDueDate} onChange={this.changePaymentDueDateIdHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Payment Method: </label>
                                            <input placeholder="Payment Method" name="paymentMethod" className="form-control" 
                                                value={this.state.paymentMethod} onChange={this.changePaymentMethodHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Product Damage Charges: </label>
                                            <input placeholder="Poduct Damage Charges" name="productDamageCharges" className="form-control" 
                                                value={this.state.productDamageCharges} onChange={this.changeProductDamageChargesHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Late Charges: </label>
                                            <input placeholder="Late Charges" name="lateCharges" className="form-control" 
                                                value={this.state.lateCharges} onChange={this.changeLateChargesHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Transpotation Charges: </label>
                                            <input placeholder="Transpotation Charges" name="transpotationCharges" className="form-control" 
                                                value={this.state.transpotationCharges} onChange={this.changeTranspotationChargesHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Installment Price: </label>
                                            <input placeholder="Installment Price" name="installmentPrice" className="form-control" 
                                                value={this.state.installmentPrice} onChange={this.changeInstallmentPriceHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Installment Due Date: </label>
                                            <input placeholder="Installment Date" name="installmentDate" className="form-control" 
                                                value={this.state.installmentDate} onChange={this.changeInstallmentDateHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> GST Taxes: </label>
                                            <input placeholder="GST Taxes" name="gstTaxes" className="form-control" 
                                                value={this.state.gstTaxes} onChange={this.changeGstTaxesHandler}/>
                                        </div>
                                       

                                        <button className="btn btn-success" onClick={this.saveOrUpdateRentalInformation}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default CreateRentalInformationComponent
