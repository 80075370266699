import React, { Component } from "react";
import AuthService from "../services/auth.service";
import { Redirect } from "react-router-dom";

class FrontTopBarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      
    };
    
  }

  logout = () => {
    AuthService.logout();
    this.setState({ redirect: "/login" });
  };

  changePassword = () => {
    AuthService.changePassword();
    this.setState({ redirect: "/login" });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        {/* <!-- Header --> */}
        <header class="site-header mo-left header style-1">
          {/* <!-- Main Header --> */}
          <div class="sticky-header main-bar-wraper navbar-expand-lg">
            <div class="main-bar clearfix ">
              <div class="container clearfix">
                {/* <!-- Website Logo --> */}
                <div class="logo-header mostion logo-dark">
                  <a href="/">
                    <img src="/images/logo-favicon.jpg" alt=""></img>
                  </a>
                </div>
                {/* <!-- Nav Toggle Button --> */}
                <button
                  class="navbar-toggler collapsed navicon justify-content-end"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                {/* <!-- Extra Nav --> */}
                <div class="extra-nav">
                  <div class="extra-cell">
                    <a
                      href="tel:+9180073 58007"
                      class="btn btn-primary light phone-no shadow-none effect-1"
                    >
                      <span>
                        <i class="fas fa-phone-volume shake"></i>+91 80073 58007
                      </span>
                    </a>
                  </div>
                </div>
                {/* <!-- Extra Nav --> */}
                <div
                  class="header-nav navbar-collapse collapse justify-content-end"
                  id="navbarNavDropdown"
                >
                  <div class="logo-header">
                    <a href="/">
                      <img src="/images/logo-favicon.jpeg" alt=""></img>
                    </a>
                  </div>
                  <ul class="nav navbar-nav navbar navbar-left">
                    <li class="sub-menu-down">
                      <a href="/">HOME</a>
                    </li>

                    <li class="sub-menu-down">
                      <a href="/car-listing">CAR LISTING</a>
                    </li>

                    <li class="sub-menu-down">
                      <a href="/compare">COMPARE</a>
                    </li>

                    <li class="sub-menu-down">
                      <a href="/about-us">ABOUT</a>
                    </li>

                    <li class="sub-menu-down">
                      <a href="/contact-us">CONTACT</a>
                    </li>
                  </ul>
                  <div class="dlab-social-icon">
                    <ul>
                      <li>
                        <a
                          class="fab fa-facebook-f"
                          href="javascript:void(0);"
                        ></a>
                      </li>
                      <li>
                        <a
                          class="fab fa-twitter"
                          href="javascript:void(0);"
                        ></a>
                      </li>
                      <li>
                        <a
                          class="fab fa-linkedin-in"
                          href="javascript:void(0);"
                        ></a>
                      </li>
                      <li>
                        <a
                          class="fab fa-instagram"
                          href="javascript:void(0);"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Main Header End --> */}
        </header>
        {/* <!-- Header End --> */}
     
      </div>
    );
  }
}

export default FrontTopBarComponent;
