import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import SpecificationService from "../services/SpecificationService";

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';




  


class CreateSpecificationComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
        // step 2
        id: this.props.match.params.id,
        specificationName: '', specificationNameVal: '',
      
    }
    this.changeSpecificationNameHandler = this.changeSpecificationNameHandler.bind(this);

    this.saveOrUpdateSpecification = this.saveOrUpdateSpecification.bind(this);
    
}

// step 3
componentDidMount(){

    // step 4
    if(this.state.id === '0'){
        return
    }else{
        SpecificationService.getSpecificationById(this.state.id).then( (res) =>{
            let specification = res.data;
            this.setState({
                specificationName: specification.specificationName,
            });
        });
    }        
}
saveOrUpdateSpecification = (e) => {
    e.preventDefault();
    if (this.state.specificationName === '') {
        this.setState({specificationNameVal: 'Invalid!, Please enter specification name'})
        return false;
    } 
    let specification = {specificationId:this.state.id, specificationName: this.state.specificationName};
    console.log('specification => ' + JSON.stringify(specification));

    // step 5
    if(this.state.id === '0'){
        SpecificationService.createSpecification(specification).then(res =>{
            this.props.history.push('/specifications');
        });
    }else{
        SpecificationService.updateSpecification(specification, this.state.id).then( res => {
            this.props.history.push('/specifications');
        });
    }
}

changeSpecificationNameHandler= (event) => {
    this.setState({specificationName: event.target.value});
}



cancel(){
    this.props.history.push('/specifications');
}

getTitle(){
    if(this.state.id === '0'){
        return <h6 className="m-0 font-weight-bold text-primary">Add Specification</h6>
    }else{
        return <h6 className="m-0 font-weight-bold text-primary">Update Specification</h6>
    }
}

addSpecification(){
    this.props.history.push('/add-specification/0');
}

      render() {
        if (this.state.redirect) {
          return <Redirect to={this.state.redirect} />
        }
    
    
    
        return (

 // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Specification</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                <a class="btn btn-sm btn-primary float-right   mr-3" href="/specifications" role="button">List Specification</a>

                                 {  this.getTitle() }
                                </div>
                                <div class="card-body">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                              
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Specification Name: </label>
                                            <input placeholder="Specification Name" name="specificationName" className="form-control" 
                                                value={this.state.specificationName} onChange={this.changeSpecificationNameHandler}/>
                                         <p className="text-danger"> {this.state.specificationNameVal }</p>

                                        </div>
                                      

                                        <button className="btn btn-success" onClick={this.saveOrUpdateSpecification}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 










        );
      }
}

export default CreateSpecificationComponent
