import React, { Component } from 'react'

import { faEye, faEdit,  faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";
class FooterComponent extends Component {


    


  
   
    render() {
        const ballStyle = {
            borderLeft: '1px solid FloralWhite',
            height: '150px'
          }
        return (

            <div class="footer-dark">
            <footer>
                <div class="container">
                    <div class="row">
           
                         <div class="col-md-6 item text " >
                            <h3>BSECARS</h3>
                            <p class="card-text">For any queries related to Product, Price, Dealership, Warranty Please submit your
                    queries on the links given below. Our representative will contact you shortly..</p>
                  <p class="card-text"><b>Call us for Enquiry:-</b> 8007358007.</p>
                  <p class="card-text"><b>Sales Enquiry:- </b>sales@bsecars.com.</p>                        </div>
                        <div class="col-sm-6 col-md-3 item"style={ ballStyle}>
                            <h3>OVERVIEW</h3>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="about-us">About-Us</a></li>
                                <li><a href="contact-us">Contact-Us</a></li>


                            </ul>
                        </div>
                        <div class="col-sm-6 col-md-3 item "style={ ballStyle}>
                            <h3>Other</h3>
                            <ul>
                             
                                <li><a href="/terms-and-condition">Terms & Condition</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div class="col item social">
                            <a href="https://www.facebook.com/BSECARS01/" className="faFacebook social"> <FontAwesomeIcon icon={faFacebook}/></a>
                            <a href="https://www.youtube.com/channel/UCzk4L2kLFC65OGbmZ-yx62A" className="faFacebook social"> <FontAwesomeIcon icon={faYoutube}/></a>
                            <a href="#" className="faFacebook social"> <FontAwesomeIcon icon={faTwitter}/></a>
                            <a href="https://www.instagram.com/bsecars_01/" className="faFacebook social"> <FontAwesomeIcon icon={faInstagram}/></a>
                            
                            </div>
                    </div>
                    <p class="copyright">BSE CARS &#169; 2023</p>
                </div>
            </footer>
        </div>
        ) 
    }
}




export default FooterComponent
