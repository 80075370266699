import React, { Component } from 'react'
import EmployeeService from '../services/EmployeeService';

class CreateEmployeeComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            firstName: '',
            lastName: '',
            emailId: '',
            employeeId: '',
            address: '',
            alternateMobile: '',
            oraganization: '',
            designation: '',
            state: '',
            country: '',
            aadhar: '',
            pan: '',
            zipcode: ''
        }
        this.changeFirstNameHandler = this.changeFirstNameHandler.bind(this);
        this.changeLastNameHandler = this.changeLastNameHandler.bind(this);
        this.changeEmailHandler = this.changeEmailHandler.bind(this);
        this.changeEmployeeIdHandler = this.changeEmployeeIdHandler.bind(this);
        this.changeAddressHandler = this.changeAddressHandler.bind(this);
        this.changeAlternateMobileHandler = this.changeAlternateMobileHandler.bind(this);
        this.changeOraganizationHandler = this.changeOraganizationHandler.bind(this);
        this.changeDesignationHandler = this.changeDesignationHandler.bind(this);
        this.changeStateHandler = this.changeStateHandler.bind(this);
        this.changeCountryHandler = this.changeCountryHandler.bind(this);
        this.changeAadharHandler = this.changeAadharHandler.bind(this);
        this.changePanHandler = this.changePanHandler.bind(this);
        this.changeZipCodeHandler = this.changeZipCodeHandler.bind(this);
        this.saveOrUpdateEmployee = this.saveOrUpdateEmployee.bind(this);
        
    }

    // step 3
    componentDidMount(){

        // step 4
        if(this.state.id === '0'){
            return
        }else{
            EmployeeService.getEmployeeById(this.state.id).then( (res) =>{
                let employee = res.data;
                this.setState({
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    emailId : employee.emailId,
                    employeeId : employee.employeeId,
                    address : employee.address,
                    alternateMobile : employee.alternateMobile,
                    oraganization : employee.oraganization,
                    designation : employee.designation,
                    state : employee.state,
                    country : employee.country,
                    aadhar : employee.aadhar,
                    pan : employee.pan,
                    zipcode : employee.zipcode
                });
            });
        }        
    }
    saveOrUpdateEmployee = (e) => {
        e.preventDefault();
        let employee = {firstName: this.state.firstName, lastName: this.state.lastName, 
                        emailId: this.state.emailId, employeeId: this.state.employeeId,
                        address: this.state.address, alternateMobile: this.state.alternateMobile,
                        oraganization: this.state.oraganization, designation: this.state.designation,
                        state: this.state.state, country: this.state.country, aadhar: this.state.aadhar,
                        pan:this.state.pan, zipcode: this.state.zipcode};
        console.log('employee => ' + JSON.stringify(employee));

        // step 5
        if(this.state.id === '0'){
            EmployeeService.createEmployee(employee).then(res =>{
                this.props.history.push('/employees');
            });
        }else{
            EmployeeService.updateEmployee(employee, this.state.id).then( res => {
                this.props.history.push('/employees');
            });
        }
    }
    
    changeFirstNameHandler= (event) => {
        this.setState({firstName: event.target.value});
    }

    changeLastNameHandler= (event) => {
        this.setState({lastName: event.target.value});
    }

    changeEmailHandler= (event) => {
        this.setState({emailId: event.target.value});
    }

    changeEmployeeIdHandler= (event) => {
        this.setState({employeeId: event.target.value});
    }

    changeAddressHandler= (event) => {
        this.setState({address: event.target.value});
    }

    changeAlternateMobileHandler= (event) => {
        this.setState({alternateMobile: event.target.value});
    }

    changeOraganizationHandler= (event) => {
        this.setState({oraganization: event.target.value});
    }

    changeDesignationHandler= (event) => {
        this.setState({designation: event.target.value});
    }

    changeStateHandler= (event) => {
        this.setState({state: event.target.value});
    }

    changeCountryHandler= (event) => {
        this.setState({country: event.target.value});
    }

    changeAadharHandler= (event) => {
        this.setState({aadhar: event.target.value});
    }

    changePanHandler= (event) => {
        this.setState({pan: event.target.value});
    }

    changeZipCodeHandler= (event) => {
        this.setState({zipcode: event.target.value});
    }

    cancel(){
        this.props.history.push('/employees');
    }

    getTitle(){
        if(this.state.id === '0'){
            return <h3 className="text-center">Add Employee</h3>
        }else{
            return <h3 className="text-center">Update Employee</h3>
        }
    }
    render() {
        return (
            <div>
                <br></br>
                   <div className = "container">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                {
                                    this.getTitle()
                                }
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> First Name: </label>
                                            <input placeholder="First Name" name="firstName" className="form-control" 
                                                value={this.state.firstName} onChange={this.changeFirstNameHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Last Name: </label>
                                            <input placeholder="Last Name" name="lastName" className="form-control" 
                                                value={this.state.lastName} onChange={this.changeLastNameHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Email Id: </label>
                                            <input placeholder="Email Address" name="emailId" className="form-control" 
                                                value={this.state.emailId} onChange={this.changeEmailHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Employee ID: </label>
                                            <input placeholder="Employee ID" name="employeeId" className="form-control" 
                                                value={this.state.employeeId} onChange={this.changeEmployeeIdHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Address: </label>
                                            <input placeholder="Address" name="address" className="form-control" 
                                                value={this.state.address} onChange={this.changeAddressHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Alternate Mobile Number: </label>
                                            <input placeholder="Alternate Mobile Number" name="alternateMobile" className="form-control" 
                                                value={this.state.alternateMobile} onChange={this.changeAlternateMobileHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Oraganization: </label>
                                            <input placeholder="Oraganization" name="oraganization" className="form-control" 
                                                value={this.state.oraganization} onChange={this.changeOrganizationHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Designation: </label>
                                            <input placeholder="Designation" name="designation" className="form-control" 
                                                value={this.state.designation} onChange={this.changeDesignationHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> State: </label>
                                            <input placeholder="State" name="state" className="form-control" 
                                                value={this.state.state} onChange={this.changeStateHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Country: </label>
                                            <input placeholder="Country" name="country" className="form-control" 
                                                value={this.state.country} onChange={this.changeCountryHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Aadhar No: </label>
                                            <input placeholder="Aadhar Number" name="aadhar" className="form-control" 
                                                value={this.state.aadhar} onChange={this.changeAadharHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Pan No: </label>
                                            <input placeholder="Pan Number" name="pan" className="form-control" 
                                                value={this.state.pan} onChange={this.changePanHandler}/>
                                        </div>
                                        <div className = "form-group">
                                            <label> Zip Code: </label>
                                            <input placeholder="Zip Code" name="zipcode" className="form-control" 
                                                value={this.state.zipcode} onChange={this.changeZipCodeHandler}/>
                                        </div>

                                        <button className="btn btn-success" onClick={this.saveOrUpdateEmployee}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default CreateEmployeeComponent
