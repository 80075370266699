import React, { Component } from 'react'
import RentalInformationService from '../services/RentalInformationService'

class ViewRentalInformationComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            rentalInformation: {}
        }
    }

    componentDidMount(){
        RentalInformationService.getRentalInformationById(this.state.id).then( res => {
            this.setState({rentalInformation: res.data});
        })
    }

    render() {
        return (
            <div>
                <br></br>
                <div className = "card col-md-6 offset-md-3">
                    <h3 className = "text-center"> View Rental Information Details</h3>
                    <div className = "card-body">
                        <div className = "row">
                            <label> Issue Date: </label>
                            <div> { this.state.rentalInformation.issueDate }</div>
                        </div>
                        <div className = "row">
                            <label> End Date: </label>
                            <div> { this.state.rentalInformation.endDate }</div>
                        </div>
                        <div className = "row">
                            <label> Intial Payment: </label>
                            <div> { this.state.rentalInformation.initialPayment }</div>
                        </div>
                        <div className = "row">
                            <label> Transpotation Charges: </label>
                            <div> { this.state.rentalInformation.transpotationCharges }</div>
                        </div>
                        <div className = "row">
                            <label> Payment Method: </label>
                            <div> { this.state.rentalInformation.paymentMethod }</div>
                        </div>
                        <div className = "row">
                            <label> Payment Collected By: </label>
                            <div> { this.state.rentalInformation.paymentCollectedBy }</div>
                        </div>
                        <div className = "row">
                            <label> Installment Price: </label>
                            <div> { this.state.rentalInformation.installmentPrice }</div>
                        </div>
                        <div className = "row">
                            <label> Installment Date: </label>
                            <div> { this.state.rentalInformation.installmentDate }</div>
                        </div>
                        <div className = "row">
                            <label> Late Charges: </label>
                            <div> { this.state.rentalInformation.lateCharges }</div>
                        </div>
                        <div className = "row">
                            <label> GST Taxes: </label>
                            <div> { this.state.rentalInformation.gstTaxes }</div>
                        </div>
                        <div className = "row">
                            <label> Payment Due Date: </label>
                            <div> { this.state.rentalInformation.paymentDueDate }</div>
                        </div>
                        <div className = "row">
                            <label> Product Damage Charges: </label>
                            <div> { this.state.rentalInformation.productDamageCharges }</div>
                        </div>
                        

                    </div>

                </div>
            </div>
        )
    }
}

export default ViewRentalInformationComponent
