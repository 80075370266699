import axios from 'axios';

const Specifications_API_BASE_URL = "https://api.bsecars.com/api/v1/specificationValues";

class SpecificationValueService {



    getSpecificationValues(){
        return axios.get(Specifications_API_BASE_URL);
    }

    createSpecificationValue(specificationValue){
        return axios.post(Specifications_API_BASE_URL, specificationValue);
    }

    getSpecificationValueById(specificationValueId){
        return axios.get(Specifications_API_BASE_URL + '/' + specificationValueId);
    }

    updateSpecificationValue(specificationValue, specificationValueId){
        return axios.put(Specifications_API_BASE_URL + '/' + specificationValueId, specificationValue);
    }

    deleteSpecificationValue(specificationValueId){
        return axios.delete(Specifications_API_BASE_URL + '/' + specificationValueId);
    }
}

export default new SpecificationValueService()