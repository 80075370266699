import axios from 'axios';

const Equipment_API_BASE_URL = "https://api.bsecars.com/api/v1/equipments";

class EquipmentService {
    getAll(params) {
        return axios.get("https://api.bsecars.com/api/v1/all_equipments", { params });
    }

    getEquipments(){
        return axios.get(Equipment_API_BASE_URL);
    }

    createEquipment(equipment){
        return axios.post(Equipment_API_BASE_URL, equipment);
    }

    getEquipmentById(equipmentId){
        return axios.get(Equipment_API_BASE_URL + '/' + equipmentId);
    }

    updateEquipment(equipment, equipmentId){
        return axios.put(Equipment_API_BASE_URL + '/' + equipmentId, equipment);
    }

    deleteEquipment(equipmentId){
        return axios.delete(Equipment_API_BASE_URL + '/' + equipmentId);
    }
}

export default new EquipmentService()