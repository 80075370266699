import React, { Component } from 'react'
import StateService from '../services/StateService';
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';



class CreateStateComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
           stateName: '',  stateNameVal: ''
          
        }
        this.changeStateNameHandler = this.changeStateNameHandler.bind(this);

        this.saveOrUpdateState = this.saveOrUpdateState.bind(this);
        
    }

    // step 3
    componentDidMount(){

        // step 4
        if(this.state.id === '0'){
            return
        }else{
            StateService.getStateById(this.state.id).then( (res) =>{
                let state = res.data;
                this.setState({
                    stateName: state.stateName,
                });
            });
        }        
    }
    saveOrUpdateState = (e) => {
        e.preventDefault();
        if (this.state. stateName === '') {
            this.setState({stateNameVal: 'Invalid!, Please enter   state name'})
            return false;
        } 
        let state = {stateName: this.state.stateName};
        console.log('state => ' + JSON.stringify(state));

        // step 5
        if(this.state.id === '0'){
            StateService.createState(state).then(res =>{
                this.props.history.push('/states');
            });
        }else{
            StateService.updateState(state, this.state.id).then( res => {
                this.props.history.push('/states');
            });
        }
    }
    
    changeStateNameHandler= (event) => {
        this.setState({stateName: event.target.value});
    }

  

    cancel(){
        this.props.history.push('/states');
    }

    getTitle(){
        if(this.state.id === '0'){
            return <h6 className="m-0 font-weight-bold text-primary">Add State</h6>
        }else{
            return <h6 className="m-0 font-weight-bold text-primary">Update State</h6>
        }
    }
    render() {
        return (
           // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">state</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                <a class="btn btn-sm btn-primary float-right   mr-3" href="/States" role="button">List State</a>

                                 {  this.getTitle() }
                                </div>
                                <div class="card-body">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                              
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> State Name: </label>
                                            <input placeholder="State Name" name="stateName" className="form-control" 
                                                value={this.state.stateName} onChange={this.changeStateNameHandler}/>
                                                <p className="text-danger"> {this.state.stateNameVal }</p>
                                        </div>
                                      

                                        <button className="btn btn-success" onClick={this.saveOrUpdateState}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 

            
        )
    }
}

export default CreateStateComponent
