import React, { Component } from "react";
import VariantService from "../services/VariantService";
import ModelService from "../services/ModelService";
import UploadFilesService from "../services/UploadFilesService";
import CustomerService from "../services/CustomerService";
import Circle from "@uiw/react-color-circle";
import {} from "@fortawesome/free-solid-svg-icons";
import CurrencyFormat from "react-currency-format";
import FrontTopBarComponent from "./FrontTopBarComponent";
import FrontEndFooterComponent from "./FrontEndFooterComponent";

// const required = value => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

class DisplayVariantComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variantId: "",
      id: this.props.match.params.id,
      model: {},
      brand: {},
      variant: {},
      variants: [],
      specifications: [],
      features: [],
      hex: "",
      colors: [],
      map1: [],
      variantNameStr: "",
      customerName: "",
      customerNameVal: "",
      customerEmailId: "",
      customerMoblieNumber: "",
      customerMoblieNumberVal: "",
      customerEmail: "",
      customerEmailVal: "",
      onRoadPrice: 0.0,
      imageInfos: [],
    };

    this.saveOrUpdateCustomer = this.saveOrUpdateCustomer.bind(this);
    this.changeCustomerNameHandler = this.changeCustomerNameHandler.bind(this);
    this.changecustomerNumberHandler =
      this.changecustomerNumberHandler.bind(this);
    this.changeCustomerEmailHandler =
      this.changeCustomerEmailHandler.bind(this);
  }

  componentDidMount() {
    ModelService.getModelById(this.state.id).then((res) => {
      this.setState({ model: res.data });
      this.setState({ brand: res.data.brand });
      this.setState({ variants: res.data.variants });
      this.setState({ variant: res.data.variants[0] });
      this.setState({
        specifications: res.data.variants[0].specificationValues,
      });
      this.setState({ features: res.data.variants[0].featureValues });
      this.setState({
        onRoadPrice:
          this.state.variant.exShowroom +
          this.state.variant.regAmount +
          this.state.variant.insPayment +
          this.state.variant.autoCard +
          this.state.variant.fastTag -
          this.state.variant.discount,
      });
    });

    UploadFilesService.getFiles(this.state.id).then((response) => {
      // alert("in upload " + response);
      this.setState({
        imageInfos: response.data,
      });
    });
  }
  saveOrUpdateCustomer = (e) => {
    e.preventDefault();
    if (this.state.customerName === "") {
      this.setState({
        customerNameVal: "Invalid!, Please enter  customer name",
      });
      return false;
    }

    if (this.state.customerEmail === "") {
      this.setState({ customerEmailVal: "Invalid!, Please enter email id" });
      return false;
    }

    if (this.state.customerMoblieNumber === "") {
      this.setState({
        customerMoblieNumberVal: "Invalid!, Please enter mobile number",
      });
      return false;
    }
    let customer = {
      name: this.state.customerName,
      mobile: this.state.customerMoblieNumber,
      email: this.state.customerEmail,
      inquiry:
        this.state.brand.brandName +
        "-" +
        this.state.model.modelName +
        "-" +
        this.state.variant.variantName,
      brandId: this.state.brand.brandId,
    };
    console.log("customer => " + JSON.stringify(customer));

    CustomerService.createCustomer(customer).then((res) => {
      window.location.reload(false);
    });
  };

  changeCustomerNameHandler = (event) => {
    this.setState({ customerName: event.target.value });
  };

  changecustomerNumberHandler = (event) => {
    this.setState({ customerMoblieNumber: event.target.value });
  };

  changeCustomerEmailHandler = (event) => {
    this.setState({ customerEmail: event.target.value });
  };

  changeVariantIdHandler = (event) => {
    this.setState({ variantId: event.target.value });
    console.log("variantId", event.target.value);
    if (event.target.value !== "") {
      VariantService.getVariantById(event.target.value).then((res) => {
        console.log(res.data);
        this.setState({ model: res.data.model });
        this.setState({ brand: res.data.model.brand });
        this.setState({ variant: res.data });
        this.setState({ specifications: res.data.specificationValues });
        this.setState({ features: res.data.featureValues });
        this.setState({
          onRoadPrice:
            this.state.variant.exShowroom +
            this.state.variant.regAmount +
            this.state.variant.insPayment +
            this.state.variant.autoCard +
            this.state.variant.fastTag -
            this.state.variant.discount,
        });
        this.setState({
          colors: this.state.variant.colors
            ? this.state.variant.colors.map((color) => color.colorCode)
            : [],
        });

        console.log(this.state.colors);
      });
    }
  };

  render() {
    const { imageInfos, models, page, count, pageSize, volume } = this.state;

    return (
      <div>
        {/* Meta */}
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content />
        <meta name="author" content />
        <meta name="robots" content />
        <meta name="description" content="Mobhil Car Dealer HTML Template" />
        <meta property="og:title" content="Mobhil Car Dealer HTML Template" />
        <meta
          property="og:description"
          content="Mobhil Car Dealer HTML Template"
        />
        <meta
          property="og:image"
          content="https://mobhil.dexignlab.com/xhtml/social-image.png"
        />
        <meta name="format-detection" content="telephone=no" />
        {/* Mobile Specific */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Title */}

        {/* Favicon Icon */}
        <link rel="icon" type="image/png" src="images/logo-favicon.jpeg" />
        {/* Stylesheet */}
        <link
          href="vendor/lightgallery/css/lightgallery.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/magnific-popup/magnific-popup.min.css"
          rel="stylesheet"
        />
        <link href="vendor/swiper/swiper-bundle.min.css" rel="stylesheet" />
        <link href="vendor/splitting/dist/splitting.css" rel="stylesheet" />
        <link href="vendor/aos/aos.css" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        {/* Custom Stylesheet */}
        <link rel="stylesheet" href="css/style.css" />
        <link rel="stylesheet" type="text/css" href="vendor/rangeslider/rangeslider.css" />
        <link rel="stylesheet" className="skin" href="css/skin/skin-1.css" />
        <div id="loading-area" className="loading-page-1">
          <div className="spinner">
            <div className="ball" />
            <p>LOADING</p>
          </div>
        </div>
        <div className="page-wraper">
          {/* Header */}
          <FrontTopBarComponent />
          {/* Header End */}
          <div className="page-content bg-white">
            {/* Breadcrumb row */}
            <nav aria-label="breadcrumb" className="breadcrumb-row">
              <div className="container">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/car-listing">Car Collections</a>
                  </li>
                  <li className="breadcrumb-item">
                    {" "}
                    {this.state.brand.brandName} - {this.state.model.modelName}{" "}
                    {this.state.variant.variantName}
                  </li>
                </ul>
              </div>
            </nav>
            {/* Breadcrumb row END */}
            <section className>
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-6 m-b40">
                    <div
                      className="product-gallery on-show-slider lightgallery"
                      id="lightgallery"
                    >
                      <div className="swiper-container sync1">
                        <div className="swiper-wrapper">
                          {imageInfos &&
                            imageInfos.map((img, index) => (
                              <div className="swiper-slide">
                                <div
                                  className={
                                    index === 0
                                      ? "dlab-thum-bx"
                                      : "dlab-thum-bx"
                                  }
                                  key={index}
                                >
                                  <img
                                    src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}
                                  />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <span
                                        data-exthumbimage={""}
                                        data-src={""}
                                        className="view-btn lightimg"
                                      >
                                        <svg
                                          width={75}
                                          height={74}
                                          viewBox="0 0 75 74"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                            fill="white"
                                            fillOpacity="0.66"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* <div className="swiper-slide">
                             {imageInfos &&
                          imageInfos.map((img, index) => (
                                <div className="dlab-thum-bx">
                                <img
                                    src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}
                                  />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <span
                                        data-exthumbimage={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}
                                        data-src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                  
                                        className="view-btn lightimg"
                                      >
                                        <svg
                                          width={75}
                                          height={74}
                                          viewBox="0 0 75 74"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                            fill="white"
                                            fillOpacity="0.66"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                          ))}
                              </div> */}
                          {/* <div className="swiper-slide">
                                <div className="dlab-thum-bx">
                                <img
                                    src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}
                                  />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <span
                                        data-exthumbimage={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}
                                        data-src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                 
                                        className="view-btn lightimg"
                                      >
                                        <svg
                                          width={75}
                                          height={74}
                                          viewBox="0 0 75 74"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                            fill="white"
                                            fillOpacity="0.66"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                        </div>
                      </div>
                      <div className="swiper-container thumb-slider sync2">
                        <div className="swiper-wrapper">
                          {imageInfos &&
                            imageInfos.map((img, index) => (
                              <div className="swiper-slide">
                                <div className="dlab-media">
                                  <img
                                    src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}
                                  />
                                </div>
                              </div>
                            ))}
                          {/* <div className="swiper-slide">
                            <div className="dlab-media">
                            <img
                                    src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}/>
                            </div>
                          </div> */}
                          {/* <div className="swiper-slide">
                            <div className="dlab-media">
                               <img
                                    src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}/>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-6 m-b40 m-sm-b0">
                    <div className="sticky-top">
                      <div className="dlab-post-title">
                        <h3 className="post-title">
                          <a href="javascript:void(0);">
                            {this.state.brand?.brandName}{" "}
                            {this.state.model?.modelName} -{" "}
                            {this.state.variant?.variantName}
                          </a>
                        </h3>
                        <h6 className="sub-title">
                          {" "}
                          {this.state.brand?.brandName} -
                          {this.state.model?.modelName}
                        </h6>
                        <p className="m-b10">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam
                        </p>
                        <div className="dlab-divider bg-gray tb15">
                          <i className="icon-dot c-square" />
                        </div>
                      </div>
                      <div className="feature-list m-b40 m-lg-b30">
                        <div>
                          <label>Transmission</label>
                          <p className="value">
                            {this.state.variant.transmission}
                          </p>
                        </div>
                        <div>
                          <label>Fuel</label>
                          <p className="value">{this.state.variant.fuelType}</p>
                        </div>
                        <div>
                          <label>Engine CC</label>
                          <p className="value">
                            {" "}
                            {this.state.specifications.map(
                              (specificationValue) => (
                                <label>
                                  {specificationValue.specification
                                    .specificationName == "Engine"
                                    ? specificationValue.specificationValueName +
                                      " " +
                                      specificationValue.specificationUnit
                                    : ""}{" "}
                                </label>
                              )
                            )}
                          </p>
                        </div>
                      </div>

                      <div class="text-white" style={{ margin: 10 }}>
                        {this.state.variant.exShowroom > 0 && (
                          <span className="btn btn-primary price-btn m-b10 m-r10">
                            <CurrencyFormat
                              value={this.state.variant.exShowroom}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"\u20B9"}
                              renderText={(value) => <div>{value}</div>}
                            />
                          </span>
                        )}

                        <a
                          href="tel:+9180073 58007"
                          className="btn btn-primary light shadow-none m-b10"
                        >
                          <i className="fas fa-phone-volume me-3" />
                          +9180073 58007
                        </a>
                      </div>
                      <div className="social-list">
                        <span>Share</span>
                        <ul class="social-list style-1">
                          <li>
                            <a href="https://www.facebook.com/BSECARS01/">
                              <i class="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/bsecars_01/">
                              <i class="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.youtube.com/channel/UCzk4L2kLFC65OGbmZ-yx62A">
                              <i class="fab fa-whatsapp"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.youtube.com/channel/UCzk4L2kLFC65OGbmZ-yx62A">
                              <i class="fab fa-youtube"></i>
                            </a>
                          </li>
                          {/* <li>
                          <a href="https://in.linkedin.com/company/bse-cars">
                            <i class="fab fa-linkedin"></i>
                          </a>
                        </li> */}
                          <li>
                            <a href="https://twitter.com/">
                              <i class="fab fa-twitter"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="content-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 m-b40">
                    <div className="dlab-tabs  product-description">
                      <ul className="nav nav-tabs m-b60 m-lg-b30">
                        <li>
                          <a
                            data-bs-toggle="tab"
                            href="#description"
                            className="active"
                          >
                            Description
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="tab" href="#discussion">
                            Discussion
                          </a>
                        </li>
                        <li>
                          <a data-bs-toggle="tab" href="#reviews">
                            Reviews
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div id="description" className="tab-pane active">
                          <h5 className="m-b20">
                            The standard Lorem Ipsum passage, used since the
                            1500s
                          </h5>
                          <p className="m-b50">
                            "Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum."
                          </p>
                          <h5 className="m-b20">
                            Section 1.10.32 of "de Finibus Bonorum et Malorum",
                            written by Cicero in 45 BC
                          </h5>
                          <p>
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque laudantium, totam
                            rem aperiam, eaque ipsa quae ab illo inventore
                            veritatis et quasi architecto beatae vitae dicta
                            sunt explicabo. Nemo enim ipsam voluptatem quia
                            voluptas sit aspernatur aut odit aut fugit, sed quia
                            consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt. Neque porro quisquam est,
                            qui dolorem ipsum quia dolor sit amet, consectetur,
                            adipisci velit, sed quia non numquam eius modi
                            tempora incidunt ut labore et dolore magnam aliquam
                            quaerat voluptatem. Ut enim ad minima veniam, quis
                            nostrum exercitationem ullam corporis suscipit
                            laboriosam, nisi ut aliquid ex ea commodi
                            consequatur? Quis autem vel eum iure reprehenderit
                            qui in ea voluptate velit esse quam nihil molestiae
                            consequatur, vel illum qui dolorem eum fugiat quo
                            voluptas nulla pariatur?
                          </p>
                        </div>
                        <div id="discussion" className="tab-pane">
                          <div className="comments-area style-1" id="comments">
                            <div className="widget-title">
                              <h5 className="title">8 Comments</h5>
                              <div className="dlab-separator style-1 text-primary mb-0" />
                            </div>
                            <div className="clearfix">
                              {/* comment list END */}
                              <ol className="comment-list">
                                <li className="comment">
                                  <div className="comment-body">
                                    <div className="comment-author vcard">
                                      <img
                                        className="avatar photo"
                                        src="images/testimonials/pic1.jpg"
                                        alt=""
                                      />
                                      <cite className="fn">
                                        Celesto Anderson
                                      </cite>
                                    </div>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipisicing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua.
                                    </p>
                                    <div className="reply">
                                      <a
                                        href="javascript:void(0);"
                                        className="comment-reply-link"
                                      >
                                        Reply
                                        <i className="fa fa-reply" />
                                      </a>
                                    </div>
                                  </div>
                                  <ol className="children">
                                    <li className="comment odd parent">
                                      <div className="comment-body">
                                        <div className="comment-author vcard">
                                          <img
                                            className="avatar photo"
                                            src="images/testimonials/pic2.jpg"
                                            alt=""
                                          />
                                          <cite className="fn">
                                            Jake Johnson
                                          </cite>
                                        </div>
                                        <p>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipisicing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua.
                                        </p>
                                        <div className="reply">
                                          <a
                                            href="javascript:void(0);"
                                            className="comment-reply-link"
                                          >
                                            <i className="fa fa-reply" />
                                            Reply
                                          </a>
                                        </div>
                                      </div>
                                      {/* list END */}
                                    </li>
                                  </ol>
                                  {/* list END */}
                                </li>
                                <li className="comment">
                                  <div className="comment-body">
                                    <div className="comment-author vcard">
                                      <img
                                        className="avatar photo"
                                        src="images/testimonials/pic1.jpg"
                                        alt=""
                                      />
                                      <cite className="fn">John Doe</cite>
                                    </div>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipisicing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua.
                                    </p>
                                    <div className="reply">
                                      <a
                                        href="javascript:void(0);"
                                        className="comment-reply-link"
                                      >
                                        <i className="fa fa-reply" />
                                        Reply
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ol>
                              {/* comment list END */}
                              {/* Form */}
                              <div
                                className="comment-respond style-1"
                                id="respond"
                              >
                                <div className="widget-title">
                                  <h5 className="title">Leave Your Comment</h5>
                                  <div className="dlab-separator style-1 text-primary mb-0" />
                                  <a
                                    style={{ display: "none" }}
                                    href="javascript:void(0);"
                                    id="cancel-comment-reply-link"
                                    rel="nofollow"
                                  >
                                    Cancel reply
                                  </a>
                                  <div className="dlab-separator bg-primary" />
                                </div>
                                <form
                                  className="comment-form"
                                  id="commentform"
                                  method="post"
                                >
                                  <p className="comment-form-author">
                                    <label htmlFor="author">
                                      Name <span className="required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="Author"
                                      placeholder="Author"
                                      id="author"
                                    />
                                  </p>
                                  <p className="comment-form-email">
                                    <label htmlFor="email">
                                      Email <span className="required">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Email"
                                      name="email"
                                      id="email"
                                    />
                                  </p>
                                  <p className="comment-form-comment">
                                    <label htmlFor="comment">Comment</label>
                                    <textarea
                                      rows={8}
                                      name="comment"
                                      placeholder="Comment"
                                      id="comment"
                                      defaultValue={""}
                                    />
                                  </p>
                                  <p className="form-submit">
                                    <button
                                      type="submit"
                                      className="btn shadow-primary btn-primary btn-icon"
                                      id="submit"
                                    >
                                      POST NOW
                                      <i className="fas fa-caret-right ms-1" />
                                    </button>
                                  </p>
                                </form>
                              </div>
                              {/* Form */}
                            </div>
                          </div>
                        </div>
                        <div id="reviews" className="tab-pane">
                          <ol className="commentlist">
                            <li className="comment">
                              <div className="comment_container">
                                <img
                                  className="avatar avatar-60 photo"
                                  src="images/testimonials/pic1.jpg"
                                  alt=""
                                />
                                <div className="comment-text">
                                  <div className="star-rating">
                                    <div data-rating={2}>
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={1}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={2}
                                        title="regular"
                                      />
                                      <i
                                        className="far fa-star text-yellow"
                                        data-alt={3}
                                        title="regular"
                                      />
                                      <i
                                        className="far fa-star text-yellow"
                                        data-alt={4}
                                        title="regular"
                                      />
                                      <i
                                        className="far fa-star text-yellow"
                                        data-alt={5}
                                        title="regular"
                                      />
                                    </div>
                                  </div>
                                  <p className="meta">
                                    <strong className="author">
                                      Cobus Bester
                                    </strong>
                                    <span>
                                      <i className="fa fa-clock-o" /> March 7,
                                      2013
                                    </span>
                                  </p>
                                  <div className="description">
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem
                                      Ipsum has been the industry's standard
                                      dummy text ever since the 1500s, when an
                                      unknown printer took a galley of type and
                                      scrambled it to make a type specimen book.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="comment">
                              <div className="comment_container">
                                <img
                                  className="avatar avatar-60 photo"
                                  src="images/testimonials/pic2.jpg"
                                  alt=""
                                />
                                <div className="comment-text">
                                  <div className="star-rating">
                                    <div data-rating={4}>
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={1}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={2}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={3}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={4}
                                        title="regular"
                                      />
                                      <i
                                        className="far fa-star text-yellow"
                                        data-alt={5}
                                        title="regular"
                                      />
                                    </div>
                                  </div>
                                  <p className="meta">
                                    <strong className="author">
                                      Emilia Johnson
                                    </strong>
                                    <span>
                                      <i className="fa fa-clock-o" /> March 7,
                                      2013
                                    </span>
                                  </p>
                                  <div className="description">
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem
                                      Ipsum has been the industry's standard
                                      dummy text ever since the 1500s, when an
                                      unknown printer took a galley of type and
                                      scrambled it to make a type specimen book.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="comment">
                              <div className="comment_container">
                                <img
                                  className="avatar avatar-60 photo"
                                  src="images/testimonials/pic3.jpg"
                                  alt=""
                                />
                                <div className="comment-text">
                                  <div className="star-rating">
                                    <div data-rating={3}>
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={1}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={2}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={3}
                                        title="regular"
                                      />
                                      <i
                                        className="far fa-star text-yellow"
                                        data-alt={4}
                                        title="regular"
                                      />
                                      <i
                                        className="far fa-star text-yellow"
                                        data-alt={5}
                                        title="regular"
                                      />
                                    </div>
                                  </div>
                                  <p className="meta">
                                    <strong className="author">
                                      Mark Steven
                                    </strong>
                                    <span>
                                      <i className="fa fa-clock-o" /> March 7,
                                      2013
                                    </span>
                                  </p>
                                  <div className="description">
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem
                                      Ipsum has been the industry's standard
                                      dummy text ever since the 1500s, when an
                                      unknown printer took a galley of type and
                                      scrambled it to make a type specimen book.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="comment">
                              <div className="comment_container">
                                <img
                                  className="avatar avatar-60 photo"
                                  src="images/testimonials/pic1.jpg"
                                  alt=""
                                />
                                <div className="comment-text">
                                  <div className="star-rating">
                                    <div data-rating={5}>
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={1}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={2}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={3}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={4}
                                        title="regular"
                                      />
                                      <i
                                        className="fa fa-star text-yellow"
                                        data-alt={5}
                                        title="regular"
                                      />
                                    </div>
                                  </div>
                                  <p className="meta">
                                    <strong className="author">
                                      Cobus Bester
                                    </strong>
                                    <span>
                                      <i className="fa fa-clock-o" /> March 7,
                                      2013
                                    </span>
                                  </p>
                                  <div className="description">
                                    <p>
                                      Lorem Ipsum is simply dummy text of the
                                      printing and typesetting industry. Lorem
                                      Ipsum has been the industry's standard
                                      dummy text ever since the 1500s, when an
                                      unknown printer took a galley of type and
                                      scrambled it to make a type specimen book.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 m-b40 m-lg-b0">
                    <div className="product-rating-box sticky-top">
                      <div className="review-text">
                        <span>4.5</span> / 5.0
                      </div>
                      <div className="rating-footer">
                        <h4 className="text-white">Ratings</h4>
                        <ul className="item-review">
                          <li>
                            <i className="fa fa-star" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                          <li>
                            <i className="fa fa-star" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr />
            <section className="content-inner-2">
              <div className="container-fluid">
                <div className="section-head text-center">
                  <h2 className="title">Top deals of the week</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.{" "}
                  </p>
                </div>
                <div className="swiper-container deal-swiper swiper-dots-1">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      {models &&
                        models.map((model, index1) => (
                          <div className="car-list-box overlay">
                            <div className="media-box">
                              <img src="https://mobhil.dexignlab.com/xhtml/images/deal/pic1.jpg" />
                            </div>
                            <div className="list-info">
                              <h3 className="title">
                                <a
                                  href="car-details.html"
                                  data-splitting
                                  className="text-white"
                                >
                                  {model.brand.brandName} - {model.modelName}
                                </a>
                              </h3>
                              <div className="car-type">SPORT CAR</div>
                              <span className="badge m-b30">$34,500</span>
                              <div className="feature-list">
                                <div>
                                  <label>Transmotion</label>
                                  <p className="value">Automatic</p>
                                </div>
                                <div>
                                  <label>Fuel</label>
                                  <p className="value">Electric</p>
                                </div>
                                <div>
                                  <label>Passenger</label>
                                  <p className="value">2 Person</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="swiper-slide">
                      <div className="car-list-box overlay">
                        <div className="media-box">
                          <img
                            src="	https://mobhil.dexignlab.com/xhtml/images/deal/pic2.jpg"
                            alt=""
                          />
                        </div>
                        <div className="list-info">
                          <h3 className="title">
                            <a
                              href="car-details.html"
                              data-splitting
                              className="text-white"
                            >
                              GT-Z 122 Boost
                            </a>
                          </h3>
                          <div className="car-type">SPORT CAR</div>
                          <span className="badge m-b30">$34,500</span>
                          <div className="feature-list">
                            <div>
                              <label>Transmotion</label>
                              <p className="value">Automatic</p>
                            </div>
                            <div>
                              <label>Fuel</label>
                              <p className="value">Electric</p>
                            </div>
                            <div>
                              <label>Passenger</label>
                              <p className="value">2 Person</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="car-list-box overlay">
                        <div className="media-box">
                          <img
                            src="https://mobhil.dexignlab.com/xhtml/images/deal/pic3.jpg"
                            alt=""
                          />
                        </div>
                        <div className="list-info">
                          <h3 className="title">
                            <a
                              href="car-details.html"
                              data-splitting
                              className="text-white"
                            >
                              SPORT X-GTZ
                            </a>
                          </h3>
                          <div className="car-type">SPORT CAR</div>
                          <span className="badge m-b30">$34,500</span>
                          <div className="feature-list">
                            <div>
                              <label>Transmotion</label>
                              <p className="value">Automatic</p>
                            </div>
                            <div>
                              <label>Fuel</label>
                              <p className="value">Electric</p>
                            </div>
                            <div>
                              <label>Passenger</label>
                              <p className="value">2 Person</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="car-list-box overlay">
                        <div className="media-box">
                          <img
                            src="	https://mobhil.dexignlab.com/xhtml/images/deal/pic4.jpg"
                            alt=""
                          />
                        </div>
                        <div className="list-info">
                          <h3 className="title">
                            <a
                              href="car-details.html"
                              data-splitting
                              className="text-white"
                            >
                              Smart Car GT AA-211
                            </a>
                          </h3>
                          <div className="car-type">SPORT CAR</div>
                          <span className="badge m-b30">$34,500</span>
                          <div className="feature-list">
                            <div>
                              <label>Transmotion</label>
                              <p className="value">Automatic</p>
                            </div>
                            <div>
                              <label>Fuel</label>
                              <p className="value">Electric</p>
                            </div>
                            <div>
                              <label>Passenger</label>
                              <p className="value">2 Person</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="car-list-box overlay">
                        <div className="media-box">
                          <img
                            src="	https://mobhil.dexignlab.com/xhtml/images/deal/pic3.jpg"
                            alt=""
                          />
                        </div>
                        <div className="list-info">
                          <h3 className="title">
                            <a
                              href="car-details.html"
                              data-splitting
                              className="text-white"
                            >
                              SPORT X-GTZ
                            </a>
                          </h3>
                          <div className="car-type">SPORT CAR</div>
                          <span className="badge m-b30">$34,500</span>
                          <div className="feature-list">
                            <div>
                              <label>Transmotion</label>
                              <p className="value">Automatic</p>
                            </div>
                            <div>
                              <label>Fuel</label>
                              <p className="value">Electric</p>
                            </div>
                            <div>
                              <label>Passenger</label>
                              <p className="value">2 Person</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="slider-one-pagination m-t40">
                    {/* Add Navigation */}
                    <div className="swiper-pagination" />
                  </div>
                </div>
              </div>
            </section>
            <section className="content-inner">
              <div className="container">
                <div className="row call-to-action-bx">
                  <div className="col-xl-5 col-lg-6 me-auto">
                    <div className="section-head">
                      <h2 className="title text-white">
                        Have any question about us?
                      </h2>
                    </div>
                    <a
                      href="tel:+9180073 58007"
                      className="btn btn-white me-3 mb-2"
                    >
                      <i className="fas fa-phone-volume me-sm-3 me-0 shake" />
                      <span className="d-sm-inline-block d-none">
                        +9180073 58007
                      </span>
                    </a>
                    <a
                      href="contact-us.html"
                      className="btn btn-outline-white effect-1  mb-2"
                    >
                      <span>Contact Us</span>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="media-box">
                      <img
                        src="https://mobhil.dexignlab.com/xhtml/images/about/pic5.jpg"
                        className="main-img"
                        alt=""
                      />
                      <img
                        src="https://mobhil.dexignlab.com/xhtml/images/pattern/pattern7.png"
                        className="pt-img move-1"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* Footer */}
          <FrontEndFooterComponent />
          {/* Footer End */}
          <button className="scroltop icon-up" type="button">
            <i className="fas fa-arrow-up" />
          </button>
        </div>
        {/* JAVASCRIPT FILES ========================================= */}
        {/* JQUERY.MIN JS */}
        {/* BOOTSTRAP.MIN JS */}
        {/* RANGESLIDER */}
        {/* MAGNIFIC-POPUP JS */}
        {/* WAYPOINTS JS */}
        {/* COUNTERUP JS */}
        {/* LIGHTGALLERY */}
        {/* LIGHTGALLERY */}
        {/* AOS */}
        {/* SWIPER */}
        {/* AOS */}
        {/* OWL CAROUSEL */}
        {/* AJAX */}
        {/* CUSTOM JS */}
      </div>
    );
  }
}

export default DisplayVariantComponent;
