import axios from 'axios';

const DASHBOARD_DEALER_API_BASE_URL = "https://api.bsecars.com/api/v1/dashboardDealer";

class DashboardDealerService {

    getDashboardData(){
        return axios.get(DASHBOARD_DEALER_API_BASE_URL);
    }

    getModelDashboardData(modelID){
        return axios.get(DASHBOARD_DEALER_API_BASE_URL + '/' + modelID);
    }

    createCustomer(Customer){
        return axios.post(DASHBOARD_DEALER_API_BASE_URL, Customer);
    }

    getBrandById(customerId){
        return axios.get(DASHBOARD_DEALER_API_BASE_URL + '/' + customerId);
    }

    

}

export default new DashboardDealerService()