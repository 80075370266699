import axios from 'axios';

const HISTORY_API_BASE_URL = "https://api.bsecars.com/api/v1/history";

class HistoryService {
    
    getHistory(){
        return axios.get(HISTORY_API_BASE_URL);
    }

    createHistory(History){
        return axios.post(HISTORY_API_BASE_URL, History);
    }

    getHistoryById(HistoryId){
        return axios.get(HISTORY_API_BASE_URL + '/' + HistoryId);
    }

    updateHistory(History, HistoryId){
        return axios.put(HISTORY_API_BASE_URL + '/' + HistoryId, History);
    }

    deleteHistory(HistoryId){
        return axios.delete(HISTORY_API_BASE_URL + '/' + HistoryId);
    }
}

export default new HistoryService()