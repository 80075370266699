import React, { Component } from 'react'


class HeaderComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    return (
      <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="/"><b>BSECARS</b></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">




              <li className="nav-item">
                <a className="nav-link" href="/about-us">About Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact-us">Contact Us</a>
              </li>


              {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle " href="#!" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Dropdown link
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a className="dropdown-item" href="#!">Action</a>
                    <a className="dropdown-item" href="#!">Another action</a>
                    <a className="dropdown-item" href="#!">Something else here</a>
                  </div>
                </li> */}

              {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-user"></i> Profile </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-cyan" aria-labelledby="navbarDropdownMenuLink-4">
                    <a className="dropdown-item" href="#!">My account</a>
                    <a className="dropdown-item" href="#!">Log out</a>
                  </div>
                </li> */}
            </ul>


            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="/login">Login</a>

                {/* <Link to="/login" className="nav-link"> Login </Link>  */}

              </li>

            </ul>
          </div>
        </nav>

      </header>

     
      <br></br>


      
    </div>
    )
  }
}

export default HeaderComponent
