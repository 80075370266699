import React, { Component } from 'react'
import HistoryService from '../services/HistoryService'
class ListHistoryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                history: []
        }
        /* this.addEmployee = this.addEmployee.bind(this);
        this.editEmployee = this.editEmployee.bind(this); */
        this.deleteHistory = this.deleteHistory.bind(this);
    }

    deleteHistory(id){
        HistoryService.deleteEmployee(id).then( res => {
            this.setState({history: this.state.history.filter(history => history.id !== id)});
        });
    }
    viewHistory(id){
        this.props.history.push(`/view-history/${id}`);
    }
    /* editHistory(id){
        this.props.history.push(`/add-history/${id}`);
    } */

    componentDidMount(){
        HistoryService.getHistory().then((res) => {
            this.setState({ history: res.data});
        });
    }

    /* addEmployee(){
        this.props.history.push('/add-employee/0');
    } */


    viewCustomer(){
        this.props.history.push('/customers');
    }



    render() {
        return (
            <div>
                 <h2 className="text-center">Records</h2>
                 <div className = "row">

                   {/*  <button className="btn btn-primary" onClick={this.addEmployee}> Add Employee</button>
                    <button className="btn btn-primary" onClick={this.addEmployee}> View Customer</button> */}
                 </div>
                 <br></br>
                 <div className = "row">
                        <table className = "table table-striped table-bordered">

                            <thead>
                                <tr>
                                    <th> Name</th>
                                    <th> Product Name</th>
                                    <th> Mobile</th>
                                    <th> Email</th>
                                    <th> Aadhar No</th>
                                    <th> PAN No</th>
                                    <th> Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.history.map(
                                        history => 
                                        <tr key = {history.id}>
                                             <td> {history.custName} </td>   
                                             <td> {history.name}</td>
                                             <td> {history.mobile}</td>
                                             <td> {history.email}</td>
                                             <td> {history.aadhar}</td>
                                             <td> {history.pan}</td>
                                             <td>
                                                 {/* <button onClick={ () => this.editEmployee(employee.id)} className="btn btn-info">Update </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deleteEmployee(employee.id)} className="btn btn-danger">Delete </button> */}
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.viewHistory(history.id)} className="btn btn-info">More </button>
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            </div>
        )
    }
}

export default ListHistoryComponent
