import React, { Component } from 'react'
import SpecificationService from '../services/SpecificationService'
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';

class ViewSpecificationComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            specification: {}
        }

        this.viewSpecifications = this.viewSpecifications.bind(this);
    }

    componentDidMount(){
        SpecificationService.getSpecificationById(this.state.id).then( res => {
            this.setState({specification: res.data});
        })
    }

    viewSpecifications(){
        this.props.history.push('/specifications');
    }


    render() {
        return (
           // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Conten+
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        t --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Specification</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">View Specification
                                    <button className="btn btn-primary" style={{marginLeft: "950px"}} onClick={this.viewSpecifications}> List Specification</button>
                                    </h6>
                                </div>
                                <div class="card-body">
                                {/* <h2 className="text-center">Specifications List</h2> */}
                 <div className = "row">
                   
                 </div>
                 <br></br>
                <div className = "card col-md-6 offset-md-3">
                    <h3 className = "text-center"> View Specification Details</h3>
                    <div className = "card-body">
                        <div className = "row">
                        <h6 class="">
                            <label> Specification Id: </label>&nbsp;
                             { this.state.specification.specificationId }</h6>
                        </div>
                        <div className = "row"><h6 class="">
                            <label> Specification Name: </label>&nbsp;
                            { this.state.specification?.specificationName }</h6>
                        </div>
                        <div className = "row"><h6 class="">
                            <label>Created Date: </label>&nbsp;
                           { this.state.specification?.dateCreated }</h6>
                        </div>
                        <div className = "row"><h6 class="">
                            <label> Updated Date: </label>&nbsp;
                            { this.state.specification?.dateUpdated }</h6>
                        </div>
                        

                       
                    </div>

                </div>
                <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 
        )
    }
}

export default ViewSpecificationComponent
