import React, { Component } from "react";
import SpecificationService from "../services/SpecificationService";

import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";
import DataTable from "react-data-table-component";
// import { confirm } from "react-confirm-box";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ListSpecificationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      
      currentUser: null,
      tableData: {},
      specifications: [],
      selctedSpecificationsRows: [],
     
      columns: [
        {
          name: "Id",
          selector: (row) => row.specificationId,
          cellExport: (row) => row.specificationId,
          sortable: true,
        },
        {
          name: "Specifications  Name",
          selector: (row) => row.specificationName,
          cellExport: (row) => row.specificationName,
          sortable: true,
        },
        {
          name: " Created Date",
          selector: (row) => row.dateCreated,
          cellExport: (row) => row.dateCreated,
          sortable: true,
        },
        {
          name: " Updated Date",
          selector: (row) => row.dateUpdated,
          cellExport: (row) => row.dateUpdated,
          sortable: true,
        },

        {
          cell: (row) => (
            <a href={`/view-specification/${row["specificationId"]}`}>
              <FontAwesomeIcon icon={faEye} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href={`/add-specification/${row["specificationId"]}`}>
              <FontAwesomeIcon icon={faEdit} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          cell: (row) => (
            <a href="#!" onClick={() => this.clickHandler(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </a>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
    };
    this.addSpecification = this.addSpecification.bind(this);
    this.editSpecification = this.editSpecification.bind(this);
    this.deleteSpecification = this.deleteSpecification.bind(this);
  }

  deleteSpecification(id) {
    SpecificationService.deleteSpecification(id).then((res) => {
      this.setState({
        specifications: this.state.specifications.filter(
          (specification) => specification.specificationId !== id
        ),
      });
      this.componentDidMount();
    });
  }
  clickHandler = (row) => {
    this.deleteSpecification(row.specificationId);
  }

  componentDidMount() {
    SpecificationService.getSpecifications().then((res) => {
      this.setState({ specifications: res.data });
      this.setState({
        tableData: { columns: this.state.columns, data: res.data },
      });
    });
  }

  viewSpecification(id) {
    this.props.history.push(`/view-specification/${id}`);
  }
  editSpecification(id) {
    this.props.history.push(`/add-specification/${id}`);
  }
  addSpecification() {
    this.props.history.push("/add-specification/0");
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Specification</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        List Specification     </h6>
                        <button
                          className="btn btn-sm btn-primary"
                      
                          onClick={this.addSpecification}
                        >
                          {" "}
                          Add Specification
                        </button>
                 
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Specifications List</h2> */}
                      <div className="row">

                         <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.specifications}
                            defaultSortField="Id"
                            defaultSortDesc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>



                      </div>
                      <br></br>
                      <div className="row">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ListSpecificationComponent;
