import React, { Component } from "react";
import VariantService from "../services/VariantService";
import ModelService from "../services/ModelService";
import UploadFilesService from "../services/UploadFilesService";
import CustomerService from "../services/CustomerService";
import Circle from "@uiw/react-color-circle";
import {} from "@fortawesome/free-solid-svg-icons";
import CurrencyFormat from "react-currency-format";
import FrontTopBarComponent from "./FrontTopBarComponent";
import FrontEndFooterComponent from "./FrontEndFooterComponent";
import SpecificationComponent from "./SpecificationComponent";
import CityService from "../services/CityService";
import BrandService from "../services/BrandService";

// const required = value => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

class DisplayVariantComponentNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variantId: "",
      id: this.props.match.params.id,
      model: {},
      brand: {},
      variant: {},
      variants: [],
      models: [],
      specifications: [],
      features: [],
      hex: "",
      colors: [],
      map1: [],
      variantNameStr: "",
      customerName: "",
      customerNameVal: "",
      customerEmailId: "",
      customerMoblieNumber: "",
      customerMoblieNumberVal: "",
      customerEmail: "",
      customerEmailVal: "",
      onRoadPrice: 0.0,
      imageInfos: [],
    };

    this.saveOrUpdateCustomer = this.saveOrUpdateCustomer.bind(this);
    this.changeCustomerNameHandler = this.changeCustomerNameHandler.bind(this);
    this.changecustomerNumberHandler =
      this.changecustomerNumberHandler.bind(this);
    this.changeCustomerEmailHandler =
      this.changeCustomerEmailHandler.bind(this);
  }
  specificationModel(id) {
    this.props.history.push(`/specifications/${id}`);
  }

  componentDidMount() {
    ModelService.getModelById(this.state.id).then((res) => {
      this.setState({ model: res.data });
      this.setState({ brand: res.data.brand });
      this.setState({ variants: res.data.variants });
      this.setState({ variant: res.data.variants[0] });
      this.setState({
        specifications: res.data.variants[0].specificationValues,
      });
      this.setState({ features: res.data.variants[0].featureValues });
      this.setState({
        onRoadPrice:
          this.state.variant.exShowroom +
          this.state.variant.regAmount +
          this.state.variant.insPayment +
          this.state.variant.autoCard +
          this.state.variant.fastTag -
          this.state.variant.discount,
      });
    });

    UploadFilesService.getFiles(this.state.id).then((response) => {
      // alert("in upload " + response);
      this.setState({
        imageInfos: response.data,
      });
    });

    ModelService.getModels().then((res) => {
      this.setState({ models: res.data });
    });
  }
  saveOrUpdateCustomer = (e) => {
    e.preventDefault();
    if (this.state.customerName === "") {
      this.setState({
        customerNameVal: "Invalid!, Please enter  customer name",
      });
      return false;
    }

    if (this.state.customerEmail === "") {
      this.setState({ customerEmailVal: "Invalid!, Please enter email id" });
      return false;
    }

    if (this.state.customerMoblieNumber === "") {
      this.setState({
        customerMoblieNumberVal: "Invalid!, Please enter mobile number",
      });
      return false;
    }
    let customer = {
      name: this.state.customerName,
      mobile: this.state.customerMoblieNumber,
      email: this.state.customerEmail,
      inquiry:
        this.state.brand.brandName +
        "-" +
        this.state.model.modelName +
        "-" +
        this.state.variant.variantName,
      brandId: this.state.brand.brandId,
    };
    console.log("customer => " + JSON.stringify(customer));

    CustomerService.createCustomer(customer).then((res) => {
      window.location.reload(false);
    });
  };

  changeCustomerNameHandler = (event) => {
    this.setState({ customerName: event.target.value });
  };

  changecustomerNumberHandler = (event) => {
    this.setState({ customerMoblieNumber: event.target.value });
  };

  changeCustomerEmailHandler = (event) => {
    this.setState({ customerEmail: event.target.value });
  };

  changeVariantIdHandler = (event) => {
    this.setState({ variantId: event.target.value });
    console.log("variantId", event.target.value);
    if (event.target.value !== "") {
      VariantService.getVariantById(event.target.value).then((res) => {
        console.log(res.data);
        this.setState({ model: res.data.model });
        this.setState({ brand: res.data.model.brand });
        this.setState({ variant: res.data });
        this.setState({ specifications: res.data.specificationValues });
        this.setState({ features: res.data.featureValues });
        this.setState({
          onRoadPrice:
            this.state.variant.exShowroom +
            this.state.variant.regAmount +
            this.state.variant.insPayment +
            this.state.variant.autoCard +
            this.state.variant.fastTag -
            this.state.variant.discount,
        });
        this.setState({
          colors: this.state.variant.colors
            ? this.state.variant.colors.map((color) => color.colorCode)
            : [],
        });

        console.log(this.state.colors);
      });
    }
  };

  render() {
    const { imageInfos, models, page, count, pageSize, volume } = this.state;
    <SpecificationComponent example="foo" />;

    return (
      <div>
        {/* <div id="loading-area" className="loading-page-1">
          <div className="spinner">
            <div className="ball" />
            <p>LOADING</p>
          </div>
        </div> */}

        <div className="page-wraper">
          {/* Header */}
          <FrontTopBarComponent />
          {/* Header End */}
          <div className="page-content bg-white">
            {/* Banner  */}

            <div
              className="dlab-bnr-inr style-1 overlay-black-middle"
              style={{
                backgroundImage:
                  'url("https://mobhil.dexignlab.com/xhtml/images/banner/bnr2.jpg")',
              }}
            >
              <div className="container">
                <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">Car Details</h1>
                  <div className="dlab-separator" />
                </div>
              </div>
            </div>
            {/* Banner End */}
            {/* <div className="car-details-info">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="nav">
                      <ul>
                        <li className="active">
                          <a href="/display-variant-New/${id}">Overview</a>
                        </li>
                        <li>
                          <a href="/specifications/65">Specifications</a>
                        </li>
                        <li>
                          <a href="/price/65">Price</a>
                        </li>
                        <li>
                          <a href="/compare">Compare</a>
                        </li>
                        <li>
                          <a href="/pictures/65">Pictures</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div> */}

            <section className="content-inner-2">
              <div className="container">
                <div className="row">
                  <div className=" col-xl-8 col-lg-7 col-md-6 m-b0 m-md-b50">
                    <div
                      className="product-gallery on-show-slider lightgallery m-b40"
                      id="lightgallery"
                    >
                      <div className="swiper-container sync1">
                        <div className="swiper-wrapper">
                          {imageInfos &&
                            imageInfos.map((img, index) => (
                              <div className="swiper-slide">
                                <div
                                  className={
                                    index === 0
                                      ? "dlab-thum-bx"
                                      : "dlab-thum-bx"
                                  }
                                  key={index}
                                >
                                  <img
                                    src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}
                                  />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <span
                                        data-exthumbimage={
                                          `https://api.bsecars.com/api/v1/files/` +
                                          img.modelImageName
                                        }
                                        data-src={
                                          `https://api.bsecars.com/api/v1/files/` +
                                          img.modelImageName
                                        }
                                        alt={img.modelImageName}
                                        className="view-btn lightimg"
                                      >
                                        <svg
                                          width={75}
                                          height={74}
                                          viewBox="0 0 75 74"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                            fill="white"
                                            fillOpacity="0.66"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* <div className="swiper-slide">
                            <div className="dlab-thum-bx">
                              <img
                                src="images/product/item2/item2.jpg"
                                alt=""
                              />
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <span
                                    data-exthumbimage="images/product/item2/item2.jpg"
                                    data-src="images/product/item2/item2.jpg"
                                    className="view-btn lightimg"
                                  >
                                    <svg
                                      width={75}
                                      height={74}
                                      viewBox="0 0 75 74"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                        fill="white"
                                        fillOpacity="0.66"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="dlab-thum-bx">
                              <img
                                src="images/product/item2/item3.jpg"
                                alt=""
                              />
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <span
                                    data-exthumbimage="images/product/item2/item3.jpg"
                                    data-src="images/product/item2/item3.jpg"
                                    className="view-btn lightimg"
                                  >
                                    <svg
                                      width={75}
                                      height={74}
                                      viewBox="0 0 75 74"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                        fill="white"
                                        fillOpacity="0.66"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="dlab-thum-bx">
                              <img
                                src="images/product/item2/item4.jpg"
                                alt=""
                              />
                              <div className="overlay-bx">
                                <div className="overlay-icon">
                                  <span
                                    data-exthumbimage="images/product/item2/item4.jpg"
                                    data-src="images/product/item2/item4.jpg"
                                    className="view-btn lightimg"
                                  >
                                    <svg
                                      width={75}
                                      height={74}
                                      viewBox="0 0 75 74"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                        fill="white"
                                        fillOpacity="0.66"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="swiper-container thumb-slider sync2">
                        <div className="swiper-wrapper">
                          {imageInfos &&
                            imageInfos.map((img, index) => (
                              <div className="swiper-slide">
                                <div className="dlab-media">
                                  <img
                                    src={
                                      `https://api.bsecars.com/api/v1/files/` +
                                      img.modelImageName
                                    }
                                    alt={img.modelImageName}
                                  />
                                </div>
                              </div>
                            ))}
                          {/* <div className="swiper-slide">
                            <div className="dlab-media">
                              <img
                                src="images/product/thumb2/thumb2.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="dlab-media">
                              <img
                                src="images/product/thumb2/thumb3.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="dlab-media">
                              <img
                                src="images/product/thumb2/thumb4.jpg"
                                alt=""
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="m-b50">
                      <div className="dlab-post-title">
                        <h3 className="post-title">
                          <a href="javascript:void(0);">
                            {this.state.brand.brandName} -{" "}
                            {this.state.model.modelName}{" "}
                          </a>
                        </h3>
                        <h6 className="sub-title">
                          {this.state.variant.variantName}
                        </h6>
                        <p className="m-b10">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam
                        </p>
                        <div className="dlab-divider bg-gray tb15">
                          <i className="icon-dot c-square" />
                        </div>
                      </div>
                      <div className="social-list">
                        <span>Share</span>
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <svg
                                width={31}
                                height={29}
                                viewBox="0 0 31 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M28.0994 0H2.15474C0.965916 0 0 0.938616 0 2.09085V26.9092C0 28.0614 0.965916 29 2.15474 29H28.0994C29.2882 29 30.2609 28.0614 30.2609 26.9092V2.09085C30.2609 0.938616 29.2882 0 28.0994 0ZM9.14581 24.8571H4.66071V11.0174H9.15256V24.8571H9.14581ZM6.90326 9.12723C5.46452 9.12723 4.30272 8.00737 4.30272 6.63504C4.30272 5.26272 5.46452 4.14286 6.90326 4.14286C8.33525 4.14286 9.50381 5.26272 9.50381 6.63504C9.50381 8.01384 8.342 9.12723 6.90326 9.12723ZM25.9582 24.8571H21.4731V18.125C21.4731 16.5196 21.4393 14.4547 19.1427 14.4547C16.8056 14.4547 16.4476 16.2025 16.4476 18.0085V24.8571H11.9625V11.0174H16.2652V12.9076H16.326C16.9272 11.8201 18.3929 10.6743 20.5747 10.6743C25.1138 10.6743 25.9582 13.542 25.9582 17.2705V24.8571Z"
                                  fill="#314BA5"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <svg
                                width={28}
                                height={25}
                                viewBox="0 0 28 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M25.1487 6.33653C25.1663 6.60534 25.1663 6.8742 25.1663 7.143C25.1663 15.3418 19.4461 24.7888 8.99107 24.7888C5.77008 24.7888 2.77793 23.7711 0.260986 22.0046C0.718628 22.0622 1.15861 22.0814 1.63386 22.0814C4.29156 22.0814 6.73813 21.1022 8.69184 19.4317C6.1925 19.3741 4.09798 17.5884 3.37633 15.1306C3.72838 15.1882 4.08037 15.2266 4.45003 15.2266C4.96044 15.2266 5.4709 15.1498 5.9461 15.0154C3.34117 14.4394 1.3874 11.9432 1.3874 8.92869V8.85191C2.14421 9.31274 3.02433 9.60075 3.95712 9.63911C2.42583 8.52542 1.42262 6.62454 1.42262 4.47401C1.42262 3.32197 1.70418 2.26591 2.19704 1.34426C4.9956 5.10766 9.20225 7.56535 13.9193 7.83421C13.8313 7.37338 13.7785 6.89341 13.7785 6.41337C13.7785 2.99554 16.313 0.211426 19.4636 0.211426C21.1004 0.211426 22.5789 0.960264 23.6174 2.16993C24.9022 1.90113 26.1343 1.38268 27.2256 0.672254C26.8031 2.11236 25.9055 3.32203 24.7262 4.09002C25.8703 3.95568 26.9792 3.60998 28 3.13C27.2257 4.35882 26.2576 5.45324 25.1487 6.33653Z"
                                  fill="#32C3E2"
                                />
                              </svg>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <svg
                                width={16}
                                height={29}
                                viewBox="0 0 16 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.1389 16.2336L14.9235 11.2135H10.0178V7.95578C10.0178 6.58237 10.7031 5.24363 12.9002 5.24363H15.1304V0.969525C15.1304 0.969525 13.1066 0.630371 11.1715 0.630371C7.13153 0.630371 4.4908 3.03479 4.4908 7.38745V11.2135H0V16.2336H4.4908V28.3695H10.0178V16.2336H14.1389Z"
                                  fill="#3D7AD6"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="product-description">
                      <ul className="nav nav-tabs style-1 m-b20">
                        <li>
                          <a
                            data-bs-toggle="tab"
                            href="#specifications"
                            className="nav-link active"
                          >
                            Specifications
                          </a>
                        </li>
                        
                        <li>
                          <a
                            data-bs-toggle="tab"
                            href="#features"
                            className="nav-link"
                          >
                            Features
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div id="specifications" className="tab-pane active">
                          <div className="icon-bx-wraper bx-style-1 p-a30 p-sm-a0">
                            <ul className="table-dl clearfix">
                              {this.state.specifications.map(
                                (specificationValue) => (
                                  <li
                                    style={{
                                      listStyleType: "none",
                                      width: "200%",
                                    }}
                                  >
                                    <div className="icon-bx-wraper bx-style-1 p-a30 p-sm-a0">
                                      <div class="col-md-5">
                                        <p class="text-left">
                                          {" "}
                                          {
                                            specificationValue.specification
                                              .specificationName
                                          }{" "}
                                          :{" "}
                                        </p>
                                      </div>
                                      <div class="col-md-8">
                                        <p class="text-right">
                                          {" "}
                                          {
                                            specificationValue.specificationValueName
                                          }{" "}
                                          {specificationValue.specificationUnit}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>

                        <div id="features" className="tab-pane">
                          <div className="icon-bx-wraper bx-style-1 p-a30 p-sm-a0">
                            <ul className="table-dl clearfix">
                              {this.state.features.map((featureValue) => (
                                <li
                                  style={{
                                    listStyleType: "none",
                                    width: "200%",
                                  }}
                                >
                                  <div className="icon-bx-wraper bx-style-1 p-a30 p-sm-a0">
                                    <div class="col-md-5">
                                      <p class="text-left">
                                        {" "}
                                        {
                                          featureValue.feature.featureName
                                        } :{" "}
                                      </p>
                                    </div>
                                    <div class="col-md-8">
                                      <p class="text-right">
                                        {" "}
                                        {featureValue.featureValueName}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ))}

                            </ul>
                          </div>
                        </div>
                       
                      </div>
                    </div> */}
                  </div>
                  <div className="col-xl-4  col-lg-5 col-md-6">
                   
                    <div className="sticky-top nav-tabs-top">
                      <div className="car-dl-info icon-bx-wraper style-1 m-b50">
                        <div className="form-group">
                          <select
                            class="form-select col-12"
                            name="variantId"
                            aria-label="Default select example"
                            onChange={this.changeVariantIdHandler}
                          >
                            {this.state.variants.map((currentVariant) => (
                              <option value={currentVariant.variantId}>
                                {currentVariant.variantName}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="row  d-flex justify-content-between">
                              <div className="col-md-2 ">
                                {" "}
                                Colors:&nbsp;
                              </div>
                              <div className="col-md-10">
                            <Circle
                              class="circle"
                              colors={this.state.colors}
                              color={this.state.hex}
                            />
                          </div>
                        </div>


                        
                        <div class="price m-b30">
                          <h3 class="">
                            Price &nbsp;
                            <label>
                              <CurrencyFormat
                                value={this.state.onRoadPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"\u20B9"}
                                renderText={(value) => <div>{value}</div>}
                              />
                            </label>
                          </h3>

                          <span>
                            {this.state.brand.brandName} -{" "}
                            {this.state.model.modelName}{" "}
                            {this.state.variant.variantName}
                          </span>
                        </div>
                        {/* <div>
                          <h6 class="card-title">
                            <div>
                              Colors:
                              <Circle
                                colors={this.state.colors}
                                color={this.state.hex}
                              />
                            </div>
                          </h6>
                        </div> */}
                        <form>
                          {this.state.variant.exShowroom > 0 && (
                            <div className="row  d-flex justify-content-between">
                              <div className="col-md-8 ">
                                {" "}
                                Ex-showroom Price:
                              </div>
                              <div className="col-md-3">
                                <label>
                                  <CurrencyFormat
                                    style={{ textAlign: "right" }}
                                    value={this.state.variant.exShowroom}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"\u20B9"}
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </label>
                              </div>
                              {/* <label>
                               
                                <CurrencyFormat
                                  value={this.state.variant.exShowroom}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"\u20B9"}
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </label> */}
                            </div>
                          )}
                          {this.state.variant.regAmount > 0 && (
                            <div className="row  d-flex justify-content-between">
                              <div className="col-md-8 ">
                                {" "}
                                Registration Amount:
                              </div>
                              <div className="col-md-3">
                                <label>
                                  <CurrencyFormat
                                    value={this.state.variant.regAmount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"\u20B9"}
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </label>
                              </div>
                            </div>
                          )}
                          {this.state.variant.insPayment > 0 && (
                            <div className="row  d-flex justify-content-between">
                              <div className="col-md-8 ">
                                {" "}
                                Insurance Payment:
                              </div>
                              <div className="col-md-3">
                                <label style={{ textAlign: "right" }}>
                                  <CurrencyFormat
                                    value={this.state.variant.insPayment}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"\u20B9"}
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </label>
                              </div>
                            </div>
                          )}
                          {this.state.variant.tcs > 0 && (
                            <div className="row  d-flex justify-content-between">
                           <div className="col-md-8 ">
                                {" "}
                                TCS:
                              </div>
                              <div className="col-md-3">
                              
                                <label>
                                  <CurrencyFormat
                                    value={this.state.variant.tcs}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"\u20B9"}
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </label>
                            </div>
                            </div>
                          )}
                          {this.state.variant.autoCard > 0 && (
                            <div className="row  d-flex justify-content-between">
                              <div className="col-md-8 ">
                                {" "}
                                Auto Card:
                              </div>
                              <div className="col-md-3">
                        
                                <label>
                                  <CurrencyFormat
                                    value={this.state.variant.autoCard}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"\u20B9"}
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </label>
                            </div>
                            </div>
                          )}
                          {this.state.variant.fastTag > 0 && (
                            <div className="row  d-flex justify-content-between">
                              <div className="col-md-8">Fast Tag:</div>
                              <div className="col-md-3">
                                <label style={{ textAlign: "right" }}>
                                  <CurrencyFormat
                                    value={this.state.variant.fastTag}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"\u20B9"}
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </label>
                              </div>
                            </div>
                          )}
                          {this.state.variant.discount > 0 && (
                            <div className="row  d-flex justify-content-between">
                           <div className="col-md-8 ">
                                {" "}
                                Discount:
                              </div>
                              <div className="col-md-3">
                              
                                <label>
                                  <CurrencyFormat
                                    value={this.state.variant.discount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"\u20B9"}
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </label>
                       </div>
                            </div>
                          )}

                          <div className="clearfix">
                            {/* <button
                              type="button"
                              className="btn-primary btn btn-block"
                            >
                              View On Road Price{" "}
                            </button> */}
                            <button
                              type="button"
                              className="btn btn-primary btn-block"
                              data-toggle="modal"
                              data-target="#enquiryModal"
                            >
                              Enquire Now
                            </button>

                            

                            {/* <div
                              className="modal fade"
                              id="enquiryModal"
                              tabindex="-1"
                              data-keyboard="false"
                              data-backdrop="static"
                            >
                              <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Guranteed Delivery !!!
                                    </h5>
                                    <button
                                      className="close"
                                      data-dismiss="modal"
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="form-group">
                                        <label for="inputUserName" req>
                                          Name
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Customer Name"
                                          name="customerName"
                                          className="form-control"
                                          value={this.state.customerName}
                                          onChange={
                                            this.changeCustomerNameHandler
                                          }
                                          required
                                        />
                                        <p className="text-danger">
                                          {" "}
                                          {this.state.customerNameVal}
                                        </p>
                                      </div>
                                      <div className="form-group">
                                        <label for="inputEmail">Email Id</label>
                                        <input
                                          type="email"
                                          placeholder="Email"
                                          name="Email"
                                          className="form-control"
                                          value={this.state.customerEmail}
                                          onChange={
                                            this.changeCustomerEmailHandler
                                          }
                                          required
                                        />
                                        <p className="text-danger">
                                          {" "}
                                          {this.state.customerEmailVal}
                                        </p>
                                      </div>
                                      <div className="form-group">
                                        <label for="inputMobile">Mobile</label>
                                        <input
                                          type="text"
                                          placeholder="Customer Number"
                                          name="customerNumber"
                                          className="form-control"
                                          value={
                                            this.state.customerMoblieNumber
                                          }
                                          onChange={
                                            this.changecustomerNumberHandler
                                          }
                                          required
                                        />
                                        <p className="text-danger">
                                          {" "}
                                          {this.state.customerMoblieNumberVal}
                                        </p>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      className="btn btn-primary"
                                      onClick={this.saveOrUpdateCustomer}
                                    >
                                      Send Enquiry
                                    </button>

                                    <button
                                      id="btnHideModal"
                                      type="button"
                                      className="btn btn-primary"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          
                        </form>


                      </div>

                      

                      <div className="used-car-features clearfix m-b50">
                        <div className="car-features">
                          <svg
                            className="mt-2 mb-3"
                            enableBackground="new 0 0 32 32"
                            height="28px"
                            id="Layer_1"
                            version="1.1"
                            viewBox="0 0 32 32"
                            width="32px"
                            xmlSpace="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <g id="calendar_1_">
                              <path
                                d="M29.334,3H25V1c0-0.553-0.447-1-1-1s-1,0.447-1,1v2h-6V1c0-0.553-0.448-1-1-1s-1,0.447-1,1v2H9V1   c0-0.553-0.448-1-1-1S7,0.447,7,1v2H2.667C1.194,3,0,4.193,0,5.666v23.667C0,30.806,1.194,32,2.667,32h26.667   C30.807,32,32,30.806,32,29.333V5.666C32,4.193,30.807,3,29.334,3z M30,29.333C30,29.701,29.701,30,29.334,30H2.667   C2.299,30,2,29.701,2,29.333V5.666C2,5.299,2.299,5,2.667,5H7v2c0,0.553,0.448,1,1,1s1-0.447,1-1V5h6v2c0,0.553,0.448,1,1,1   s1-0.447,1-1V5h6v2c0,0.553,0.447,1,1,1s1-0.447,1-1V5h4.334C29.701,5,30,5.299,30,5.666V29.333z"
                                fill="#333332"
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={7}
                                y={12}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={7}
                                y={17}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={7}
                                y={22}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={14}
                                y={22}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={14}
                                y={17}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={14}
                                y={12}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={21}
                                y={22}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={21}
                                y={17}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={21}
                                y={12}
                              />
                            </g>
                          </svg>
                          <h6>2019</h6>
                          <span>Model</span>
                        </div>
                        <div className="car-features">
                          <svg
                            className="mt-2 mb-3"
                            data-name="Capa 1"
                            height="32px"
                            id="Capa_1"
                            viewBox="0 0 20 17"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M18.19,10.24a8.17,8.17,0,0,0-3-5.18A8.28,8.28,0,0,0,10,3.32h0A9.14,9.14,0,0,0,5.12,4.85a7.93,7.93,0,0,0-3.41,6.33A7.84,7.84,0,0,0,2.94,16a1.47,1.47,0,0,0,1.18.57,1.63,1.63,0,0,0,.64-.13,1.4,1.4,0,0,0,.76-1.68.3.3,0,0,0,0-.1s-1.6-3-.11-5.57L5.4,9h0s0,0,0,0A5.05,5.05,0,0,1,10,6.32,5.48,5.48,0,0,1,14.61,9a5.09,5.09,0,0,1,.58,3.54l-5-1.88a.38.38,0,1,0-.27.7L15,13.25a5.92,5.92,0,0,1-.37,1,1.63,1.63,0,0,0-.14,1.36,1.38,1.38,0,0,0,.75.8c.65.27,1.55.3,2.2-1.1A8.09,8.09,0,0,0,18.19,10.24ZM4.8,14.92a.64.64,0,0,1-.35.78.74.74,0,0,1-.9-.2,7,7,0,0,1-1.09-4.26,9,9,0,0,1,.8-3.1l1.33.78A6.93,6.93,0,0,0,4.8,14.92ZM5,8.27,3.62,7.49a7.23,7.23,0,0,1,2-2A8.34,8.34,0,0,1,9.69,4.09l0,1.49A5.71,5.71,0,0,0,5,8.27Zm5.44-2.69,0-1.5a7.33,7.33,0,0,1,4.25,1.55,8,8,0,0,1,1.69,1.88L15,8.28A6.25,6.25,0,0,0,10.41,5.58ZM16.78,15c-.48,1-1,.85-1.23.73a.67.67,0,0,1-.34-.36,1,1,0,0,1,.1-.77,5.67,5.67,0,0,0,.41-1l.29.11a.35.35,0,0,0,.14,0,.39.39,0,0,0,.35-.25.38.38,0,0,0-.22-.48l-.38-.14a6,6,0,0,0-.47-3.86l1.32-.75a7.3,7.3,0,0,1,.7,2.19A7.37,7.37,0,0,1,16.78,15Z" />
                            <path d="M7.87,7.5a.34.34,0,1,0,.33.33A.34.34,0,0,0,7.87,7.5Z" />
                            <circle cx="12.14" cy="7.81" r="0.33" />
                            <path d="M14.85,11.62a.34.34,0,1,0-.34.33A.34.34,0,0,0,14.85,11.62Z" />
                            <path d="M5.58,11.29a.33.33,0,1,0,.34.33A.33.33,0,0,0,5.58,11.29Z" />
                          </svg>
                          <h6>6,900 km</h6>
                          <span>Driven</span>
                        </div>
                        <div className="car-features">
                          <svg
                            className="mt-2 mb-3"
                            enableBackground="new 0 0 24 24"
                            height="32px"
                            id="Layer_2"
                            version="1.1"
                            viewBox="0 0 24 24"
                            width="24px"
                            xmlSpace="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <g>
                              <circle cx={8} cy={13} r={1} />
                              <circle cx={12} cy={13} r={1} />
                              <path d="M5,10h10V3H5V10z M6,4h8v5h-3.6L9.3,5l-1,0.3L9.4,9H6V4z" />
                              <path d="M21.4,4.2l-0.8,0.6L23,8v8c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4c0-1.7-1.3-3-3-3V1c0-0.6-0.4-1-1-1H3C2.4,0,2,0.4,2,1v21H1   c-0.6,0-1,0.4-1,1v1h20v-1c0-0.6-0.4-1-1-1h-1V10c1.1,0,2,0.9,2,2v4c0,1.1,0.9,2,2,2s2-0.9,2-2V7.7L21.4,4.2z M4,22V2h12v20H4z" />
                            </g>
                          </svg>
                          <h6>Diesel</h6>
                          <span>Fuel</span>
                        </div>
                        <div className="car-features">
                          <svg
                            className="mt-2 mb-3"
                            id="Lineal"
                            viewBox="0 0 512 512"
                            height="32px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title />
                            <g data-name="30. Police man" id="_30._Police_man">
                              <path d="M335.43,398.17a33.25,33.25,0,0,1-28.6-16.05,8,8,0,1,0-13.66,8.32A49,49,0,0,0,324.88,413l-20.22,35.22-23-26.53c3.63-4.17,2.76-9-.37-11.77a8,8,0,0,0-11.29.79L255.9,426.93l-35.44-39.37a48.85,48.85,0,0,0,5.39-19c61.85,16.26,134.68-18.66,137.09-98.32A32.25,32.25,0,0,0,389,238.46V202a8,8,0,0,0-16,0v36.46a16,16,0,0,1-10,15.09V191.22a39.93,39.93,0,0,0,21.82-26.52c4.77-19.06,2.69-11.72,9.12-32.18a56.7,56.7,0,0,0-29.78-68L282.82,26.1a63.76,63.76,0,0,0-54.64,0L146.84,64.49a56.7,56.7,0,0,0-29.78,68c6.29,20,4.73,14.63,7.2,24.5-.38,1.46-.26-4.7-.26,81.44a32.65,32.65,0,0,0,25.06,31.62c1.3,44.39,24.68,77.29,60.94,93v1.78a33.08,33.08,0,0,1-9.79,23.58c-11.19,11.16-25.3,9.76-23.64,9.76A82.52,82.52,0,0,0,94,480.74V488a8,8,0,0,0,16,0v-7.26a66.54,66.54,0,0,1,59.53-66.19L199.06,466a8,8,0,0,0,13,1.27l13.07-15.05-7,34.21a8,8,0,1,0,15.68,3.18L244.32,438c5.81,6.46,6.81,8.22,10.14,8.87A10.37,10.37,0,0,0,256,447a8.07,8.07,0,0,0,5.35-2.05c.63-.58,1.13-1.21,6.29-7.15l10.52,51.79a8,8,0,1,0,15.68-3.18l-6.95-34.21L300,467.25a8,8,0,0,0,13-1.27l29.53-51.43A66.53,66.53,0,0,1,402,480.74V488a8,8,0,0,0,16,0v-7.26A82.53,82.53,0,0,0,335.43,398.17ZM153.66,79,235,40.56a47.89,47.89,0,0,1,41,0L357.34,79a40.65,40.65,0,0,1,21.34,48.76L375.13,139H343a8,8,0,0,0,0,16h27.75l-1.45,5.82A24,24,0,0,1,346,179H165a24,24,0,0,1-23.28-18.18L140.25,155H301.67a8,8,0,0,0,0-16H135.87l-3.55-11.28A40.65,40.65,0,0,1,153.66,79ZM140,238.46V186.25a39.94,39.94,0,0,0,9,5.43V253.1A16.54,16.54,0,0,1,140,238.46Zm25,27.67V195c1.51,0,180.71,0,182,0v71.15C347,386.78,165,386.39,165,266.13Zm42.34,182.13L187.12,413a49,49,0,0,0,23.52-12.48L230,422.12Z" />
                            </g>
                          </svg>
                          <h6>Individual</h6>
                          <span>Seller</span>
                        </div>
                        <div className="car-features">
                          <svg
                            className="mt-2 mb-3"
                            enableBackground="new 0 0 32 32"
                            height="28px"
                            id="Layer_3"
                            version="1.1"
                            viewBox="0 0 32 32"
                            width="32px"
                            xmlSpace="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <g id="calendar_3_">
                              <path
                                d="M29.334,3H25V1c0-0.553-0.447-1-1-1s-1,0.447-1,1v2h-6V1c0-0.553-0.448-1-1-1s-1,0.447-1,1v2H9V1   c0-0.553-0.448-1-1-1S7,0.447,7,1v2H2.667C1.194,3,0,4.193,0,5.666v23.667C0,30.806,1.194,32,2.667,32h26.667   C30.807,32,32,30.806,32,29.333V5.666C32,4.193,30.807,3,29.334,3z M30,29.333C30,29.701,29.701,30,29.334,30H2.667   C2.299,30,2,29.701,2,29.333V5.666C2,5.299,2.299,5,2.667,5H7v2c0,0.553,0.448,1,1,1s1-0.447,1-1V5h6v2c0,0.553,0.448,1,1,1   s1-0.447,1-1V5h6v2c0,0.553,0.447,1,1,1s1-0.447,1-1V5h4.334C29.701,5,30,5.299,30,5.666V29.333z"
                                fill="#333332"
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={7}
                                y={12}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={7}
                                y={17}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={7}
                                y={22}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={14}
                                y={22}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={14}
                                y={17}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={14}
                                y={12}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={21}
                                y={22}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={21}
                                y={17}
                              />
                              <rect
                                fill="#333332"
                                height={3}
                                width={4}
                                x={21}
                                y={12}
                              />
                            </g>
                          </svg>
                          <h6>Automatic</h6>
                          <span>Transmission</span>
                        </div>
                        <div className="car-features">
                          <svg
                            className="mt-2 mb-3"
                            enableBackground="new 0 0 50 50"
                            height="32px"
                            id="Layer_5"
                            version="1.1"
                            viewBox="0 0 50 50"
                            width="50px"
                            xmlSpace="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <rect fill="none" height={50} width={50} />
                            <polyline
                              fill="none"
                              points="28.081,29.919   24,34 22,34 22,36 20,38 18,38 18,40 15,43 12,43 12,46 9,49 3,49 1,47 1,41 19.96,22.04 "
                              stroke="#000000"
                              strokeLinecap="round"
                              strokeMiterlimit={10}
                              strokeWidth={2}
                            />
                            <path
                              d="M49,16  c0,8.281-6.719,15-15,15s-15-6.719-15-15c0-8.282,6.719-15,15-15S49,7.718,49,16z"
                              fill="none"
                              stroke="#000000"
                              strokeLinecap="round"
                              strokeMiterlimit={10}
                              strokeWidth={2}
                            />
                            <path
                              d="M41.071,23.071L26.929,8.929  c3.904-3.904,10.238-3.904,14.143,0S44.976,19.167,41.071,23.071z"
                              fill="none"
                              stroke="#000000"
                              strokeLinecap="round"
                              strokeMiterlimit={10}
                              strokeWidth={2}
                            />
                            <line
                              fill="none"
                              stroke="#000000"
                              strokeLinecap="round"
                              strokeMiterlimit={10}
                              strokeWidth={2}
                              x1="1.61"
                              x2="18.282"
                              y1="46.39"
                              y2="29.718"
                            />
                          </svg>
                          <h6>First</h6>
                          <span>Owner</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="content-inner-2">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="section-head row">
                      <div className="col-sm-8">
                        <h3 className="title">FOR YOUR QUICK LOOK</h3>
                      </div>
                      <div className="col-sm-4 text-sm-end">
                        <div className="portfolio-pagination d-inline-block">
                          <div className="btn-prev swiper-button-prev2">
                            <i className="las la-arrow-left" />
                          </div>
                          <div className="btn-next swiper-button-next2">
                            <i className="las la-arrow-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-container similar-slider lightgallery">
                    <div className="swiper-wrapper">
                            {models &&
                              models.map((model, index1) => (
                                <div className="swiper-slide">
                                  <div className="car-list-box border shadow-none">
                                    <div className="media-box" style={{height:"220px"}}>
                                      <img
                                      
                                        src={
                                          " https://api.bsecars.com/api/v1/models_get_base_image/" +
                                          model.modelId
                                        }
                                        alt=""
                                        onClick={() => this.carListing()}
                                      />
                                      <div className="overlay-bx">
                                        <span
                                          data-exthumbimage={
                                            " https://api.bsecars.com/api/v1/models_get_base_image/" +
                                            model.modelId
                                          }
                                          data-src={
                                            " https://api.bsecars.com/api/v1/models_get_base_image/" +
                                            model.modelId
                                          }
                                          className="view-btn lightimg"
                                        >
                                          <svg
                                            width={75}
                                            height={74}
                                            viewBox="0 0 75 74"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                              fill="white"
                                              fillOpacity="0.66"
                                            />
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="list-info">
                                      <h5 className="title mb-0">
                                        <a
                                          onClick={() =>
                                            this.displayModel(model.modelId)
                                            
                                          }style={{height:"20px"}}
                                          data-splitting
                                          className="text-black"
                                        >
                                          {model.brand.brandName} - 
                                          {model.modelName}
                                        </a>
                                      </h5>
                                      <div className="car-type">{model.modelName}</div>
                                      <span className="badge m-b30">
                                        <CurrencyFormat
                                          className="text-black"
                                          value={model?.first}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"Starts from \u20B9"}
                                          renderText={(value) => (
                                            <div>{value}</div>
                                          )}
                                        />
                                         
                                      </span>
                                      <div className="feature-list" style={{height:"50px"}}>
                                        <div>
                                          <label>Transmission</label>
                                          <p className="value">
                                            {model.transmissions.map(
                                              (transmission) => (
                                                <span key={transmission}>
                                                  {transmission},
                                                </span>
                                              )
                                            )}
                                          </p>
                                        </div>
                                        <div>
                                          <label>Fuel</label>
                                          <p className="value">
                                            {" "}
                                            {model.fuelTypes.map((fuelType) => (
                                              <span key={fuelType}>
                                                {fuelType},
                                              </span>
                                            ))}
                                          </p>
                                        </div>
                                        {/* <div>
                                          <label>Passenger</label>
                                          <p className="value">5 Person</p>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                           
                          </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="content-inner">
              <div className="container">
                <div className="row call-to-action-bx">
                  <div className="col-xl-5 col-lg-6 me-auto">
                    <div className="section-head">
                      <h2 className="title text-white">
                        Have any question about us?
                      </h2>
                    </div>
                    <a
                      href="tel:+91 80073 58007"
                      className="btn btn-white me-3 mb-2"
                    >
                      <i className="fas fa-phone-volume me-sm-3 me-0 shake" />
                      <span className="d-sm-inline-block d-none">
                        +91 80073 58007
                      </span>
                    </a>
                    <a
                      href="/contact-us"
                      className="btn btn-outline-white effect-1  mb-2"
                    >
                      <span>Contact Us</span>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <div className="media-box">
                      <img
                        src="https://mobhil.dexignlab.com/xhtml/images/about/pic5.jpg"
                        className="main-img"
                        alt=""
                      />
                      <img
                        src="images/pattern/pattern7.png"
                        className="pt-img move-1"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* Footer */}
          <FrontEndFooterComponent />
          {/* Footer End */}
          <button className="scroltop icon-up" type="button">
            <i className="fas fa-arrow-up" />
          </button>
        </div>


           {/* Modal Start */}
           <div
          className="modal fade"
          id="enquiryModal"
          tabindex="-1"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Guranteed Delivery !!!</h5>
                <button className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label for="inputUserName" req>
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder="Customer Name"
                      name="customerName"
                      className="form-control"
                      value={this.state.customerName}
                      onChange={this.changeCustomerNameHandler}
                      required
                    />
                    <p className="text-danger"> {this.state.customerNameVal}</p>
                  </div>
                  <div className="form-group">
                    <label for="inputEmail">Email Id</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="Email"
                      className="form-control"
                      value={this.state.customerEmail}
                      onChange={this.changeCustomerEmailHandler}
                      required
                    />
                    <p className="text-danger">
                      {" "}
                      {this.state.customerEmailVal}
                    </p>
                  </div>
                  <div className="form-group">
                    <label for="inputMobile">Mobile</label>
                    <input
                      type="text"
                      placeholder="Customer Number"
                      name="customerNumber"
                      className="form-control"
                      value={this.state.customerMoblieNumber}
                      onChange={this.changecustomerNumberHandler}
                      required
                    />
                    <p className="text-danger">
                      {" "}
                      {this.state.customerMoblieNumberVal}
                    </p>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  onClick={this.saveOrUpdateCustomer}
                >
                  Send Enquiry
                </button>

                <button
                  id="btnHideModal"
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* <div className="row">
                
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/pune.svg"
                      width="100"
                      height="75"
                      alt="Pune"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Pune</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/mumbai.svg"
                      width="100"
                      height="75"
                      alt="Mumbai"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Mumbai</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/agra.svg"
                      width="100"
                      height="75"
                      alt="Agra"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Agra</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/ahmedabad.svg"
                      width="100"
                      height="75"
                      alt="ahmedabad"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Ahmedabad</h6>
                  </center>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/jaipur.svg"
                      width="100"
                      height="75"
                      alt="Jaipur"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Jaipur</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/chennai.svg"
                      width="100"
                      height="75"
                      alt="Chennai"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Chennai</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/chandigarh.svg"
                      width="100"
                      height="75"
                      alt="Chandigarh"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Chandigarh</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/Bangaloru.svg"
                      width="100"
                      height="75"
                      alt="Bangaloru"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Bangaloru</h6>
                  </center>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/kolkata.svg"
                      width="100"
                      height="75"
                      alt="Kolkata"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Kolkata</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/lucknow.svg"
                      width="100"
                      height="75"
                      alt="Lucknow"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Lucknow</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/surat.svg"
                      width="100"
                      height="75"
                      alt="Surat"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Surat</h6>
                  </center>
                </div>
                <div className="col-md-3">
                  <center>
                    <img
                      src="/images/goa.svg"
                      width="100"
                      height="75"
                      alt="Goa"
                      class="listing__StyledBrandImage-sc-8k8s2c-6 dzcvVt"
                    ></img>
                    <h6>Goa</h6>
                  </center>
                </div>
              </div> */}
          {/* <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label for="inputUserName">Select City</label>
                  </div>
                  <div>
                    <img
                      class="rounded-circle"
                      alt="avatar1"
                      src="/images/pune.svg"
                    />
                  </div>
                  <div>
                    <img
                      class="rounded-circle shadow-4-strong"
                      alt="avatar2"
                      src="/images/mumbai.svg"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.saveOrUpdateCustomer}
                >
                  Send Enquiry
                </button>

                <button
                  id="btnHideModal"
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div> */}
        </div>
        {/* Modal End */}
      </div>
    );
  }
}

export default DisplayVariantComponentNew;
