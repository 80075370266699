import React, { Component } from 'react'
import DealerService from '../services/DealerService'


import SideBarDealerComponent from './SideBarDealerComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import DataTable from "react-data-table-component";
// import { confirm } from "react-confirm-box";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { faEye, faPaperPlane, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../services/auth.service";



class ListDealerBackendInquiryComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            //    dealers: [],
               dealerInquiry:[],
               currentUser: null,
               tableData: {},
               selctedDealersInquiryRows: [],
               columns: [
                 {
                   name: " Id",
                   selector: (row) => row.dealerInquiryId,
                   cellExport: (row) => row.dealerInquiryId,
                   sortable: true,
                 },
                 {
                   name: "Car Name",
                   selector: (row) => row.customer.inquiry,
                   cellExport: (row) => row.customer.inquiry,
                   sortable: true,
                 },
                 
                 {
                   cell: (row) => (
                     <a href={`/view-dealerInquiry/${row["dealerInquiryId"]}`}>
                       <FontAwesomeIcon icon={faEye} />
                     </a>
                   ),
                   ignoreRowClick: true,
                   allowOverflow: true,
                   button: true,
                 },
                 {
                   cell: (row) => (
                     <a href={`/add-dealerInquiry/${row["dealerInquiryId"]}`}>
                       <FontAwesomeIcon icon={faEdit} />
                     </a>
                   ),
                   ignoreRowClick: true,
                   allowOverflow: true,
                   button: true,
                 },
                 {
                    cell: (row) => (
                      <a href="#!" onClick={() => this.clickHandler(row)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </a>
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
              

                ]

        }
       
        this.editDealer = this.editDealer.bind(this);
        this.deleteDealer = this.deleteDealer.bind(this);
        this.createAvailability = this.createAvailability.bind(this);
    }

    deleteDealer(id){

        DealerService.deleteDealer(id).then( res => {
            this.setState({dealer: this.state.dealers.filter(dealer => dealer.dealerId !== id)});
        });
        this.componentDidMount();

    }
    
  clickHandler = (row) => {
    this.deleteDealer(row.dealerId);
  };



    viewDealer(id){
        this.props.history.push(`/view-dealer/${id}`);
    }
    editDealer(id){
        this.props.history.push(`/add-dealer/${id}`);
    }

    componentDidMount(){
        DealerService.getDealersInquiry().then((res) => {

            console.log( res.data);
            this.setState({dealerInquiry: res.data});
            this.setState({
                tableData: { columns: this.state.columns, data: res.data },
              });
              
        });


    }




    createAvailability(dealerInquiryId){



        const currentUser = AuthService.getCurrentUser();

      //  alert(JSON.stringify(currentUser));

        let carAvailability = {
            dealerInquiry : { dealerInquiryId : dealerInquiryId } ,
            dealer : { dealerId: currentUser.id} ,
            status: "Yes",
          
        };

      //  alert(JSON.stringify(carAvailability));

        DealerService.createAvailability(carAvailability).then( res => {

            alert(res.data.message);
        });
    }

    addDealer(){
        this.props.history.push('/add-dealer/0');
    }



    render() {
        return (
           // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarDealerComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Dealer</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">List Dealer
                                    {/* <button className="btn btn-sm btn-primary" onClick={this.addDealer}> Add Dealer</button> */}
                                    </h6> 
                                    

                                </div>
                                <div class="card-body">
                 {/* <h2 className="text-center">Dealers List</h2> */}
                 <div className = "row">
                 <DataTableExtensions {...this.state.tableData}>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.dealers}
                            defaultSortField="Id"
                            defaultSortDesc={false}
                            pagination
                            highlightOnHover
                            selectableRows
                            print={false}
                            onSelectedRowsChange={this.handleChange}
                            selectedRows={this.handleChange}
                          />
                        </DataTableExtensions>
                      
                 </div>
                 <br></br>
                 <div className = "row" >
                        <table className = "table table-striped table-bordered">

                            <thead>
                                <tr>
                                    <th> Dealer Inquiry Id</th>
                                    <th> Car Name</th>
                                    <th> Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.dealerInquiry.map(
                                        dealerInquiry => 
                                        <tr key = {dealerInquiry.dealerInquiryId}>
                                            <td> {dealerInquiry.dealerInquiryId} </td>  
                                            <td> {dealerInquiry.customer.inquiry} </td>    
                                            <td> <button style={{marginLeft: "10px"}} onClick={() => this.createAvailability(dealerInquiry.dealerInquiryId)}  className="btn btn-info"><FontAwesomeIcon icon={ faPaperPlane }/> </button>
                                            </td>        
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            
                 </div>
                            </div>

                          

                        </div>

                      
                    </div>

                {/* <!-- Content Row --> */}


                {/* <!-- Content Row --> */}
             

            </div>
            {/* <!-- /.container-fluid --> */}

        </div>
        {/* <!-- End of Main Content --> */}
<BackEndFooterComponent/>

    </div>
    {/* <!-- End of Content Wrapper --> */}

</div>
// <!-- End of Page Wrapper --> 
        )
    }
}

export default ListDealerBackendInquiryComponent
