import axios from 'axios';

const RENTALINFORMATION_API_BASE_URL = "https://api.bsecars.com/api/v1/rentalInformation";

class RentalInformationService {

    getRentalInformations(){
        return axios.get(RENTALINFORMATION_API_BASE_URL);
    }

    createRentalInformation(RentalInformation){
        return axios.post(RENTALINFORMATION_API_BASE_URL, RentalInformation);
    }

    getRentalInformationById(RentalInformationId){
        return axios.get(RENTALINFORMATION_API_BASE_URL + '/' + RentalInformationId);
    }

    updateRentalInformation(RentalInformation, RentalInformationId){
        return axios.put(RENTALINFORMATION_API_BASE_URL + '/' + RentalInformationId, RentalInformation);
    }

    deleteRentalInformation(RentalInformationId){
        return axios.delete(RENTALINFORMATION_API_BASE_URL + '/' + RentalInformationId);
    }
}

export default new RentalInformationService()