import React, { Component } from "react";
import VariantService from "../services/VariantService";
import SideBarComponent from "./SideBarComponent";
import BackEndFooterComponent from "./BackEndFooterComponent";
import TopBarComponent from "./TopBarComponent";

class ViewVariantComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      variant: {},
      model: {},
      brand: {},
      specificationValues: [],
      featureValues: [],
      specification: [],
    };

    this.viewVariants = this.viewVariants.bind(this);
  }

  componentDidMount() {
    VariantService.getVariantById(this.state.id).then((res) => {
      // console.log(res);

      console.log("Home Data: ", res.data.specificationValues);
      this.setState({ variant: res.data });
      this.setState({ model: res.data.model });
      this.setState({ brand: res.data.model.brand });
      this.setState({ specificationValues: res.data.specificationValues });
      this.setState({ featureValues: res.data.featureValues });
    });
  }

  viewVariants() {
    this.props.history.push("/variants");
  }

  render() {
    return (
      // <!-- Page Wrapper -->
      <div id="wrapper">
        <SideBarComponent />

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" class="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <TopBarComponent />

            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Brand</h1>
                
                </div> */}

              {/* <!-- Content Row --> */}
              {/* <!-- Content Row --> */}
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  {/* <!-- Area Chart --> */}
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">
                        View Variant
                      </h6>
                      <button
                        className="btn btn-primary"
                        onClick={this.viewVariants}
                      >
                        {" "}
                        List Variant
                      </button>
                    </div>
                    <div class="card-body">
                      {/* <h2 className="text-center">Variants List</h2> */}
                      <div className="row"></div>
                      <br></br>
                      <div className="card col-md-12 offset-md-0">
                        <h3 className="text-center"> View Variant Details</h3>
                        <div className="card-body">
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b> Variant Name:</b>{" "}
                              </label>
                              &nbsp; {this.state.variant.variantName}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                {" "}
                                <b>Model Name: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.model.modelName}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b> Brand Name: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.brand.brandName}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b> Ex Showroom: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.variant.exShowroom}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b> Registration Amount: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.variant.regAmount}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b> Insurance Payment: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.variant.insPayment}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b> Auto Card: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.variant.autoCard}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b> Tcs: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.variant.tcs}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b> Fast Tag: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.variant.fastTag}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b>Discount: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.variant.discount}
                            </h6>
                          </div>
                          <div className="row">
                            <h6 class="">
                              <label>
                                <b>Featured Cars: </b>{" "}
                              </label>
                              &nbsp;
                              {this.state.variant.featuredCars}
                            </h6>
                          </div>
                          <div class="card" style={{ margin: 10 }}>
                            <div class="card-body">
                              <div class="container mt-4">
                                <h3>
                                  Car Details - Specifications &amp; Features
                                </h3>
                                <br />
                                {/* <!-- Nav tabs --> */}
                                <ul class="nav nav-tabs">
                                  <li class="nav-item">
                                    <a
                                      class="nav-link active"
                                      data-toggle="tab"
                                      href="#home"
                                    >
                                      Specifications
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      data-toggle="tab"
                                      href="#menu1"
                                    >
                                      Features
                                    </a>
                                  </li>
                                </ul>

                                {/* <!-- Tab panes -->! */}
                                <div class="tab-content">
                                  <div
                                    id="home"
                                    class="container tab-pane active"
                                  >
                                    <br />
                                    <p>
                                      <ul>
                                        {this.state.specificationValues.map(
                                          (specificationValue) => (
                                            <li>
                                              {
                                                specificationValue.specification
                                                  .specificationName
                                              }{" "}
                                              :{" "}
                                              {
                                                specificationValue.specificationValueName
                                              }{" "}
                                              {
                                                specificationValue.specificationUnit
                                              }
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </p>
                                  </div>
                                  <div
                                    id="menu1"
                                    class="container tab-pane fade"
                                  >
                                    <br />

                                    <ul>
                                      {this.state.featureValues.map(
                                        (featureValue) => (
                                          <li>
                                            {featureValue.feature.featureName} :{" "}
                                            {featureValue.featureValueName}{" "}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}
          <BackEndFooterComponent />
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      // <!-- End of Page Wrapper -->
    );
  }
}

export default ViewVariantComponent;
