import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import BrandService from "../services/BrandService";

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';

class CreateBrandComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
        // step 2
        id: this.props.match.params.id,
        brandName: '',  message: ''
      
    }
    this.changeBrandNameHandler = this.changeBrandNameHandler.bind(this);

    this.saveOrUpdateBrand = this.saveOrUpdateBrand.bind(this);
    
}

// step 3
componentDidMount(){

    // step 4
    if(this.state.id === '0'){
        return
    }else{
        BrandService.getBrandById(this.state.id).then( (res) =>{
            let brand = res.data;
            this.setState({
                brandName: brand.brandName,
            });
        });
    }        
}
saveOrUpdateBrand = (e) => {
    e.preventDefault();


 // validations
 if (this.state.brandName === '') {
     this.setState({message: 'Invalid!, Please enter valid input'})
     return false;
 } else {
     this.setState({message: 'Yeah!, Got Valid input'})
 }   

    let brand = {brandId:this.state.id, brandName: this.state.brandName};
    console.log('brand => ' + JSON.stringify(brand));

    // step 5
    if(this.state.id === '0'){
        BrandService.createBrand(brand).then(res =>{
            this.props.history.push('/brands');
        });
    }else{
        BrandService.updateBrand(brand, this.state.id).then( res => {
            this.props.history.push('/brands');
        });
    }
}

changeBrandNameHandler= (event) => {
    this.setState({brandName: event.target.value});
}



cancel(){
    this.props.history.push('/Brands');
}

getTitle(){
    if(this.state.id === '0'){
        return <h6 className="m-0 font-weight-bold text-primary">Add Brand</h6>
    }else{
        return <h6 className="m-0 font-weight-bold text-primary">Update Brand</h6>
    }
}


      render() {
        if (this.state.redirect) {
          return <Redirect to={this.state.redirect} />
        }
    
      //  const { currentUser } = this.state;
    
        return (

 // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Brand</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                <a class="btn btn-sm btn-primary float-right   mr-3" href="/brands" role="button">List Brand</a>

                                 {  this.getTitle() }
                                </div>
                                <div class="card-body">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                              
                                <div className = "card-body">
                                    <form> 
                                        <div className = "form-group">
                                            <label> Brand Name: </label>
                                            <input placeholder="Brand Name" name="brandName" className="form-control" 
                                                value={this.state.brandName} onChange={this.changeBrandNameHandler} required/>
                                                <p className="text-danger"> {this.state.message }</p>

                                        </div>
                                      

                                        <button className="btn btn-success" onClick={this.saveOrUpdateBrand}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 










        );
      }
}

export default CreateBrandComponent
