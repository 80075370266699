import React, { Component } from 'react'
import CustomerService from '../services/CustomerService'
import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';

class ViewCustomerComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            customer: {}
        }

        this.viewCustomers = this.viewCustomers.bind(this);
    }

    componentDidMount(){
        CustomerService.getCustomerById(this.state.id).then( res => {

            console.log("customer data",res.data);

            this.setState({customer: res.data});
        })
    }

    viewCustomers(){
        this.props.history.push('/customers');
    }


    render() {
        return (
           // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Customer</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center">View Customer
                                    <button className="btn btn-primary" style={{marginLeft: "800px"}} onClick={this.viewCustomers}> List Customer</button>
                                    </h6>
                                </div>
                                <div class="card-body">
                                {/* <h2 className="text-center">Customers List</h2> */}
                 <div className = "row">
                   
                 </div>
                 <br></br>
                <div className = "card col-md-6 offset-md-3">
                    <h3 className = "text-center"> View Customer Details</h3>
                    <div className = "card-body">
                        <div className = "row">
                        <h6 class="">
                            <label> Customer Name :     </label> &nbsp; {this.state.customer.name}</h6>
                        </div>


                        {/* <div className = "row">
                           
                           <label> Customer Address: </label><div> {this.state.customer.address}</div>
                       </div>
                        */}
                       <div className = "row">
                       <h6 class="">
                           <label> Customer Email: </label>&nbsp; {this.state.customer?.email}</h6>
                       </div>
                       <div className = "row">
                       <h6 class="">
                           <label> Customer Number: </label>&nbsp; {this.state.customer?.mobile}</h6>
                       </div>
                       <div className = "row">
                       <h6 class="">
                           <label> Inquiry Status: </label>&nbsp; {this.state.customer?.inquiryStatus}</h6>
                       </div>
                       <div className = "row">
                       <h6 class="">
                           <label>Inquiry Description: </label>&nbsp; {this.state.customer?.inquiryDescription}
                           </h6>
                       </div>
                       <div className = "row">
                       <h6 class="">
                           <label>Message: </label>&nbsp; {this.state.customer?.message}
                           </h6>
                       </div>
                    </div>

                </div>
                <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 
        )
    }
}

export default ViewCustomerComponent
