import axios from 'axios';

const DEALER_API_BASE_URL = "https://api.bsecars.com/api/v1/dealers";

class DealerService {

    getDealers(){
        return axios.get(DEALER_API_BASE_URL);
    }

    createDealer(dealer){
        return axios.post(DEALER_API_BASE_URL, dealer);
    }

    getDealerById(dealerId){
        return axios.get(DEALER_API_BASE_URL + '/' + dealerId);
    }

    updateDealer(dealer, dealerId){
        return axios.put(DEALER_API_BASE_URL + '/' + dealerId, dealer);
    }

    deleteDealer(dealerId){
        return axios.delete(DEALER_API_BASE_URL + '/' + dealerId);
    }


    createDealerInquiry(dealerInquiry){
        return axios.post(DEALER_API_BASE_URL+ '/dealerInquiry' , dealerInquiry);
    }


    getDealersInquiry(){
        return axios.get(DEALER_API_BASE_URL+ '/dealerInquiry');
    }
    

    getDealerByBrandId(brandId){
        return axios.get(DEALER_API_BASE_URL + '/dealerInquiry' + brandId);
    }

    createAvailability(carAvailability){
        return axios.post(DEALER_API_BASE_URL + '/dealerCarAvailability', carAvailability);
    }

    getCarAvailability(){
        return axios.get(DEALER_API_BASE_URL + '/carAvailability');
    }
    
}

export default new DealerService()