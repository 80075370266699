import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import EquipmentService from "../services/EquipmentService";

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';

class CreateEquipmentComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
        // step 2
        id: this.props.match.params.id,
        equipmentName: '',    equipmentNameVal: '',
        year: '', yearVal: '',
         
      
    }
    this.changeEquipmentNameHandler = this.changeEquipmentNameHandler.bind(this);
    this.changeEquipmentYearHandler = this.changeEquipmentYearHandler.bind(this);


    this.saveOrUpdateEquipment = this.saveOrUpdateEquipment.bind(this);
    
}

// step 3
componentDidMount(){

    // step 4
    if(this.state.id === '0'){
        return
    }else{
        EquipmentService.getEquipmentById(this.state.id).then( (res) =>{
            let equipment = res.data;
            this.setState({
                equipmentName: equipment.equipmentName,
                year: equipment.year,
            });
        });
    }        
}
saveOrUpdateEquipment = (e) => {
    e.preventDefault();
    if (this.state.equipmentName === '') {
        this.setState({equipmentNameVal: 'Invalid!, Please enter equipment name'})
        return false;
    } 
    if (this.state. year === '') {
        this.setState({yearVal: 'Invalid!, Please enter year '})
        return false;
    }
    let equipment = {equipmentId:this.state.id, equipmentName: this.state.equipmentName, year: this.state.year };
    console.log('equipment => ' + JSON.stringify(equipment));

    // step 5
    if(this.state.id === '0'){
        EquipmentService.createEquipment(equipment).then(res =>{
            this.props.history.push('/equipments');
        });
    }else{
        EquipmentService.updateEquipment(equipment, this.state.id).then( res => {
            this.props.history.push('/equipments');
        });
    }
}

changeEquipmentNameHandler= (event) => {
    this.setState({equipmentName: event.target.value});
}

changeEquipmentYearHandler= (event) => {
    this.setState({year: event.target.value});
}



cancel(){
    this.props.history.push('/equipments');
}

getTitle(){
    if(this.state.id === '0'){
        return <h6 className="m-0 font-weight-bold text-primary">Add Equipment</h6>
    }else{
        return <h6 className="m-0 font-weight-bold text-primary">Update Equipment</h6>
    }
}


      render() {
        if (this.state.redirect) {
          return <Redirect to={this.state.redirect} />
        }
    
      //  const { currentUser } = this.state;
    
        return (

 // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Equipment</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                <a class="btn btn-sm btn-primary float-right   mr-3" href="/equipments" role="button">List equipments</a>

                                 {  this.getTitle() }
                                </div>
                                <div class="card-body">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                              
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Equipment Name: </label>
                                            <input placeholder="Equipment Name" name="equipmentName" className="form-control" 
                                                value={this.state.equipmentName} onChange={this.changeEquipmentNameHandler}/>
                                                <p className="text-danger"> {this.state.equipmentNameVal }</p>
                                                <div className = "form-group">
                                            <label> Year: </label>
                                            <input placeholder="Year" name="year" className="form-control" 
                                                value={this.state.year} onChange={this.changeEquipmentYearHandler}/>
                                              <p className="text-danger"> {this.state.yearVal }</p>
                                        </div>
                                        </div>
                                      

                                        <button className="btn btn-success" onClick={this.saveOrUpdateEquipment}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>
                               

                                    
                                </div>
                            </div>
                        </div>
                        <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 










        );
      }
}

export default CreateEquipmentComponent
