import React, { Component } from "react";
import FooterComponent from "./FooterComponent";
import ModelService from "../services/ModelService";
import BrandService from "../services/BrandService";
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../services/auth.service";
import CityService from "../services/CityService";
import FrontTopBarComponent from "./FrontTopBarComponent";
import ScriptTag from "react-script-tag";
import FrontEndFooterComponent from "./FrontEndFooterComponent";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import CurrencyFormat from "react-currency-format";

class IndexComponent extends Component {
  constructor(props) {
    super(props);

    this.xLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.onChangeSearchModel = this.onChangeSearchModel.bind(this);
    this.retrieveModels = this.retrieveModels.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveModel = this.setActiveModel.bind(this);
    this.removeAllModels = this.removeAllModels.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      models: [],
      specifications: [],
      variant: {},
      variantNameStr: "",
      brands: [],
      currentModel: null,
      currentIndex: -1,
      searchModel: "",
      page: 1,
      count: 0,
      pageSize: 8,
      totalModels: 0,
      brandId: "",
      modelId: "",
      fuelType: "",
      transmission: "",
      volume: 0,
      options: [
        { name: "Pune", id: 1 },
        { name: "Mumbai", id: 2 },
        { name: "Nagpur", id: 3 },
        { name: "Mumbai", id: 4 },
        { name: "Aurangabad ", id: 5 },
      ],
      city: [],
    };

    this.pageSizes = [6, 10, 20];
  }

  displayModel(id) {
    this.props.history.push(`/display-variant-New/${id}`);
  }
  handleOnChange = (value) => {
    this.setState({
      volume: value,
    });
  };

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.history.push("/dashboard");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  componentDidMount() {
    BrandService.getBrands().then((res) => {
      this.setState({ brands: res.data });
    });

    CityService.getCitys().then((res) => {
      this.setState({ city: res.data });
    });

    this.retrieveModels();
  }

  onChangeSearchModel(e) {
    const searchModel = e.target.value;

    this.setState({
      searchModel: searchModel,
    });
  }

  onChangeBrandIdHandler = (event) => {
    //alert('Hello' + event.target.value);
    console.log("brandId: " + event.target.value);

    this.setState({ brandId: event.target.value });
    this.setState({ modelId: "" });

    console.log("state.brandId: " + this.state.brandId);

    this.retrieveModels(event.target.name, event.target.value);
  };
  onChangeModelIdHandler = (event) => {
    //alert('Hello' + event.target.value);
    console.log("modelId: " + event.target.value);

    this.setState({ modelId: event.target.value });
    console.log("state.modelId: " + this.state.modelId);
    this.retrieveModels(event.target.name, event.target.value);
  };

  onChangeFuelTypeHandler = (event) => {
    // alert('Hello' + event.target.value);
    console.log("fuelType: " + event.target.value);

    this.setState({ fuelType: event.target.value });
    console.log("state.fuelType: " + this.state.fuelType);
    this.retrieveModels(event.target.name, event.target.value);
  };
  onChangeTransmissionHandler = (event) => {
    // alert('Hello' + event.target.value);
    console.log("transmission: " + event.target.value);

    this.setState({ transmission: event.target.value });
    console.log("state.transmission: " + this.state.transmission);
    this.retrieveModels(event.target.name, event.target.value);
  };

  getRequestParams(brandId, modelId, fuelType, transmission, page, pageSize) {
    let params = {};

    if (brandId) {
      params["brandId"] = brandId;
    }
    if (modelId) {
      params["modelId"] = modelId;
    }
    if (fuelType) {
      params["fuelType"] = fuelType;
    }
    if (transmission) {
      params["transmission"] = transmission;
    }
    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveModels() {
    const { brandId, modelId, fuelType, transmission, page, pageSize } =
      this.state;

    const params = this.getRequestParams(
      brandId,
      modelId,
      fuelType,
      transmission,
      page,
      pageSize
    );

    ModelService.getAll(params)
      .then((response) => {
        const { models, totalPages, totalItems } = response.data;

        this.setState({
          models: models,
          count: totalPages,
          totalModels: totalItems,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveModels(name, value) {
    const { brandId, modelId, fuelType, transmission, page, pageSize } =
      this.state;
    let params;

    //alert(name);
    //alert(value);

    switch (name) {
      case "brandId":
        params = this.getRequestParams(
          value,
          modelId,
          fuelType,
          transmission,
          page,
          pageSize
        );
        break;
      case "modelId":
        params = this.getRequestParams(
          brandId,
          value,
          fuelType,
          transmission,
          page,
          pageSize
        );
        break;
      case "fuelType":
        params = this.getRequestParams(
          brandId,
          modelId,
          value,
          transmission,
          page,
          pageSize
        );
        break;
      case "transmission":
        params = this.getRequestParams(
          brandId,
          modelId,
          fuelType,
          value,
          page,
          pageSize
        );
        break;
      default:
        params = this.getRequestParams(
          brandId,
          modelId,
          fuelType,
          transmission,
          page,
          pageSize
        );
    }

    ModelService.getAll(params)
      .then((response) => {
        const { models, totalPages, totalItems } = response.data;

        this.setState({
          models: models,
          count: totalPages,
          totalModels: totalItems,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  refreshList() {
    this.retrieveModels();
    this.setState({
      currentModel: null,
      currentIndex: -1,
    });
  }

  setActiveModel(model, index) {
    this.setState({
      currentModel: model,
      currentIndex: index,
    });
  }

  removeAllModels() {
    ModelService.deleteAll()
      .then((response) => {
        console.log(response.data);
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveModels();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1,
      },
      () => {
        this.retrieveModels();
      }
    );
  }

  render() {
    const { models, page, count, pageSize, volume } = this.state;

    return (
      <div>
        <ScriptTag src="./mobhil/vendor/rangeslider/rangeslider.js" />

        {/* <div id="loading-area" className="loading-page-1">
          <div class="spinner">
            <div class="ball"></div>
            <p>LOADING</p>
          </div>
        </div> */}

        <div class="page-wraper">
          {/* <!-- Header --> */}
          <FrontTopBarComponent />
          {/* <!-- Header End --> */}

          <div class="page-content bg-white">
            <div
              class="dlab-bnr-inr style-1 overlay-black-middle"
              style={{
                backgroundImage: "url('images/slider3.jpg')",
                height: "100vh",
                marginTop: "-70px",
                fontSize: "50px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div class="container">
                <img src="/images/slide3.jpg" alt="" />
                <div class="dlab-bnr-inr-entry">
                  <h1 class="text-white">Car Collections</h1>
                  <div class="dlab-separator"></div>
                </div>
              </div>
            </div>

            <section class="content-inner-2">
              <div class="container">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 m-b30">
                    <aside class="side-bar sticky-top left">
                      <div class="section-head">
                        <h3 class="title">FILTER</h3>
                        <h6 class="sub-title style-1">Search your car</h6>
                      </div>
                      <form>
                        <div class="widget widget_search">
                          <div class="form-group search-bx m-b20">
                            <div class="input-group">
                              <input
                                name="text"
                                class="form-control  "
                                placeholder="Search Any Cars..."
                                type="text"
                              />
                              <span class="input-group-btn">
                                <button type="submit" class="btn shadow-none">
                                  <i class="la la-search scale3"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                          <div class="form-group m-b20">
                            <select
                              class="form-control sm"
                              name="brandId"
                              value={this.state.brandId}
                              aria-label="Default select example"
                              onChange={this.onChangeBrandIdHandler}
                            >
                              <option value="">Select Brand</option>
                              {this.state.brands.map((brand1) => (
                                <option
                                  key={brand1.brandId}
                                  value={brand1.brandId}
                                >
                                  {brand1.brandName}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* <div class="form-group">
                            <select class="form-control">
                              <option>Choose Class</option>
                              <option>First</option>
                              <option>Luxury</option>
                            </select>
                          </div> */}
                          <div class="form-group m-b20">
                            <select
                              class="form-control
                            sm"
                              name="modelId"
                              value={this.state.modelId}
                              aria-label="Default select example"
                              onChange={this.onChangeModelIdHandler}
                            >
                              <option value="">Select Model</option>
                              {this.state.models.map((model1) => (
                                <option
                                  key={model1.modelId}
                                  value={model1.modelId}
                                >
                                  {model1.modelName}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div class="widget widget_price_range">
                          <h5>Price range</h5>
                          <div class="price-slider">
                            <Slider
                              min={100000}
                              max={5000000}
                              value={volume}
                              onChange={this.handleOnChange}
                              valueLabelDisplay="50px"
                            />
                            {/* onChange={this.handleOnChange}
                            /> */}
                          </div>
                        </div>
                        <div class="widget">
                          {/* <div class="form-group m-b20">
                            <select class="form-control sm">
                              <option>Any fuel</option>
                              <option>1 liter</option>
                              <option>2 liter</option>
                              <option>3 liter</option>
                            </select>
                          </div> */}
                          <div class="form-group m-b20">
                            <select class="form-control sm">
                              <option>Colour</option>
                              <option>Black</option>
                              <option>White</option>
                              <option>Red</option>
                              <option>Green</option>
                            </select>
                          </div>
                          <div class="form-group m-b30">
                            <select class="form-control sm">
                              <option>Transmision</option>
                              <option>auto</option>
                              <option>manual</option>
                            </select>
                          </div>
                          <div class="form-group">
                            <a
                              href="/car-listing"
                              class="btn btn-lg shadow-none btn-primary d-flex justify-content-between"
                            >
                              FIND CARS
                              <i class="las la-long-arrow-alt-right"></i>
                            </a>
                          </div>
                        </div>
                      </form>
                    </aside>
                  </div>
                  <div class="col-xl-8 col-lg-8">
                    <div class="catagory-result-row">
                      <h5 class="serch-result">
                        Showing page{" "}
                        <strong>
                          {this.state.page} for total {this.state.totalModels}{" "}
                          car results
                        </strong>
                      </h5>
                      <div>
                        Sort by
                        <select class="form-control custom-select ms-3">
                          <option>Newest</option>
                          <option>Oldest</option>
                        </select>
                      </div>
                    </div>
                    <div class="row lightgallery">
                      {models &&
                        models.map((model, index1) => (
                          <div class="col-xl-6 col-lg-12 col-md-6 m-b30">
                            <div class="car-list-box">
                              <div class="media-box" style={{height:"220px"}}>
                                <img
                                  src={
                                    "https://api.bsecars.com/api/v1/models_get_base_image/" +
                                    model.modelId
                                  }
                                  onClick={() =>
                                    this.displayModel(model.modelId)
                                  }
                                />
                                <div class="overlay-bx">
                                  <span
                                    data-exthumbimage={
                                      "https://api.bsecars.com/api/v1/models_get_base_image/" +
                                      model.modelId
                                    }
                                    data-src={
                                      "https://api.bsecars.com/api/v1/models_get_base_image/" +
                                      model.modelId
                                    }
                                    onClick={() =>
                                      this.displayModel(model.modelId)
                                    }
                                    class="view-btn lightimg"
                                  >
                                    <svg
                                      width={75}
                                      height={74}
                                      viewBox="0 0 75 74"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M44.5334 27.7473V32.3718C44.5334 33.3257 43.7424 34.106 42.7755 34.106H34.572V42.199C34.572 43.1528 33.7809 43.9332 32.8141 43.9332H28.1264C27.1595 43.9332 26.3685 43.1528 26.3685 42.199V34.106H18.1649C17.1981 34.106 16.4071 33.3257 16.4071 32.3718V27.7473C16.4071 26.7935 17.1981 26.0131 18.1649 26.0131H26.3685V17.9201C26.3685 16.9663 27.1595 16.1859 28.1264 16.1859H32.8141C33.7809 16.1859 34.572 16.9663 34.572 17.9201V26.0131H42.7755C43.7424 26.0131 44.5334 26.7935 44.5334 27.7473ZM73.9782 68.8913L69.8325 72.9812C68.4555 74.3396 66.2288 74.3396 64.8664 72.9812L50.2466 58.5728C49.5874 57.9225 49.2212 57.0409 49.2212 56.116V53.7604C44.05 57.749 37.5458 60.1191 30.4702 60.1191C13.6384 60.1191 0 46.6646 0 30.0596C0 13.4545 13.6384 0 30.4702 0C47.3021 0 60.9405 13.4545 60.9405 30.0596C60.9405 37.0397 58.538 43.4563 54.4949 48.5578H56.8827C57.8202 48.5578 58.7138 48.9191 59.373 49.5694L73.9782 63.9777C75.3406 65.3362 75.3406 67.5329 73.9782 68.8913ZM50.3931 30.0596C50.3931 19.1919 41.4864 10.4052 30.4702 10.4052C19.4541 10.4052 10.5474 19.1919 10.5474 30.0596C10.5474 40.9273 19.4541 49.7139 30.4702 49.7139C41.4864 49.7139 50.3931 40.9273 50.3931 30.0596Z"
                                        fill="white"
                                        fillOpacity="0.66"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <div
                                class="list-info"
                                onClick={() => this.displayModel(model.modelId)}
                              >
                                <h4 class="title mb-0">
                                  <a class="text-black">{model.modelName}</a>
                                </h4>
                                <div class="car-type">
                                  {model.brand.brandName} - {model.modelName}
                                </div>

                                <span className="badge m-b30">
                                  <CurrencyFormat
                                    className="text-black"
                                    value={model?.first}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"Starts from \u20B9"}
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </span>
                                <div
                                  class="feature-list"
                                  style={{ height: "50px" }}
                                >
                                  <div>
                                    <label>Transmission</label>
                                    <p class="value">
                                      {/* {model.transmissions.length} */}

                                      {model.transmissions.map(
                                        (transmission, i) => (
                                          <span key={transmission}>{transmission} </span>
                                        )
                                      )}
                                    </p>
                                  </div>
                                  <div>
                                    <label>Fuel</label>
                                    <p class="value">
                                      {model.fuelTypes.map((fuelType) => (
                                        <span key={fuelType}>{fuelType} </span>
                                      ))}
                                    </p>
                                  </div>
                                  {/* <div>
                                    <label>Passenger</label>
                                    <p class="value">
                                      {" "}
                                      {this.state.specifications.map(
                                        (specificationValue) => (
                                          <label>
                                            {specificationValue.specification
                                              .specificationName ==
                                            "Seating Capacity"
                                              ? specificationValue.specificationValueName +
                                                " " +
                                                specificationValue.specificationUnit
                                              : ""}{" "}
                                          </label>
                                        )
                                      )}
                                    </p>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <Pagination
                      className="my-3 "
                      count={count}
                      page={page}
                      siblingCount={1}
                      boundaryCount={1}
                      model="outlined"
                      shape="rounded"
                      onChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="content-inner">
              <div class="container">
                <div class="row call-to-action-bx">
                  <div class="col-xl-5 col-lg-6 me-auto">
                    <div class="section-head">
                      <h2 class="title text-white">
                        Have any question about us?
                      </h2>
                    </div>
                    <a
                      href="tel:+91 80073 58007."
                      class="btn btn-white me-3 mb-2"
                    >
                      <i class="fas fa-phone-volume me-sm-3 me-0 shake"></i>
                      <span class="d-sm-inline-block d-none">
                        +91 80073 58007
                      </span>
                    </a>
                    <a
                      href="contact-us"
                      class="btn btn-outline-white effect-1  mb-2"
                    >
                      <span>Contact Us</span>
                    </a>
                  </div>
                  <div class="col-lg-6">
                    <div class="media-box">
                      <img
                        src="images/carrrrrrrr.jpg"
                        class="main-img"
                        alt=""
                      />
                      <img
                        src="/images/pattern/pattern7.png"
                        class="pt-img move-1"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <!-- Footer --> */}
          <FrontEndFooterComponent />
          {/* <!-- Footer End --> */}
        </div>
      </div>
    );
  }
}

export default IndexComponent;
