import axios from 'axios';

const VARIANT_API_BASE_URL = "https://api.bsecars.com/api/v1/variants";

class VariantService {


    getAll(params) {
        return axios.get("https://api.bsecars.com/api/v1/all_variants", { params });
    }

    getVariants(){
        return axios.get(VARIANT_API_BASE_URL);
    }

    createVariant(variant){
        return axios.post(VARIANT_API_BASE_URL, variant);
    }

    getVariantById(variantId){
        return axios.get(VARIANT_API_BASE_URL + '/' + variantId);
    }

    updateVariant(variant, variantId){
        return axios.put(VARIANT_API_BASE_URL + '/' + variantId, variant);
    }

    deleteVariant(variantId){
        return axios.delete(VARIANT_API_BASE_URL + '/' + variantId);
    }
}

export default new VariantService()