import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import ColorService from "../services/ColorService";

import SideBarComponent from './SideBarComponent';
import BackEndFooterComponent from './BackEndFooterComponent';
import TopBarComponent from './TopBarComponent';

class CreateColorComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
        // step 2
        id: this.props.match.params.id,
        colorName: '', colorNameVal:'',
        colorCode:'',   colorCodeVal:''
      
    }
    this.changeColorNameHandler = this.changeColorNameHandler.bind(this);
    this.changeColorCodeHandler = this.changeColorCodeHandler.bind(this);


    this.saveOrUpdateColor = this.saveOrUpdateColor.bind(this);
    
}

// step 3
componentDidMount(){

    // step 4
    if(this.state.id === '0'){
        return
    }else{
        ColorService.getColorById(this.state.id).then( (res) =>{
            let color = res.data;
            this.setState({
                colorName: color.colorName,
                colorCode: color.colorCode,
            });
        });
    }        
}
saveOrUpdateColor = (e) => {
    
    e.preventDefault();
    if (this.state.colorName === '') {
        this.setState({colorNameVal: 'Invalid!, Please enter color name'})
        return false;
    } 
    if (this.state. colorCode === '') {
        this.setState({colorCodeVal: 'Invalid!, Please enter colorcode value'})
        return false;
    }
    let color = {colorId:this.state.id, colorName: this.state.colorName, colorCode: this.state.colorCode };
    console.log('color => ' + JSON.stringify(color));

    // step 5
    if(this.state.id === '0'){
        ColorService.createColor(color).then(res =>{
            this.props.history.push('/colors');
        });
    }else{
        ColorService.updateColor(color, this.state.id).then( res => {
            this.props.history.push('/colors');
        });
    }
}

changeColorNameHandler= (event) => {
    this.setState({colorName: event.target.value});
}

changeColorCodeHandler= (event) => {
    this.setState({colorCode: event.target.value});
}



cancel(){
    this.props.history.push('/Colors');
}

getTitle(){
    if(this.state.id === '0'){
        return <h6 className="m-0 font-weight-bold text-primary">Add Color</h6>
    }else{
        return <h6 className="m-0 font-weight-bold text-primary">Update Color</h6>
    }
}


      render() {
        if (this.state.redirect) {
          return <Redirect to={this.state.redirect} />
        }
    
      //  const { currentUser } = this.state;
    
        return (

 // <!-- Page Wrapper -->
<div id="wrapper">
<SideBarComponent />

    {/* <!-- Content Wrapper --> */}
    <div id="content-wrapper" class="d-flex flex-column">

        {/* <!-- Main Content --> */}
        <div id="content">

        <TopBarComponent/>


            {/* <!-- Begin Page Content --> */}
            <div class="container-fluid">

                {/* <!-- Page Heading --> */}
                {/* <div class="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 class="h3 mb-0 text-gray-800">Color</h1>
                
                </div> */}

                {/* <!-- Content Row --> */}
                {/* <!-- Content Row --> */}
                    <div class="row">

                        <div class="col-xl-12 col-lg-12">

                            {/* <!-- Area Chart --> */}
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                <a class="btn btn-sm btn-primary float-right   mr-3" href="/colors" role="button">List colors</a>

                                 {  this.getTitle() }
                                </div>
                                <div class="card-body">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                              
                                <div className = "card-body">
                                    <form>
                                        <div className = "form-group">
                                            <label> Color Name: </label>
                                            <input placeholder="Color Name" name="colorName" className="form-control" 
                                                value={this.state.colorName} onChange={this.changeColorNameHandler}/>
                                                <p className="text-danger"> {this.state.colorNameVal }</p>

                                                <div className = "form-group">
                                            <label> Color Code: </label>
                                            <input placeholder="Color Code" name="colorCode" className="form-control" 
                                                value={this.state.colorCode} onChange={this.changeColorCodeHandler}/>
                                                <p className="text-danger"> {this.state.colorCodeVal }</p>
                                        </div>
                                        </div>
                                      

                                        <button className="btn btn-success" onClick={this.saveOrUpdateColor}>Save</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{marginLeft: "10px"}}>Cancel</button>
                                    </form>

                                    
                                </div>
                            </div>
                        </div>
                        <hr/>
                                    


                                    </div>
                                </div>
    
                              
    
                            </div>
    
                          
                        </div>
    
                    {/* <!-- Content Row --> */}
    
    
                    {/* <!-- Content Row --> */}
                 
    
                </div>
                {/* <!-- /.container-fluid --> */}
    
            </div>
            {/* <!-- End of Main Content --> */}
    <BackEndFooterComponent/>
    
        </div>
        {/* <!-- End of Content Wrapper --> */}
    
    </div>
    // <!-- End of Page Wrapper --> 










        );
      }
}

export default CreateColorComponent
