import React, { Component } from 'react'
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import HeaderComponent from './HeaderComponent';


import AuthService from "../services/auth.service";
import FooterComponent from './FooterComponent';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

class RegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.username,
        this.state.email,
        this.state.password,
        this.state.role
      ).then(
        () => {
          this.props.history.push("/loginp");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }


  render() {
    return (
      <div >
        <HeaderComponent />
        <h2 className="text-center">Registration</h2>

        <div class="container">

          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              {/* <!-- Nested Row within Card Body --> */}
              <div class="row">
                <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
                <div class="col-lg-7">
                  <div class="p-5">
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-4">Create an Account!</h1>
                    </div>
                    <Form
                      onSubmit={this.handleRegister}
                      ref={c => {
                        this.form = c;
                      }}
                    >
                      {!this.state.successful && (
                        <div>
                          <div class="form-group row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                              <input type="text" class="form-control form-control-user" id="exampleFirstName"
                                placeholder="Username"
                                name="username"
                                value={this.state.username}
                                onChange={this.onChangeUsername}
                                required />
                            </div>
                            <div class="col-sm-6">
                              <input type="text" class="form-control form-control-user" id="exampleLastName"
                                placeholder="Last Name" required />
                            </div>
                          </div>
                          <div class="form-group">
                            <input type="email" class="form-control form-control-user" id="exampleInputEmail"
                              placeholder="Email Address"
                              name="email"
                              value={this.state.email}
                              onChange={this.onChangeEmail}
                              required/>
                          </div>
                          <div class="form-group row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                              <input type="password" class="form-control form-control-user"
                                id="exampleInputPassword" placeholder="Password"
                                name="password"
                                value={this.state.password}
                                onChange={this.onChangePassword}
                                required />
                            </div>
                            <div class="col-sm-6">
                              <input type="password" class="form-control form-control-user"
                                id="exampleRepeatPassword" placeholder="Repeat Password"  required />
                            </div>
                          </div>
                          <button className="btn btn-primary btn-block">Sign Up</button>

                        </div>
                      )}

                      {this.state.message && (
                        <div className="form-group">
                          <div
                            className={
                              this.state.successful
                                ? "alert alert-success"
                                : "alert alert-danger"
                            }
                            role="alert"
                          >
                            {this.state.message}
                          </div>
                        </div>
                      )}
                      <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                          this.checkBtn = c;
                        }}
                      />
                    </Form>
                    <hr />
                    <div class="text-center">
                      <a class="small" href="/forgot">Forgot Password?</a>
                    </div>
                    <div class="text-center">
                      <a class="small" href="/login">Already have an account? Login!</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <FooterComponent/>

      </div>
    )
  }
}

export default RegisterComponent
